import React from 'react';
import { IonImg, IonThumbnail, IonGrid, IonRow, IonCol, IonButton, IonListHeader } from '@ionic/react';
import {Adsense} from '@ctrl/react-adsense';
import './Bhagwat.css';
import { url } from 'inspector';
  


const Bhagwat: React.FC = () => {
  return (
    <div className="ion-padding">
      <div className="ion-text-center">
      
          <h1>संपूर्ण श्रीमद्भगवद्गीता</h1>
        <IonGrid fixed={true}>
            <IonRow><IonCol><IonThumbnail><img alt="Logo" src="/assets/images/gita/a1/A1.jpg" /></IonThumbnail></IonCol><IonCol>प्रथम अध्याय - अर्जुनविषादयोग</IonCol><IonCol><IonButton color="dark" fill="clear" routerLink="/bhagwatgeeta">Read Now</IonButton></IonCol></IonRow>
            <IonRow><IonCol><IonThumbnail><img alt="Logo" src="/assets/images/gita/a2/A2.jpg" /></IonThumbnail></IonCol><IonCol>दूसरा अध्याय -  संख्या योग</IonCol><IonCol><IonButton color="dark" fill="clear" routerLink="/bhagwatgeeta2">Read Now</IonButton></IonCol></IonRow>
            <IonRow><IonCol><IonThumbnail><img alt="Logo" src="/assets/images/gita/a3/A3.jpg" /></IonThumbnail></IonCol><IonCol>तीसरा अध्याय - कर्म योग</IonCol><IonCol><IonButton color="dark" fill="clear" routerLink="/bhagwatgeeta2">Read Now</IonButton></IonCol></IonRow>
            <IonRow><IonCol><IonThumbnail><img alt="Logo" src="/assets/images/gita/a4/A4.jpg" /></IonThumbnail></IonCol><IonCol>चौधा अध्याय - ज्ञानकर्मसन्यास योग</IonCol><IonCol>Read Now</IonCol></IonRow>
            <IonRow><IonCol><IonThumbnail><img alt="Logo" src="/assets/images/gita/a5/A5.jpg" /></IonThumbnail></IonCol><IonCol>पाँचवा अध्याय - कर्मसन्यास योग</IonCol><IonCol>Read Now</IonCol></IonRow>
            <IonRow><IonCol>
              <Adsense
                client="ca-pub-4029214824221330"
                slot="2310889447"
                style={{ display: "block" }}
                format="auto"
                responsive="true"
              />
              </IonCol></IonRow>
            <IonRow><IonCol><IonThumbnail><img alt="Logo" src="/assets/images/gita/a6/A6.jpg" /></IonThumbnail></IonCol><IonCol>छठा अध्याय - आत्मसंयम योग</IonCol><IonCol>Read Now</IonCol></IonRow>
            <IonRow><IonCol><IonThumbnail><img alt="Logo" src="/assets/images/gita/a7/A7.jpg" /></IonThumbnail></IonCol><IonCol>सातवाँ अध्याय - ज्ञानविज्ञानं योग</IonCol><IonCol>Read Now</IonCol></IonRow>
            <IonRow><IonCol><IonThumbnail><img alt="Logo" src="/assets/images/gita/a8/A8.jpg" /></IonThumbnail></IonCol><IonCol>आठवाँ अध्याय - अक्षर ब्रह्मं योग</IonCol><IonCol>Read Now</IonCol></IonRow>
            <IonRow><IonCol><IonThumbnail><img alt="Logo" src="/assets/images/gita/a9/A9.jpg" /></IonThumbnail></IonCol><IonCol>नौवाँ अध्याय - राज विद्या राज गुह्यः योग</IonCol><IonCol>Read Now</IonCol></IonRow>
            <IonRow><IonCol><IonThumbnail><img alt="Logo" src="/assets/images/gita/a10/A10.jpg" /></IonThumbnail></IonCol><IonCol>दशवाँ अध्याय - विभूति योग</IonCol><IonCol>Read Now</IonCol></IonRow>
            <IonRow><IonCol><IonThumbnail><img alt="Logo" src="/assets/images/gita/a11/A11.jpg" /></IonThumbnail></IonCol><IonCol>ग्यारहवाँ अध्याय - विश्व रुप दशर्न योग</IonCol><IonCol>Read Now</IonCol></IonRow>
            <IonRow><IonCol><IonThumbnail><img alt="Logo" src="/assets/images/gita/a12/A12.jpg" /></IonThumbnail></IonCol><IonCol>बारहवाँ अध्याय - भक्ति योग</IonCol><IonCol>Read Now</IonCol></IonRow>
            <IonRow><IonCol><IonThumbnail><img alt="Logo" src="/assets/images/gita/a13/A13.jpg" /></IonThumbnail></IonCol><IonCol>तेरहवाँ अध्याय-क्षेत्र क्षेत्रज्ञ विभाग योग</IonCol><IonCol>Read Now</IonCol></IonRow>
            <IonRow><IonCol><IonThumbnail><img alt="Logo" src="/assets/images/gita/a14/A14.jpg" /></IonThumbnail></IonCol><IonCol>चौदहवाँ अध्याय - गुण त्रय विभाग योग</IonCol><IonCol>Read Now</IonCol></IonRow>
            <IonRow><IonCol><IonThumbnail><img alt="Logo" src="/assets/images/gita/a15/A15.jpg" /></IonThumbnail></IonCol><IonCol>पंद्रहवाँ अध्याय - पुरुषोत्तम योग</IonCol><IonCol>Read Now</IonCol></IonRow>
            <IonRow><IonCol><IonThumbnail><img alt="Logo" src="/assets/images/gita/a16/A16.jpg" /></IonThumbnail></IonCol><IonCol>सोलहवाँ अध्याय - देव आसुर संपदा विभाग</IonCol><IonCol>Read Now</IonCol></IonRow>
            <IonRow><IonCol><IonThumbnail><img alt="Logo" src="/assets/images/gita/a17/A17.jpg" /></IonThumbnail></IonCol><IonCol>सत्रहवाँ अध्याय - श्रद्धा त्रय योग</IonCol><IonCol>Read Now</IonCol></IonRow>
            <IonRow><IonCol><IonThumbnail><img alt="Logo" src="/assets/images/gita/a18/A18.jpg" /></IonThumbnail></IonCol><IonCol>अठारहवाँ अध्याय - मोक्ष सन्यास योग</IonCol><IonCol>Read Now</IonCol></IonRow>
            <IonListHeader lines="full"></IonListHeader>
            
            <IonRow>
              <IonCol>
                  <Adsense
                    client="ca-pub-4029214824221330"
                    slot="2310889447"
                    style={{ display: "block" }}
                    format="auto"
                    responsive="true"
                  />
                </IonCol>
              </IonRow>
    
            
        </IonGrid>
      </div>
    </div>
  );
};

export default Bhagwat;