import { IonButton, IonCol, IonGrid, IonRow, IonListHeader} from '@ionic/react';
import {Adsense} from '@ctrl/react-adsense';

import './Services.css';


const Services: React.FC = () => {
  return (
      <div className="ion-padding">
    
        <IonGrid fixed={true}>
          <div className="ion-text-center">
            <h3>Puja Paath</h3>
            <IonRow>
              <IonCol><IonButton fill="clear" routerLink="/mirtunjay">Maha Mirtunjay Jaap</IonButton></IonCol>
              <IonCol><IonButton fill="clear" routerLink="/mahalakshmijaap">Maha Lakshmi Jaap</IonButton></IonCol>
              <IonCol><IonButton fill="clear" routerLink="/mahasarasawatijaap">Maha Saraswati Jaap</IonButton></IonCol>
            </IonRow>
            <IonRow>
              <IonCol><IonButton fill="clear" routerLink="/ganesh"> Sri Ganesh Chaturthi Puja</IonButton></IonCol>
              <IonCol><IonButton fill="clear" routerLink="/navgrah">NavGrah Shanti Jaap</IonButton></IonCol>
              <IonCol><IonButton fill="clear" routerLink="/vastu">Vastu Dosh Nivaran Jaap</IonButton></IonCol>
            </IonRow>
            <IonRow>  
              <IonCol><IonButton fill="clear" routerLink="/rudravishek">Rudrabhishek</IonButton></IonCol>
              <IonCol><IonButton fill="clear" routerLink="/satyanarayan">Sri Satya Narayan Puja</IonButton></IonCol>
              <IonCol><IonButton fill="clear" routerLink="/teej">Hartalika Teej Puja</IonButton></IonCol>
            </IonRow>
            <IonRow>
              <IonCol><IonButton fill="clear" routerLink="/anantpuja">Shri Anant Puja</IonButton></IonCol>
              <IonCol><IonButton fill="clear" routerLink="/mahasarasawatijaap">Sri Saraswati Puja</IonButton></IonCol>
              <IonCol><IonButton fill="clear" routerLink="/navratri">Navratri Puja</IonButton></IonCol>
            </IonRow>
            <IonRow>
              <IonCol><IonButton fill="clear" routerLink="/shivratri">Shivratri Puja</IonButton></IonCol>
              <IonCol><IonButton fill="clear" routerLink="/ganesh">Sri Ganesh Puja</IonButton></IonCol>
              <IonCol><IonButton fill="clear" routerLink="/ramnavami">Ram Navami Puja</IonButton></IonCol>
            </IonRow>
            <IonRow>
              <IonCol><IonButton fill="clear" routerLink="/vishwakarma">Sri Vishwakarma Puja</IonButton></IonCol>
              <IonCol><IonButton fill="clear" routerLink="/laxmipuja">Shri Lakshmi Puja</IonButton></IonCol>
              <IonCol><IonButton fill="clear" routerLink="/ekadashi">Ekadashi Puja</IonButton></IonCol>
            </IonRow>
            <IonRow>
        <Adsense
                client="ca-pub-4029214824221330"
                slot="2310889447"
                style={{ display: "block" }}
                format="auto"
                responsive="true"
              />
        </IonRow>
          
              <h3>Karam Kand</h3> 
            <IonRow>
              <IonCol><IonButton fill="clear" routerLink="/janmotasavapuja">Janmotasav Puja</IonButton></IonCol>
              <IonCol><IonButton fill="clear" routerLink="/shadi">Shadi Vivah</IonButton></IonCol>
              <IonCol><IonButton fill="clear" routerLink="/mundan">Mundan</IonButton></IonCol>
            </IonRow>
            <IonRow>
              <IonCol><IonButton fill="clear" routerLink="/bhumipujan">Bhumi Pujan</IonButton></IonCol>
              <IonCol><IonButton fill="clear" routerLink="/jirngrihprawesh">Jirn Grih Prawesh</IonButton></IonCol>
              <IonCol><IonButton fill="clear" routerLink="/navgrihprawesh">Nav Grih Prawesh</IonButton></IonCol>
            </IonRow>
            <IonRow>
              <IonCol><IonButton fill="clear" routerLink="/vyaparshubharambhpuja">Vyapar Shubh Arambh Puja</IonButton></IonCol>
              <IonCol><IonButton fill="clear" routerLink="/vyaparunnatianusthan">Vyapar Unnati Anusthan</IonButton></IonCol>
              
            </IonRow>
          </div>
        </IonGrid>
      </div>
  );
};

export default Services;
