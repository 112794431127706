import {IonRow,IonCol, IonGrid, IonButton, IonText, IonImg, IonListHeader  } from '@ionic/react';
import {Adsense} from '@ctrl/react-adsense';


import './Mirtunjay.css';


const Mirtunjay: React.FC = () => {
  return (
    <div className="ion-padding">
        <div className="ion-text-center">
        <IonGrid fixed={true}>
        <IonRow><IonCol><IonImg src="/assets/images/shiv.png" /></IonCol></IonRow>
        <h1><b>|| मृत्युंजय मंत्र ||</b></h1>
        <IonText color="tertiary">
        <h1 ><b>" ॐ त्र्यम्बकं यजामहे सुगन्धिं पुष्टिवर्धनम् उर्वारुकमिव बन्धनान्मृत्योर्मुक्षीय मामृतात् || "</b></h1>
        </IonText>
        <IonListHeader lines="full"></IonListHeader>
        <h1><b>|| महामृत्युंजय मंत्र ||</b></h1>
        <IonText color="danger">
        <h1><b> "ॐ हौं जूं सः ॐ भूर्भुवः स्वः ॐ त्र्यम्बकं यजामहे सुगन्धिं पुष्टिवर्धनम् उर्वारुकमिव बन्धनान्मृत्योर्मुक्षीय मामृतात् ॐ स्वः भुवः भूः ॐ सः जूं हौं ॐ।"</b></h1>
        </IonText>
        <IonListHeader lines="full"></IonListHeader>
        <h3>मंत्रानुष्ठान के लिए शास्त्र के विधान का पालन करना परम आवश्यक है, अन्यथा लाभ के बदले हानि की संभावना अधिक रहती है। इसलिए यह आवश्यक है कि प्रत्येक कार्य शास्त्रसम्मत ही किया जाए। इस हेतु किसी योग्य और विद्वान व्यक्ति का मार्गदर्शन लेना चाहिए।</h3>
        <h3>यदि साधक पूर्ण श्रद्धा और विश्वास के साथ यह साधना करें, तो वांछित फल की प्राप्ति की प्रबल संभावना रहती है।</h3>
        <h3>शास्त्रों में अलग-अलग कार्यों के लिए अलग-अलग संख्याओं में महामृत्युंजय मंत्र के जप का विधान है। इसमें किस कार्य के लिए कितनी संख्या में जप करना चाहिए इसका उल्लेख किया गया है।</h3>
        <IonListHeader lines="full"></IonListHeader>
        
        <h4># भय से छुटकारा पाने के लिए 1100 मंत्र का जप किया जाता है।</h4>
        <h4># रोगों से मुक्ति के लिए 11000 मंत्रों का जप किया जाता है।</h4>
        <h4># पुत्र की प्राप्ति के लिए, मनोवांछित नौकरी के लिए, उन्नति के लिए, मृत्यु से बचने के लिए सवा लाख की संख्या में मंत्र जप करना अनिवार्य है।</h4>
        <IonRow>
        <Adsense
                client="ca-pub-4029214824221330"
                slot="2310889447"
                style={{ display: "block" }}
                format="auto"
                responsive="true"
              />
        </IonRow>
        
        <IonListHeader lines="full"></IonListHeader>
        <h2>The <b>Maha Mrityunjaya Mantra</b> is a mantra that is used to heal and protect oneself. This mantra was written by the god Shiva, and is said to be the most powerful of all mantras. It is chanted 108 times in order to heal any ill-being or problem that may come into your life. You can also chant it on a daily basis if you desire protection from negativity or other negative forces in your life.</h2>
        <h2>The <b>Maha Mrityunjaya Mantra</b> is a short, easy-to-remember prayer that can help you heal, protect, and so much more.</h2>
        <h3>The <b>Maha Mrityunjaya Mantra</b> is a powerful mantra that can help bring healing, protection, and longevity to your life. It can be used for many different reasons—improving health, overcoming obstacles in life, or simply getting through a hard time.</h3>
        <h3>All of us at <b>[Sanatan Puja]</b> would love to do Jaap of <IonText color="danger"><b> "Maha Mirtunjaya Mantra" </b></IonText> for everyone who need it, and for you to use this as a tool that will empower you to reach your goals in life. You can contact us by clicking the link given below.</h3>
        <IonText color="success">
        <h3><i><b>We are providing this service at very nominal Dakshina.</b></i></h3>
        </IonText>
        {/*
        <IonRow><IonCol><b>Number Of Mantras</b></IonCol><IonCol><b>Pandit at your Home (Dakshina Amount)</b></IonCol><IonCol><b>At Pandit Home (Dakshina Amount)</b></IonCol></IonRow>
        <IonRow><IonCol>108</IonCol><IonCol>501/-</IonCol><IonCol>221/-</IonCol></IonRow>
        <IonRow><IonCol>1,008</IonCol><IonCol>2021/-</IonCol><IonCol>501/-</IonCol></IonRow>
        <IonRow><IonCol>1,108</IonCol><IonCol>2051/-</IonCol><IonCol>521/-</IonCol></IonRow>
        <IonRow><IonCol>2,108</IonCol><IonCol>2551/-</IonCol><IonCol>751/-</IonCol></IonRow>
        <IonRow><IonCol>11,008</IonCol><IonCol>10551/-</IonCol><IonCol>2051/-</IonCol></IonRow>
        <IonRow><IonCol>21,008</IonCol><IonCol>20551/-</IonCol><IonCol>5051/-</IonCol></IonRow>
        <IonRow><IonCol>51,008</IonCol><IonCol>25501/-</IonCol><IonCol>10501/-</IonCol></IonRow>
        <IonRow ><IonCol>1,00,008</IonCol><IonCol>51000/-</IonCol><IonCol>25001/-</IonCol></IonRow>
        <IonRow><IonCol><IonButton routerLink="/contact">Contact Us</IonButton></IonCol></IonRow>
        
        <h1> .</h1>
        <h1>Testimonials</h1>
        <h2>I first heard about this mantra when I was sick with a bad cough and fever. I consulted "Sanatan Puja" and expert kept telling me to chant it, but I didn't understand what they meant until one day I tried it out myself! The next morning when I woke up, my cough was gone! It seemed like magic! Now whenever I'm sick with a cold or something similar, I always chant this mantra around the same time every day, hoping that it will help me get better faster than usual.</h2>
        <p>Rajesh Shrivastava, Chandigarh</p>
        <h2>I feel calm and safe after using this mantra because it makes me feel like everything will be okay whether physically or spiritually speaking—even if things aren't going well in my life at the moment.</h2>
        <p>Ritu Singh, Delhi</p>
        <h3>I am calm and safe, protected by the power of this mantra.</h3>
        <p>Abhishek Gupta, Jhanshi</p>
        <h3>I've used this mantra when I was sick before, and I can tell you that while nothing really healed me (I'm still not sure what exactly caused my illness), it did make me feel better by giving me some peace of mind. The fact that chanting this mantra helped calm my mind and made me feel safe is worth something too!</h3>
        <p>Addarsh Yadav, Jabalpur</p>
        <h3>When I was sick, I found that I could chant this mantra in my room every day before falling asleep. This helped me feel better throughout my illness, and also helped me get more rest during those difficult days. It also helped me feel more relaxed when I wasn't feeling well enough to go out and do things with friends or family members.</h3>
        <p>Munmun Mondal, Asansol</p>
        <h3>I feel calmer and safer when chanting this mantra—I know that whatever happens will be for the best!</h3>
        <p>Aditi, Haridwar</p>
        */}
        <IonRow><IonCol><IonButton routerLink="/contact">Contact Us for More Details</IonButton></IonCol></IonRow>
        </IonGrid>
        </div>
        
    </div>
  );
};

export default Mirtunjay;

