import { IonGrid, IonRow, IonImg, IonCol, IonListHeader} from '@ionic/react';
import {Adsense} from '@ctrl/react-adsense';
import './Firstp.css';


const Firstp: React.FC = () => {
  return (
      <div className="ion-padding">
            <IonGrid fixed={true}>
            <IonImg src="/assets/images/home_banner.png" />
            
            <div className="ion-text-center">          
                <h1>|| Sanatan Puja - सनातन पूजा ||</h1></div>
                <IonImg src="/assets/images/vichar.png" />
                
                <div className="ion-text-center">
                <p><b>नीचे दिए गए बटन पर क्लिक करके अपने विचार साझा करें</b></p> 
                <IonRow><IonCol><a href='https://docs.google.com/forms/d/e/1FAIpQLScB-tSDyeNSdJLQRhNJY6F4GVzN6W_hKhh0fLPXzpAddkZTuw/viewform?usp=sf_link' target="_blank"><h3><b>Click Me to Submit Your Article</b></h3></a></IonCol></IonRow>
                <IonListHeader lines="full"></IonListHeader>
                <p>"Welcome to Sanatan Puja - Your one-stop destination for all Hindu rituals and prayers. Our aim is to preserve the rich cultural heritage of Hinduism and make the traditional puja rituals accessible to everyone.</p>
                <p>With our online platform, you can now easily perform puja at the comfort of your home with the guidance of experienced priests. From daily puja to special occasions, we have got you covered. Our wide range of puja packages include everything from puja materials to the prasadam.</p>
                <p>We believe in following the ancient vedic scripts and rituals for maximum benefits and blessings. Our team of knowledgeable and experienced priests will perform the puja with utmost devotion and dedication.</p>
                <p>Join hands with us in preserving and promoting our rich cultural heritage. Book your puja today and experience the divine blessings of the almighty.</p>
                <p>At Sanatan Puja, we are committed to providing an enjoyable and seamless puja experience. Our customer support team is always available to assist you with any queries or concerns.</p>
                <p>Start your spiritual journey with us and immerse yourself in the beauty of Hindu rituals and prayers. Sanatan Puja - Connecting you to your roots."</p>
                <IonListHeader lines="full"></IonListHeader>
                <p>"सनातन पूजा में आपका स्वागत है - सभी हिंदू अनुष्ठानों और प्रार्थनाओं के लिए आपका एकमात्र गंतव्य। हमारा उद्देश्य हिंदू धर्म की समृद्ध सांस्कृतिक विरासत को संरक्षित करना और पारंपरिक पूजा अनुष्ठानों को सभी के लिए सुलभ बनाना है।</p>
                <p>हमारे ऑनलाइन प्लेटफॉर्म के साथ, अब आप अनुभवी पुजारियों के मार्गदर्शन में आसानी से अपने घर में पूजा कर सकते हैं। दैनिक पूजा से लेकर विशेष अवसरों तक, हमने आपको कवर किया है। हमारे पूजा पैकेजों की विस्तृत श्रृंखला में पूजा सामग्री से लेकर प्रसादम तक सब कुछ शामिल है।</p>
                <p>हम अधिकतम लाभ और आशीर्वाद के लिए प्राचीन वैदिक लिपियों और अनुष्ठानों का पालन करने में विश्वास करते हैं। जानकार और अनुभवी पुजारियों की हमारी टीम अत्यंत भक्ति और समर्पण के साथ पूजा करेगी।</p>
                <p>हमारी समृद्ध सांस्कृतिक विरासत को संरक्षित करने और बढ़ावा देने में हमारे साथ हाथ मिलाएं। आज ही अपनी पूजा बुक करें और सर्वशक्तिमान के दिव्य आशीर्वाद का अनुभव करें।</p>
                <p>सनातन पूजा में, हम एक सुखद और निर्बाध पूजा अनुभव प्रदान करने के लिए प्रतिबद्ध हैं। हमारी ग्राहक सहायता टीम किसी भी प्रश्न या चिंता के साथ आपकी सहायता के लिए हमेशा उपलब्ध है।</p>
                <p>हमारे साथ अपनी आध्यात्मिक यात्रा शुरू करें और हिंदू रीति-रिवाजों और प्रार्थनाओं की सुंदरता में डूब जाएं। सनातन पूजा - आपको अपनी जड़ों से जोड़ना।"</p>

                <IonRow><IonCol><IonImg src="/assets/images/features-icon-white-01.png" /></IonCol><IonCol><IonImg src="/assets/images/banner-right-image.png" /></IonCol><IonCol><IonImg src="/assets/images/banner-dec-right.png" /></IonCol></IonRow>
                
            </div>
            <IonRow><IonCol>
          <Adsense
            client="ca-pub-4029214824221330"
            slot="2310889447"
            style={{ display: "block" }}
            format="auto"
            responsive="true"
          />
          </IonCol></IonRow>
          </IonGrid>
            
        </div>
               
  );
};

export default Firstp;

