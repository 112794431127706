import { IonGrid, IonRow, IonCol, IonListHeader} from '@ionic/react';
import {Adsense} from '@ctrl/react-adsense';

import './Products.css';


const Home: React.FC = () => {
  return (
    <div className="ion-padding">
      <IonGrid fixed={true}>
        <div className="ion-text-center">
            <h1>Our Products</h1>
          
              <h1>नवरत्न / Gemstones (नवरत्नों के नाम , हिंदी और इंग्लिश नाम)</h1>
              <h3>नवरत्नों का महत्व और कार्य का वर्णन तो पुराणों में भी किया गया है। जैसा की हम सब जानते है की सर्वश्रेस्ट भाषा संस्कृत है और नवरत्न शब्द संस्कृत से आया है इसका अर्थ नो रत्नो से है। गृह नक्षत्र के अनुसार प्रत्येक रत्न का एक अपना ही महत्व है नवगृह के अनुसार नवरत्नों को धारण किया जाता है। नवरत्नों के 9 नाम  इस प्रकार है मानक , मोती , पन्ना , मूंगा ,पुखराज , हीरा , नीलम , गोमेद , वैदूर्य।
              <p>रत्नो को ग्रहण करने के लिए कोई धर्म आड़े नहीं आते हिन्दू,मुस्लिम,सिख और सभी धर्मो के लोग इन्हे धारण कर सकते है। रत्नो का प्रभाव सीधा आपकी राशि पर और दैनिक कार्यो पर पड़ता है। पृथ्वी पर जीवित सभी जीवो पर नवग्रहों का प्रभाव पड़ता है इन ग्रहो के प्रभावों से बचने के लिए इनको धारण किया जाता है जो राशि के अनुसार पहने जाते है।</p>
              <p>इन रत्नो का प्रभाव सकारात्मक और नकारात्मक दोनों होता है निर्भर करता है की विधि पूर्वक और सही राशि रत्न धारण किया है या नहीं। हिन्दू मान्यता के अनुसार रत्न को हमेशा ज्योतिष परामर्श के बाद में धारण करना चाहिए।नवरत्न जड़ित आभूषणों का सभी धर्मो में बहुत ही भहत्व है।</p></h3>
              <IonRow><IonCol>नवरत्न</IonCol><IonCol>English Name</IonCol><IonCol> Rashi </IonCol><IonCol>Buy Link</IonCol></IonRow>
              <IonRow><IonCol>माणिक्य </IonCol><IonCol>Ruby</IonCol><IonCol> सूर्य </IonCol><IonCol><a href="https://amzn.to/3uYaOn4"target="_blank">Buy Now</a></IonCol></IonRow>
              <IonRow><IonCol>मरकत </IonCol><IonCol>Emerald </IonCol><IonCol>बुध</IonCol><IonCol><a href="https://amzn.to/3HM1omy"target="_blank">Buy Now</a></IonCol></IonRow>
              <IonRow><IonCol>वज्र </IonCol><IonCol>Diamond </IonCol><IonCol>शुक्र</IonCol><IonCol><a href="https://amzn.to/3HGEWet"target="_blank">Buy Now</a></IonCol></IonRow>
              <IonRow><IonCol>मोती </IonCol><IonCol>Pearl</IonCol> <IonCol>चन्द्र </IonCol><IonCol><a href="https://amzn.to/3Pzw64a"target="_blank">Buy Now</a></IonCol></IonRow>
              <IonRow><IonCol>गोमेधक</IonCol><IonCol>Hessonite</IonCol><IonCol> राहु </IonCol><IonCol><a href="https://amzn.to/3PB4b3E"target="_blank">Buy Now</a></IonCol></IonRow>
              <IonRow><IonCol>वैडूर्य </IonCol><IonCol>Chrysoberyl</IonCol><IonCol> केतु </IonCol><IonCol><a href="https://amzn.to/3YBBOX9"target="_blank">Buy Now</a></IonCol></IonRow>
              <IonRow><IonCol>प्रवाल अथवा विद्रुम </IonCol><IonCol>Coral</IonCol><IonCol> मंगल </IonCol><IonCol><a href="https://amzn.to/3G2dab1"target="_blank">Buy Now</a></IonCol></IonRow>
              <IonRow><IonCol>पद्मराग अथवा पुष्यराग अथवा पुष्पराज अथवा पुष्पराग </IonCol><IonCol>Yellow sapphire</IonCol><IonCol> गुरु </IonCol><IonCol><a href="https://amzn.to/3FBSG7y"target="_blank">Buy Now</a></IonCol></IonRow>
              <IonRow><IonCol>इन्द्रनील </IonCol><IonCol>Blue Sapphire</IonCol><IonCol> शनि </IonCol><IonCol><a href="https://amzn.to/3hHCUQu"target="_blank">Buy Now</a></IonCol></IonRow>
              <IonRow>
        <Adsense
                client="ca-pub-4029214824221330"
                slot="2310889447"
                style={{ display: "block" }}
                format="auto"
                responsive="true"
              />
        </IonRow>

              <h1>विभिन्न प्रकार की मालाएं और उनके उपयोग </h1>
              <h3>हिन्दू धर्म में पूजा पाठ का स्थान सर्वोपरि रहा है और उसमे माला को धारण करना एवं माला का मंत्र जाप में प्रयोग भी प्राचीन काल से हो रहा है। सभी देवी देवता से एक विशेष प्रकार की माला/मनका सम्बन्ध रखते हैं और उस माला को धारण करने से और उस माला पर उनका मंत्र जाप करने से फल की प्राप्ति कम समय में ही हो जाती है। ऐसी कुछ विशेष मालाएं निम्नलिखित हैं</h3>
              <h1>तुलसी की माला</h1>
              <IonRow><IonCol>तुलसी की माला वैष्णव भक्तों में प्रचलित है और इसे धारण करने से मन को शांति मिलती है और दुष्कर्म करने की प्रवृति से मुक्ति मिलती है। ये भी माना जाता है की जिस मनुष्य के गले में तुलसी की कंठी होती है उसे मृत्युपर्यन्त मुक्ति मिलती है और यम की त्रास नहीं भोगनी पड़ती। भगवन विष्णु के अष्टाक्षर मंत्र का जाप तुलसी की माला पर करने से विशेष लाभ मिलता है और उनका सामीप्य प्राप्त होता है। तुलसी की माला धारण कर स्नान करने से सभी पवित्र नदियों में स्नान करने का पुण्य प्राप्त हो जाता है और निरोगी काया रहती है। </IonCol></IonRow>
              <IonRow><IonCol><a href="https://amzn.to/3YACagX"target="_blank">Buy Now</a></IonCol></IonRow>
              <IonListHeader lines='full'></IonListHeader>
              <h1>कमल गट्टा की माला</h1>
              <IonRow><IonCol>कमल गट्टा की माला माँ लक्ष्मी से सम्बंधित है और इसे धारण करने से उनकी कृपा प्राप्त होती है। कमल गट्टा  माला पर लक्ष्मी मंत्र का जाप करने से भी दारिद्य और आर्थिक परेशानी का शमन होता है। १०८ मनके की कमल गट्टा की माला श्री यन्त्र को अर्पित करने से भी आर्थिक और व्यावसायिक परेशानियां दूर होती हैं। श्री सूक्तम एवं श्री कनक धारा स्तोत्र का पाठ कमल गट्टे की माला पर करने आर्थिक, वैवाहिक और मानसिक चिंताओं का शमन होता है और माँ लक्ष्मी पाने भक्त पर प्रसन्न रहती हैं।</IonCol></IonRow>
              <IonRow><IonCol><a href="https://amzn.to/3FCXSYW"target="_blank">Buy Now</a></IonCol></IonRow>
              <IonListHeader lines='full'></IonListHeader>
              <h1>वैजन्ती की माला</h1>
              <IonRow><IonCol>वैजन्ती की माला भगवन श्री कृष्ण/ श्री विष्णु से सम्बन्ध रखती है। जिस व्यक्ति विशेष को क्रोध अधिक आता हो या मन परेशानियों से घिरा रहता हो उसे यह माला धारण करनी चाहिए। विवाह में विलम्ब हो या वर/ वधु के चयन में परेशानी अति हो तो वैजन्ती माला पर श्री विष्णु के मंत्र का जाप करने से परेशानी दूर हो जाती है। वैजन्ती की माला आकर्षण करने का भी स्वाभाव रखती है इसलिए इसे धारण करने से मनुष्य के स्वभाव और व्यक्तित्व में भी आकर्षण अत है।</IonCol></IonRow>
              <IonRow><IonCol><a href="https://amzn.to/3G128Tf"target="_blank">Buy Now</a></IonCol></IonRow>
              <IonListHeader lines='full'></IonListHeader>
              <h1>रुद्राक्ष की माला </h1>  
              <IonRow><IonCol>रुद्राक्ष को किसी भी प्रकार के परिचय की आवश्यकता नहीं है और इसे धारण करने से मनुष्य पाप रहित हो जाता है और सद्मार्ग की ओर अग्रसर होता है। भगवन शंकर की कृपा प्राप्त होती है, क्रोध का शमन होता है, रोग मुक्ति होती है, अकाल मृत्यु से मुक्ति मिलती है, कष्ट कलेश से मुक्ति मिलती है और संचित पापों की नाश होता है। रुद्राक्ष १ से २८ मुखी वाले होते हैं और १०८ मनके की रुद्राक्ष की माला या केवल एक रुद्राक्ष ही धारण करना मनुष्य के लिए एक आशीर्वाद के सामान है।</IonCol></IonRow><IonRow><IonCol><a href="https://amzn.to/3BKcK6q"target="_blank">Buy Now</a></IonCol></IonRow>
              <IonListHeader lines='full'></IonListHeader>
              <h1>हल्दी की माला </h1> 
              <IonRow><IonCol>हल्दी की माला का निर्माण हल्दी की गांठ से किया जाता है। इस माला का विशेष प्रयोग देवी बगलामुखी के मंत्र जाप के लिए किया जाता है। शत्रु पीड़ा एवं क्रूर ग्रह पीड़ा को समाप्त करने के लिए इस माला को धारण भी किया जाता है और इस पर मंत्र जाप भी किया जाता है। बृहस्पति से सम्बंधित दोष भी इस माला को धारण करने से दूर हो जाते हैं एवं रोग मुक्ति और आर्थिक लाभ भी इस माला को धारण करने से/ जाप करने से प्राप्त हो जाते हैं।</IonCol></IonRow><IonRow><IonCol><a href="https://amzn.to/3uY8219"target="_blank">Buy Now</a></IonCol></IonRow>
              <IonListHeader lines='full'></IonListHeader>
              <h1>चन्दन की माला </h1>
              <IonRow><IonCol>चन्दन की माला श्री राम, भगवन विष्णु और श्री कृष्ण से सम्बन्ध रखती है और उनसे जुड़े मन्त्रों का जाप करने के लिए प्रयोग में लायी जाती है।चन्दन की माला धारण करने वाले मनुष्य मन सदैव शांत रहता है और क्रोध नियंत्रण में रहता है। रोग का शमन होता है और स्वभाव में नरमी आने से ऐसे मनुष्य के मित्र अधिक हो जाते हैं। रक्त चन्दन का प्रयोग देवी दुर्गा की उपासना में किया जाता है और इसके प्रयोग से/ धारण करने से मंगल ग्रह की शांति भी हो जाती है। रक्त चन्दन की माला पर मंगल के मंत्र जाप से भी पूर्ण लाभ मिलता है और मंगल शांत रहते हैं।</IonCol></IonRow><IonRow><IonCol><a href="https://amzn.to/3PB42ND"target="_blank">Buy Now</a></IonCol></IonRow>
              <IonListHeader lines='full'></IonListHeader>
              <h1>स्फटिक की माला </h1>
              <IonRow><IonCol>स्फटिक की माला धारण करने से मनुष्य में पवित्रता अति है और पुण्य उदय होते हैं। स्फटिक की माला धारण करने से और मंत्र जाप करने से शुक्र से सम्बंधित दोष दूर होते हैं। कमल गट्टे की तरह स्फटिक की माला भी माँ लक्ष्मी की आराधना और मंत्र जाप में उपयोग में लायी जाती है।</IonCol></IonRow><IonRow><IonCol><a href="https://amzn.to/3YvuKLQ"target="_blank">Buy Now</a></IonCol></IonRow>
              <IonListHeader lines='full'></IonListHeader>
              <h1>मोती की माला</h1>
              <IonRow><IonCol>मोती की उत्पति जल से होने के कारण इसकी माला धारण करने से शांति की अनुभूति होती है। मोती की माला धारण करने से चंद्र सम्बंधित दोष दूर होते हैं। इसे धारण करने से सांसारिक वस्तुएं भी प्राप्त होती हैं और धन की प्राप्ति भी होती है।</IonCol></IonRow><IonRow><IonCol><a href="https://amzn.to/3FGaWww"target="_blank">Buy Now</a></IonCol></IonRow>
              <IonListHeader lines='full'></IonListHeader>
              <h1>मूंगे की माला</h1>
              <IonRow><IonCol>मूंगे का सम्बन्ध मंगल से है और मूंगे की माला धारण करने से मंगल जनित दोष का शमन होता है। मन और स्वाभिमान में वृद्धि होती है और क्रोध शांत होता है। मूंगे की माला गणपति को अर्पित करना भी बहुत शुभ माना जाता है। इससे रक्त सम्बन्धी दोष भी समाप्त होते हैं और ऊपरी बाधा की भी शांति होती है।</IonCol></IonRow><IonRow><IonCol><a href="https://amzn.to/3v0RmpP"target="_blank">Buy Now</a></IonCol></IonRow>
              <IonListHeader lines='full'></IonListHeader>
              <h1>हकीक की माला</h1>
              <IonRow><IonCol>हकीक का पत्थर लाल, पीला, हरा, काला रंग में प्राप्त होता है और रंग के अनुसार विभिन्न ग्रह से जनित पीड़ा का नाश करता है। जैसे काले हकीक की माला से शनि, राहु और केतु की शांति होती है वैसे ही पीला हकीक धारण करने  से बृहस्पति जनित दोष से निवृति मिलती है। भगवन भैरव की साधना में भी हकीक की माला का प्रयोग जाप के लिए किया जाता है। इसे धारण करने से मान सम्मान और सुख की प्राप्ति होती है।</IonCol></IonRow><IonRow><IonCol><a href="https://amzn.to/3uY8Oez"target="_blank">Buy Now</a></IonCol></IonRow>
              <IonListHeader lines='full'></IonListHeader>
              <h1>पुत्र जीवा माला</h1>
              <IonRow><IonCol>अपने नाम के अनुसार इस माला को पुत्र प्राप्ति के लिए धारण किया जाता है। पुत्र प्राप्ति के अलावा इस माला से निरोगी काया और सात्विक विचार भी प्राप्त किये जाते हैं। कुंडली में सूर्य जनित दोष भी इस माला को धारण करने से शांत होते हैं।</IonCol></IonRow><IonRow><IonCol><a href="https://amzn.to/3YywmnT"target="_blank">Buy Now</a></IonCol></IonRow>
          
        </div>
      </IonGrid>
     
    </div>
  );
};

export default Home;
