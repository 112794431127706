import {IonRow,IonCol, IonGrid, IonButton, IonText, IonImg, IonIcon, IonListHeader  } from '@ionic/react';
import {Adsense} from '@ctrl/react-adsense';

import './Ganesh.css';


const Ganesh: React.FC = () => {
  return (
    <div className="ion-padding">
        <div className="ion-text-justify">
        <IonGrid fixed={true}>
        <IonRow><IonCol><IonImg src="/assets/images/ganesh.jpeg" /></IonCol></IonRow>
        <IonText color="danger"><h1>Lord Ganesha: The Hindu God of Beginnings and Remover of Obstacles</h1></IonText>
Lord Ganesha, also known as Ganapati or Vinayaka, is widely revered as the Hindu god of beginnings and remover of obstacles. He is widely worshipped across India and neighboring countries, and is considered one of the most important Hindu gods.

<IonText color="primary"><h2>The Origin and History of Lord Ganesha</h2></IonText>
Lord Ganesha is believed to have been created by the Hindu goddess Parvati, who formed him out of dirt from her body while taking a bath. When her husband, Lord Shiva, returned and saw the strange boy guarding the entrance to their home, he became angry and cut off Ganesha's head. Parvati was devastated, and so Shiva promised to bring Ganesha back to life by placing the head of the first living creature he found on his neck. That creature turned out to be an elephant, and thus, Ganesha was reborn with the head of an elephant and the body of a human.
<p>
Ganesha's history dates back to the Hindu epic period, around 2,000 BCE to 500 CE, and he is mentioned in several ancient Hindu texts, including the Mahabharata and the Puranas. Over time, Ganesha evolved into a popular deity, and by the 9th century CE, he had become one of the most widely worshipped Hindu gods, with numerous temples and shrines dedicated to him across India.
</p>
<IonText color="primary"><h2>The Symbolism and Significance of Lord Ganesha</h2></IonText>
Lord Ganesha is widely revered for his power to remove obstacles and bring good luck. He is often depicted with a large belly, signifying his ability to consume all obstacles and difficulties. Ganesha's elephant head symbolizes wisdom, while his large ears represent his ability to listen and his small eyes symbolize his ability to focus.
<p>
In Hinduism, Lord Ganesha is worshipped before embarking on any new venture, be it a journey, a new business, or a new project, as it is believed that he will help remove any obstacles and bring success. He is also worshipped during festivals such as Ganesh Chaturthi, which is a ten-day long celebration in honor of Lord Ganesha.
</p>
<IonText color="primary"><h2>The Attributes and Attributes of Lord Ganesha</h2></IonText>
Lord Ganesha is often depicted holding various objects, each of which has a different symbolic meaning. For example, he may be shown holding a lotus flower, which symbolizes purity and enlightenment, or a broken tusk, which symbolizes sacrifice and detachment. He may also be shown holding a sweet, which represents happiness, or a mouse, which symbolizes the conquering of desires.

<IonText color="primary"><h2>The Different Forms and Manifestations of Lord Ganesha</h2></IonText>
Over time, Lord Ganesha has taken on many different forms and manifestations. In some instances, he may be depicted as a fierce warrior, while in others he may be shown as a mischievous child. He may also be depicted with multiple heads, or as an old man with a long beard.
<p>
Regardless of the form or manifestation, Lord Ganesha is always revered and worshipped for his ability to remove obstacles and bring good luck.
</p>
<IonText color="primary"><h2>The Worship of Lord Ganesha</h2></IonText>
Worship of Lord Ganesha takes many forms, from daily prayers to elaborate rituals. One of the most popular ways of worshiping Lord Ganesha is through puja, which involves offering flowers, incense, and food to the deity. Another popular form of worship is through the chanting of mantras, such as the Ganapati Atharvashirsha or the Ganesha Sahasranama.
<p>
In addition to performing puja and chanting mantras, many devotees also participate in Ganesha processions and offer prayers at temples dedicated to the deity. These temples often house large statues of Lord Ganesha and are visited by thousands of devotees each year.
</p>
<IonText color="primary"><h2>The Importance of Lord Ganesha in Hinduism</h2></IonText>
Lord Ganesha holds a significant place in Hinduism and is widely revered as the remover of obstacles and bringer of good luck. He is considered to be the god of beginnings, and it is believed that worshiping him before embarking on any new venture will ensure success and remove any obstacles in the path.
<p>
Lord Ganesha is also seen as a symbol of wisdom and is worshipped for his ability to remove ignorance and bring clarity. He is often invoked for his blessings and is considered to be a protector and guide for his devotees.
</p>
<IonText color="primary"><h2>The Cultural Significance of Lord Ganesha</h2></IonText>
Lord Ganesha has a wide cultural significance in India and neighboring countries and is often depicted in art, literature, and music. He is a popular subject in Hindu mythology and is frequently referenced in Hindu scriptures and texts.
<p>
Ganesha is also a popular subject in Hindu art, and his image can be found on a variety of objects, including paintings, sculptures, and textiles. He is also a popular subject in Indian folk art, and his image can be found on street murals, carvings, and other decorative items.
</p>
<IonText color="primary"><h2>The Global Popularity of Lord Ganesha</h2></IonText>
In recent years, Lord Ganesha has gained popularity outside of India and is now worshipped by Hindus and non-Hindus around the world. This is in part due to the growing popularity of Hinduism and the increasing interest in Indian spirituality and religion.
<p>
Lord Ganesha's popularity has also been fueled by the growth of Hindu communities in countries such as the United States, Canada, and Australia, as well as the increasing number of non-Hindus who are drawn to his image and symbolism.
</p>
<h4>Conclusion</h4>
Lord Ganesha is a revered and beloved Hindu deity, known for his power to remove obstacles and bring good luck. He holds a significant place in Hinduism and is worshipped by Hindus around the world. From his depiction in Hindu scripture to his cultural significance in Indian art and literature, Lord Ganesha's image and symbolism continue to inspire and captivate devotees and non-devotees alike.

<h4>FAQs</h4>
<p><b><i>Who is Lord Ganesha?</i></b></p>
Lord Ganesha is a Hindu deity widely revered as the god of beginnings and remover of obstacles.

<p><b><i>Why is Lord Ganesha worshipped?</i></b></p>
Lord Ganesha is worshipped for his power to remove obstacles and bring good luck. He is considered to be the god of beginnings and is invoked before embarking on any new venture.

<p><b><i>What is the history of Lord Ganesha?</i></b></p>
Lord Ganesha is believed to have been created by the Hindu goddess Parvati and is mentioned in several ancient Hindu texts, including the Mahabharata and the Puranas. He has evolved over time into one of the most widely worshipped Hindu gods.

<p><b><i>What is the significance of Lord Ganesha's attributes?</i></b></p>
Lord Ganesha's attributes, such as his large belly and elephant head, have symbolic meaning and represent his power to remove obstacles and bring wisdom.

<p><b><i>How is Lord Ganesha worshipped?</i></b></p>
Worship of Lord Ganesha can take many forms, including puja, chanting of mantras, and offering prayers at temples dedicated to the deity.
        <h1><b>|| गणपति बप्पा के प्रिय मंत्र / विघ्न-बाधा दूर करने के लिए||</b></h1>
        <IonText color="tertiary">
        <h1 ><b>"ऊं एकदन्ताय विद्महे वक्रतुंडाय धीमहि तन्नो बुदि्ध प्रचोदयात ।। "</b></h1>
        </IonText>
        <IonListHeader lines="full"></IonListHeader>
        <h1><b>||गृह क्लेश दूर करने के लिए ||</b></h1>
        <IonText color="danger">
        <h1><b> "ॐ ग्लौम गौरी पुत्र, वक्रतुंड, गणपति गुरु गणेश । </b></h1>
        <h1><b>ग्लौम गणपति, ऋद्धि पति, सिद्धि पति,, करो दूर क्लेश ।।"</b></h1>
        </IonText>
        <IonListHeader lines="full"></IonListHeader>
        
        <h2><b>गणपति जी और कुबेर को प्रसन्न करने के लिए</b></h2>
        <p> <IonText color="danger"><b> ऊं नमो गणपतये कुबेर येकद्रिको फट् स्वाहा ।।</b></IonText></p>
        <IonListHeader lines="full"></IonListHeader>
        
        <h2><b>मनोकामना पूर्ण करने के लिए</b></h2>
        <p> <IonText color="danger"><b> गणपतिर्विघ्नराजो लम्बतुण्डो गजाननः। द्वैमातुरश्च हेरम्ब एकदन्तो गणाधिपः॥</b></IonText></p>
        <IonListHeader lines="full"></IonListHeader>
        
        <h2><b>करियर में सफलता पाने के लिए</b></h2>
        <p> <IonText color="danger"><b>ॐ श्रीं गं सौभाग्य गणपतये। वर्वर्द सर्वजन्म में वषमान्य नम:।।</b></IonText></p>
        <IonListHeader lines="full"></IonListHeader>

        <h3><b> शत्रुनाशक और संकटों से बचने के लिए इन मंत्रों का जाप करें</b></h3>
        <p><IonText color="danger"><b> ॐ गं गणपतये नम:।।</b></IonText></p>
        <p><IonText color="danger"><b>ॐ वक्रतुण्डाय हुं।।</b></IonText></p>
        <p><IonText color="danger"><b> ॐ सिद्ध लक्ष्मी मनोरहप्रियाय नमः।।</b></IonText></p>
        <p><IonText color="danger"><b> ॐ मेघोत्काय स्वाहा।।</b></IonText></p>
        <p><IonText color="danger"><b> ॐ श्रीं ह्रीं क्लीं ग्लौं गं गणपतये वर वरद सर्वजनं मे वशमानय स्वाहा ।।</b></IonText></p>
        <p> <IonText color="danger"><b> ॐ नमो हेरम्ब मद मोहित मम् संकटान निवारय-निवारय स्वाहा।।</b></IonText></p>
        <h5>गणेश चतुर्थी पर करें इन चमत्कारी मंत्रों का जाप, हर संकट से रहेंगे दूर</h5>
        <IonRow>
        <Adsense
                client="ca-pub-4029214824221330"
                slot="2310889447"
                style={{ display: "block" }}
                format="auto"
                responsive="true"
              />
        </IonRow>
        </IonGrid>
        </div>
        
    </div>
  );
};

export default Ganesh;

