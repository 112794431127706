import {IonRow,IonCol, IonGrid, IonButton, IonText, IonImg, IonIcon, IonListHeader  } from '@ionic/react';
import {Adsense} from '@ctrl/react-adsense';


import './Yagyarudra.css';


const Yagyarudra: React.FC = () => {
  return (
    <div className="ion-padding">
        <h1>Maha Rudra Yagya</h1>
        <p>Maha Rudra Yagya is a Hindu ritual that is performed to invoke the blessings of Lord Shiva, one of the most powerful deities in the Hindu pantheon. The yagya is considered to be a powerful tool for removing negative influences and obstacles, and for bringing blessings of health, wealth, and prosperity.</p>
        <p>The ritual is performed by a trained priest, who will chant mantras and make offerings to Lord Shiva and other associated deities. These offerings can include fruits, flowers, and grains, as well as ghee, milk, and other items. The priest will also light a sacred fire, known as the "yagya fire," which is considered to be a conduit for the blessings and positive energy to flow to the individual for whom the yagya is being performed.</p>
        <p>One of the key aspects of Maha Rudra Yagya is that it is considered to be a powerful tool for removing negative influences and obstacles that may be preventing an individual from achieving success. The ritual is believed to purify and balance the energy of the individual, so that they may move forward with their goals with greater ease and confidence.</p>
        <p>It is also believed that the positive energy generated by the yagya can help to attract positive people and opportunities into an individual's life. This can include new business opportunities, promotions at work, or even finding a suitable partner.</p>
        <p>The Maha Rudra Yagya is also considered to be a powerful tool for removing negative energies and malefic effects caused by planets, curses, black magic or any other negative energies. It is also said to be powerful for healing the body, mind and soul.</p>
        <p>It is important to note that Maha Rudra Yagya is not a quick fix or a magic solution, but rather a powerful tool for helping individuals achieve their goals. The individual for whom the yagya is being performed must also take responsibility for their actions and put in the necessary effort and hard work to achieve their goals.</p>
        <p>Overall, Maha Rudra Yagya is a powerful ritual that can be used to invoke the blessings of Lord Shiva and other associated deities. It is believed to bring blessings and positive energy, remove obstacles and negative influences, and attract positive people and opportunities into one's life. Additionally, it's also said to be powerful for healing and removing negative energies. However, it's important to remember that the individual must also put in the necessary effort and hard work to achieve their goals.</p>
        <IonRow>
        <Adsense
                client="ca-pub-4029214824221330"
                slot="2310889447"
                style={{ display: "block" }}
                format="auto"
                responsive="true"
              />
        </IonRow>
    </div>
  );
};

export default Yagyarudra;

