import React, { useEffect, useState } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { IonApp, IonRouterOutlet, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import Master from './pages/master/Master';


/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import { Toolbar } from '@ionic/core/dist/types/components/toolbar/toolbar';
import { auth } from './config/firebase';

setupIonicReact();

const App: React.FC = () => {

  const [loading, setLoading] = useState<boolean>(true);

  // Monitor and Update user state.
  useEffect(() => {
    auth.onAuthStateChanged(user => {
      if (user) {
        console.log('User detected.')
      } else {
        console.log('No user detected');
      }
      setLoading(false);
    })
  }, []);
  if (loading)
    return <div>Loding...</div>

  return (
  <IonApp>
    <IonReactRouter>
      <IonRouterOutlet>
        
        <Route 
          exact 
          path="/services"
          render={(props) => {
            return <Master {...props} />;
          }}
        />
        <Route 
          exact 
          path="/home"
          render={(props) => {
            return <Master {...props} />;
          }}
        />
        <Route 
          exact 
          path="/products"
          render={(props) => {
            return <Master {...props} />;
          }}
        />
        <Route 
          exact 
          path="/career"
          render={(props) => {
            return <Master {...props} />;
          }}
        />
        <Route 
          exact 
          path="/panchang"
          render={(props) => {
            return <Master {...props} />;
          }}
        />
        <Route 
          exact 
          path="/granth"
          render={(props) => {
            return <Master {...props} />;
          }}
        />
        <Route 
          exact 
          path="/rashiphal"
          render={(props) => {
            return <Master {...props} />;
          }}
        />
        <Route 
          exact 
          path="/contact"
          render={(props) => {
            return <Master {...props} />;
          }}
        />
        <Route 
          exact 
          path="/rudravishek"
          render={(props) => {
            return <Master {...props} />;
          }}
        />
        <Route 
          exact 
          path="/auth/signup"
          render={(props) => {
            return <Master {...props} />;
          }}
        />
        <Route 
          exact 
          path="/satyanarayan"
          render={(props) => {
            return <Master {...props} />;
          }}
        />
        <Route 
          exact 
          path="/bhagwat"
          render={(props) => {
            return <Master {...props} />;
          }}
          />
        <Route 
          exact 
          path="/bhagwatgeeta"
          render={(props) => {
            return <Master {...props} />;
          }}
        />
        <Route 
          exact 
          path="/bhagwatgeeta2"
          render={(props) => {
            return <Master {...props} />;
          }}
        />
        <Route 
          exact 
          path="/bhagwatgeeta3"
          render={(props) => {
            return <Master {...props} />;
          }}
        />
        <Route 
          exact 
          path="/ramcharit"
          render={(props) => {
            return <Master {...props} />;
          }}
        />
        <Route 
          exact 
          path="/ramcharitbalkand"
          render={(props) => {
            return <Master {...props} />;
          }}
        />
        <Route 
          exact 
          path="/mirtunjay"
          render={(props) => {
            return <Master {...props} />;
          }}
        />
        <Route 
          exact 
          path="/mahalakshmijaap"
          render={(props) => {
            return <Master {...props} />;
          }}
        />
        <Route 
          exact 
          path="/mahasarasawatijaap"
          render={(props) => {
            return <Master {...props} />;
          }}
        />
        <Route 
          exact 
          path="/ganesh"
          render={(props) => {
            return <Master {...props} />;
          }}
        />
        <Route 
          exact 
          path="/vastu"
          render={(props) => {
            return <Master {...props} />;
          }}
        />
        <Route 
          exact 
          path="/bhumipujan"
          render={(props) => {
            return <Master {...props} />;
          }}
        />
        
        <Route
        exact
        path ="/navgrah"
        render={(props) => {
          return <Master {...props} />;
        }}
        />
        <Route 
          exact 
          path="/photos"
          render={(props) => {
            return <Master {...props} />;
          }}
        />
        <Route 
          exact 
          path="/firstp"
          render={(props) => {
            return <Master {...props} />;
          }}
        />
        <Route exact path="/anantpuja" render={(props) => {return <Master {...props} />;
          }}
        />
        <Route exact path="/ekadashi" render={(props) => {return <Master {...props} />;
          }}
        />
        <Route exact path="/janmotasavapuja" render={(props) => {return <Master {...props} />;
          }}
        />
        <Route exact path="/jirngrihprawesh" render={(props) => {return <Master {...props} />;
          }}
        />
        <Route exact path="/laxmipuja" render={(props) => {return <Master {...props} />;
          }}
        />
        <Route exact path="/mundan" render={(props) => {return <Master {...props} />;
          }}
        />
        <Route exact path="/navgrihprawesh" render={(props) => {return <Master {...props} />;
          }}
        />
        <Route exact path="/navratri" render={(props) => {return <Master {...props} />;
          }}
        />
        <Route exact path="/ramnavami" render={(props) => {return <Master {...props} />;
          }}
        />
        <Route exact path="/shadi" render={(props) => {return <Master {...props} />;
          }}
        />
        <Route exact path="/shivratri" render={(props) => {return <Master {...props} />;
          }}
        />
        <Route exact path="/teej" render={(props) => {return <Master {...props} />;
          }}
        />
        <Route exact path="/vishwakarmapuja" render={(props) => {return <Master {...props} />;
          }}
        />
        <Route exact path="/vyaparshubharambhpuja" render={(props) => {return <Master {...props} />;
          }}
        />
        <Route exact path="/vyaparunnatianusthan" render={(props) => {return <Master {...props} />;
          }}
        />
        <Route exact path="/yagyagyatri" render={(props) => {return <Master {...props} />;
          }}
        />
        <Route exact path="/yagyaaswamedh" render={(props) => {return <Master {...props} />;
          }}
        />
        <Route exact path="/yagyakaryasiddhi" render={(props) => {return <Master {...props} />;
          }}
        />    
        <Route exact path="/yagyarudra" render={(props) => {return <Master {...props} />;
          }}
        />
        <Route exact path="/yagyasurya" render={(props) => {return <Master {...props} />;
          }}
        />
        <Route exact path="/yagyavishnu" render={(props) => {return <Master {...props} />;
          }}
        />
        <Route exact path="/triyadashi" render={(props) => {return <Master {...props} />;
          }}
        />
       
        <Route exact path="/">
          <Redirect to="/firstp" />
          
        </Route>
      </IonRouterOutlet>
    </IonReactRouter>
  </IonApp>
  );
};

export default App;
