import { IonGrid, IonRow, IonImg, IonCol, IonListHeader} from '@ionic/react';
import './Triyadashi.css';


const Triyadashi: React.FC = () => {
  return (
      <div className="ion-padding">
            <IonGrid fixed={true}>
            
            <div className="ion-text-center">          
                <h1><p>cbsjbskcs</p></h1>
                <p>sdckjbdbjc</p>
                <p>cdskjbcsdbc</p>
                <p>cdsnbcdsbn</p>
                <p>jhsagdbs jvdsfuh skjdhdskf jkdiuds </p>
                <p>bhjasgd sdjhfdgs hfgdsu </p>
                <p><b>xjhasgcsa chjsdgc jhcdsgc</b></p>
                <p><b><i>jhcgsdfg cbjhsdjfsjd</i></b></p>
            </div>
            <IonRow><IonCol>
          </IonCol></IonRow>
          </IonGrid>
            
        </div>
               
  );
};

export default Triyadashi;

