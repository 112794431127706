import React, { FormEventHandler, useState } from 'react';
import { IonButton, IonInput, IonItem, IonLabel, IonList, IonGrid, IonRow, IonCol, IonListHeader} from '@ionic/react';
import {Adsense} from '@ctrl/react-adsense';
import './Contact.css';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

export interface InputDetail {
  value: any;
  checked: boolean;
}
function Contact() {
  const onSubmit = () => {
    let tutorialsRef = firebase.firestore().collection("/contactUs");
    console.log('Value ', name)
    tutorialsRef.add({
      name,
      dob,
      tob,
      pob,
      email,
      mob,
      hno,
      gln,
      loc,
      dist,
      state,
      cont,
      pin,
      mess,
    }).then(function(docRef: any) {
      console.log("Contact us created with ID: ", docRef.id);
    });
  }
  const [name, setName] = useState('');
  const [dob, setDob] = useState('');
  const [tob, setTob]= useState('');
  const [pob, setPob]= useState('');
  const [email, setEmail] = useState('');
  const [mob, setMob] = useState('');
  const [hno, setHno] = useState('');
  const [gln, setGln] = useState('');
  const [loc, setLoc] = useState('');
  const [dist, setDist] = useState('');
  const [state, setState] = useState('');
  const [cont, setCont] = useState('');
  const [pin, setPin] = useState('');
  const [mess, setMess] = useState('');




  const onNameInput = (ev: Event) => {
    const value = (ev.target as HTMLInputElement).value;
    setName(value);
  };

  const onDobInput = (ev: Event) => {
    const value = (ev.target as HTMLInputElement).value;
    setDob(value);
  };
  const onTobInput = (ev: Event) =>{
    const value = (ev.target as HTMLInputElement).value;
    setTob(value);
  };
  const onPobInput = (ev: Event) =>{
    const value = (ev.target as HTMLInputElement).value;
    setPob(value);
  };
  const onEmailInput = (ev: Event) => {
    const value = (ev.target as HTMLInputElement).value;
    setEmail(value);

    // setIsValid(undefined);

    // if (value === '') return;

    // validateEmail(value) !== null ? setIsValid(true) : setIsValid(false);
  };
  const onMobInput = (ev: Event) =>{
    const value = (ev.target as HTMLInputElement).value;
    setMob(value);
  };
  const onHnoInput = (ev: Event) =>{
    const value = (ev.target as HTMLInputElement).value;
    setHno(value);
  };
  const onGlnInput = (ev: Event) =>{
    const value = (ev.target as HTMLInputElement).value;
    setGln(value);
  };
  const onLocInput = (ev: Event) =>{
    const value = (ev.target as HTMLInputElement).value;
    setLoc(value);
  };
  const onDistInput = (ev: Event) =>{
    const value = (ev.target as HTMLInputElement).value;
    setDist(value);
  };
  const onStateInput = (ev: Event) =>{
    const value = (ev.target as HTMLInputElement).value;
    setState(value);
  };
  const onContInput = (ev: Event) =>{
    const value = (ev.target as HTMLInputElement).value;
    setCont(value);
  };
  const onPinInput = (ev: Event) =>{
    const value = (ev.target as HTMLInputElement).value;
    setPin(value);
  };
  const onMessInput = (ev: Event) =>{
    const value = (ev.target as HTMLInputElement).value;
    setMess(value);
  };



  return (
   
    <IonList>
      <IonGrid fixed={true}>
        <IonRow><IonCol><h1>Contact Us</h1></IonCol></IonRow>
        <IonRow>
          <IonCol>
            <IonItem>
              <IonLabel>Your Name : </IonLabel>
              <IonInput onIonInput={onNameInput} clearInput={true} value={name} maxlength={25} placeholder="Enter Your Name"></IonInput>
            </IonItem>
          </IonCol>
          <IonCol>
            <IonItem>
              <IonLabel>Date Of Birth : </IonLabel>
              <IonInput onIonInput={onDobInput} clearInput={true} type="date" value={dob} placeholder="dd/mm/yyyy"></IonInput>
            </IonItem>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonItem>
              <IonLabel>Time Of Birth : </IonLabel>
              <IonInput onIonInput={onTobInput} clearInput={true} type="time" value={tob} placeholder="hh:mm"></IonInput>
            </IonItem>
          </IonCol>
          <IonCol>
            <IonItem>
              <IonLabel>Place Of Birth : </IonLabel>
              <IonInput onIonInput={onPobInput} clearInput={true} value={pob} maxlength={25} placeholder="Enter Your Birth City Name"></IonInput>
            </IonItem>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>  
            <IonItem>
              <IonLabel>Email input : </IonLabel>
              <IonInput onIonInput={onEmailInput} clearInput={true} type="email" value={email} placeholder="email@domain.com"></IonInput>
            </IonItem>
          </IonCol>
          <IonCol>
            <IonItem>
              <IonLabel>Mobile Number : </IonLabel>
              <IonInput onIonInput={onMobInput} clearInput={true} type="tel" value={mob} placeholder="888-888-8888"></IonInput>
            </IonItem>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonItem>
              <IonLabel>House No. : </IonLabel>
              <IonInput  onIonInput={onHnoInput} clearInput={true} maxlength={20} value={hno} placeholder="Enter text"></IonInput>
            </IonItem>
          </IonCol>
          <IonCol>
            <IonItem>
              <IonLabel>Gali No. : </IonLabel>
              <IonInput  onIonInput={onGlnInput} clearInput={true} maxlength={20} value={gln} placeholder="Enter text"></IonInput>
            </IonItem>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonItem>
              <IonLabel>Locality : </IonLabel>
              <IonInput onIonInput={onLocInput} clearInput={true} value={loc} maxlength={20} placeholder="Enter text"></IonInput>
            </IonItem>
          </IonCol>
          <IonCol>
            <IonItem>
              <IonLabel>District : </IonLabel>
              <IonInput onIonInput={onDistInput} clearInput={true} value={dist} maxlength={20} placeholder="Enter text"></IonInput>
            </IonItem>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonItem>
              <IonLabel>State : </IonLabel>
              <IonInput onIonInput={onStateInput} clearInput={true} value={state} maxlength={20} placeholder="Enter text"></IonInput>
            </IonItem>
          </IonCol>
          <IonCol>
            <IonItem>
              <IonLabel>Country : </IonLabel>
              <IonInput onIonInput={onContInput} clearInput={true} value={cont} maxlength={20} placeholder="Enter text"></IonInput>
            </IonItem>
          </IonCol>
          <IonCol>
            <IonItem>
              <IonLabel>Pin Code : </IonLabel>
              <IonInput onIonInput={onPinInput} clearInput={true} value={pin} maxlength={6} placeholder="111111"></IonInput>
            </IonItem>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>अपेक्षित सेवा (Services Required)/Message</IonCol>
            <IonInput onIonInput={onMessInput} clearInput={true} value={mess} maxlength={100} placeholder="Enter text"></IonInput>
            <IonListHeader lines="full"></IonListHeader>
        </IonRow>
      </IonGrid>
        <div className="ion-text-center">
          <IonButton onClick={onSubmit}>Submit</IonButton>
        </div>
        <IonListHeader lines="full"></IonListHeader>

        <IonRow><IonCol>
          <Adsense
            client="ca-pub-4029214824221330"
            slot="2310889447"
            style={{ display: "block" }}
            format="auto"
            responsive="true"
          />
          </IonCol></IonRow>
  </IonList>
  
    
  );
};

export default Contact;