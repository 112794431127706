import {IonRow,IonCol, IonGrid, IonButton, IonText, IonImg, IonIcon, IonListHeader  } from '@ionic/react';
import {Adsense} from '@ctrl/react-adsense';


import './Yagyaaswamedh.css';


const Yagyaaswamedh: React.FC = () => {
  return (
    <div className="ion-padding">
        <h1>Aswamedha Yagya </h1>
        <p>Aswamedha Yagya is a Hindu ritual that is considered to be one of the most important and elaborate rituals in the Vedic tradition. It is a horse sacrifice ritual that is performed to invoke the blessings of the gods and goddesses, and to attain spiritual and material prosperity.</p>
        <p>The word Aswamedha is derived from two words: "Aswa" meaning horse, and "medha" meaning sacrifice. The ritual involves the sacrifice of a horse, which is considered to be a symbol of power and strength. The ritual is usually performed by a king or a ruler as a symbol of his power and authority over his kingdom.</p>
        <p>The Aswamedha Yagya is a complex ritual that involves various ceremonies and offerings. It begins with the selection of a horse and the consecration of the horse by a team of priests. The horse is then set loose and is followed by a team of warriors who protect the horse as it travels through different kingdoms. If a king or ruler stops the horse, it is considered to be a challenge to the authority of the performer of the yagya. The horse is then brought back and sacrificed during the ritual.</p>
        <p>The ritual also includes the recitation of mantras and the offering of various items such as ghee, milk, and grains to the gods and goddesses. The ritual is usually performed by a team of priests and is considered to be a way to attain blessings for the performer, his family, and his kingdom.</p>
        <p>In conclusion, Aswamedha Yagya is a Hindu ritual that is considered to be one of the most important and elaborate rituals in the Vedic tradition. It is a horse sacrifice ritual that is performed to invoke the blessings of the gods and goddesses, and to attain spiritual and to attain spiritual and material prosperity. It is a complex ritual that involves various ceremonies and offerings, including the sacrifice of a horse, which is considered to be a symbol of power and strength. The ritual is usually performed by a king or a ruler as a symbol of his power and authority over his kingdom. It is believed that by performing the Aswamedha Yagya, one can attain blessings for oneself, one's family, and one's kingdom, and also to attain success, prosperity and peace. It is a way to connect with tradition and spirituality and is considered to be an act of devotion and surrender to the divine.</p>
        <IonRow>
        <Adsense
                client="ca-pub-4029214824221330"
                slot="2310889447"
                style={{ display: "block" }}
                format="auto"
                responsive="true"
              />
        </IonRow>
    </div>
  );
};

export default Yagyaaswamedh;

