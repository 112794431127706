import {IonRow,IonCol, IonGrid, IonButton, IonText, IonImg, IonIcon, IonListHeader  } from '@ionic/react';
import {Adsense} from '@ctrl/react-adsense';


import './Jirngrihprawesh.css';


const Jirngrihprawesh: React.FC = () => {
  return (
    <div className="ion-padding">
      <IonGrid fixed={true}>
      <div className="ion-text-justify">
      <h1> जीर्ण गृह प्रवेश </h1>
         <p> जीर्ण गृह प्रवेश एक हिंदू अनुष्ठान है जो तब किया जाता है जब कोई व्यक्ति पहली बार नए घर में प्रवेश करता है। इसे "गृह प्रवेश" या "वास्तु पूजा" के रूप में भी जाना जाता है। यह अनुष्ठान पुराने घर को शुद्ध करने और सकारात्मक ऊर्जा और आशीर्वाद को घर में आमंत्रित करने के लिए किया जाता है।</p>
         <p> जीर्ण गृह प्रवेश अनुष्ठान में आमतौर पर एक पूजा (पूजा) समारोह शामिल होता है जो एक हिंदू पुजारी द्वारा किया जाता है। समारोह में मंत्रों का पाठ, विभिन्न देवताओं को प्रसाद और दीया (तेल के दीपक) जलाना शामिल है। घर के प्रवेश द्वार पर एक नारियल भी फोड़ा जाता है, जो बाधाओं को तोड़ने और घर में सौभाग्य को आमंत्रित करने का प्रतीक है।</p>
         <p>समारोह के दौरान, पुजारी द्वारा घर के नए रहने वालों को तिलक (पवित्र राख या चंदन के पेस्ट के साथ माथे पर बनाया गया एक निशान) और अक्षत (अखंड चावल के दाने) से भी आशीर्वाद दिया जाता है। ये प्रतीक सौभाग्य और समृद्धि का प्रतिनिधित्व करते हैं।</p>
         <p>ऐसा माना जाता है कि नए घर में जाने से पहले जीर्ण गृह प्रवेश अनुष्ठान करने से रहने वालों के लिए सौभाग्य और समृद्धि आती है। यह भी माना जाता है कि यह घर में मौजूद किसी भी नकारात्मक ऊर्जा या आत्माओं को दूर भगाता है।</p>
         <p>जीर्ण गृह प्रवेश समारोह के दौरान जिन अनुष्ठानों और विशिष्ट देवताओं की पूजा की जाती है, वे समारोह करने वाले व्यक्ति के क्षेत्र और व्यक्तिगत मान्यताओं के आधार पर भिन्न हो सकते हैं। कुछ परिवार अपने परिवार के सदस्यों और दोस्तों को समारोह में भाग लेने के लिए आमंत्रित कर सकते हैं ताकि इसे और अधिक आनंदमय बनाया जा सके और नए घर का आशीर्वाद साझा किया जा सके।</p>
         <p>निष्कर्ष में, जीर्ण गृह प्रवेश, जिसे गृह प्रवेश या वास्तु पूजा के रूप में भी जाना जाता है, एक हिंदू अनुष्ठान है जिसे तब किया जाता है जब कोई व्यक्ति पहली बार किसी नए घर में प्रवेश करता है। यह अनुष्ठान नए घर को शुद्ध करने और घर में सकारात्मक ऊर्जा और आशीर्वाद को आमंत्रित करने के लिए किया जाता है। ऐसा माना जाता है कि यह घर में रहने वालों के लिए सौभाग्य और समृद्धि लाता है और घर में मौजूद किसी भी नकारात्मक ऊर्जा या आत्माओं को दूर भगाता है। यह घर को पवित्र करने और सर्वशक्तिमान के आशीर्वाद के साथ उसमें रहना शुरू करने का एक तरीका है।</p>
         <IonListHeader lines="full"></IonListHeader>
         <IonRow>
        <Adsense
                client="ca-pub-4029214824221330"
                slot="2310889447"
                style={{ display: "block" }}
                format="auto"
                responsive="true"
              />
        </IonRow>
         <h1> Jeern Grih Prawesh  </h1>
        <p> Jeern Grih Prawesh is a Hindu ritual that is performed when a person enters a new house for the first time. It is also known as "Griha Pravesh" or "Vastu Puja". The ritual is performed to purify the old house and to invite positive energy and blessings into the home.</p>
        <p> The Jeern Grih Prawesh ritual typically involves a puja (worship) ceremony that is performed by a Hindu priest. The ceremony includes the recitation of mantras, offerings to various deities, and the lighting of diyas (oil lamps). A coconut is also broken at the entrance of the house, which is a symbol of breaking the obstacles and inviting good fortune in the house.</p>
        <p>During the ceremony, the new occupants of the house are also blessed with Tilak (a mark made on the forehead with sacred ash or sandalwood paste) and Akshata (unbroken rice grains) by the priest. These symbols represent good luck and prosperity.</p>
        <p>It is believed that performing the Jeern Grih Prawesh ritual before moving into a new house brings good luck and prosperity to the occupants. It is also believed to ward off any negative energy or spirits that may be present in the house.</p>
        <p>The rituals and the specific gods that are worshipped during the Jeern Grih Prawesh ceremony may vary depending on the region and personal beliefs of the person performing the ceremony. Some families might also invite their family members and friends to participate in the ceremony to make it more joyous and to share the blessings of the new home.</p>
        <p>In conclusion, Jeern Grih Prawesh, also known as Griha Pravesh or Vastu Puja, is a Hindu ritual that is performed when a person enters a new house for the first time. The ritual is performed to purify the new house and to invite positive energy and blessings into the home. It is believed to bring good luck and prosperity to the occupants and ward off any negative energy or spirits that may be present in the house. It is a way to sanctify the home, and to start living in it with blessings from the almighty.</p>
        <IonRow>
        <Adsense
                client="ca-pub-4029214824221330"
                slot="2310889447"
                style={{ display: "block" }}
                format="auto"
                responsive="true"
              />
        </IonRow>
        </div>
        </IonGrid>
        
    </div>
  );
};

export default Jirngrihprawesh;

