import {IonRow,IonCol, IonGrid, IonButton, IonText, IonImg, IonIcon, IonListHeader  } from '@ionic/react';
import {Adsense} from '@ctrl/react-adsense';


import './Yagyagyatri.css';


const Yagyagyatri: React.FC = () => {
  return (
    <div className="ion-padding">
        <h1>Gayatri Yagya</h1>
       <p>Gayatri Yagya is a Hindu ritual that is performed to invoke the blessings of the Gayatri Mantra, one of the most sacred and powerful mantras in Hinduism. The Gayatri Mantra is a verse from the Rigveda, one of the oldest sacred texts in Hinduism, and is considered to be a powerful tool for spiritual growth and realization.</p>
       <p>The Gayatri Yagya is typically performed by a team of priests, who recite the Gayatri Mantra while offering oblations to the divine. The ritual involves the use of sacred fire, known as the yajna fire, which is considered to be a medium for offering the oblations to the gods and goddesses. The ritual also includes the use of various other offerings such as ghee, milk, and grains, which are offered to the divine as a symbol of devotion and surrender.</p>
       <p>The Gayatri Yagya is considered to be an act of devotion and surrender to the divine, and is believed to bring blessings for spiritual growth, material prosperity, and overall well-being. It is also believed that by performing the Gayatri Yagya, one can attain blessings for the attainment of the ultimate spiritual goal, which is liberation from the cycle of birth and death.</p>
       <p>In conclusion, Gayatri Yagya is a Hindu ritual that is performed to invoke the blessings of the Gayatri Mantra, one of the most sacred and powerful mantras in Hinduism. The ritual involves the use of sacred fire, oblations and offerings, and is typically performed by a team of priests. It is considered to be an act of devotion and surrender to the divine, and is believed to bring blessings for spiritual growth, material prosperity and overall well-being. It is also believed that by performing the Gayatri Yagya, one can attain blessings for the attainment of the ultimate spiritual goal, which is liberation from the cycle of birth and death.</p>
       <IonRow>
        <Adsense
                client="ca-pub-4029214824221330"
                slot="2310889447"
                style={{ display: "block" }}
                format="auto"
                responsive="true"
              />
        </IonRow>
    </div>
  );
};

export default Yagyagyatri;

