import {IonRow,IonCol, IonGrid, IonImg, IonText, IonListHeader} from '@ionic/react';
// import { auth } from '../../config/firebase';
import {Adsense} from '@ctrl/react-adsense';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

import './Home.css';

const Home: React.FC = () => {
   return (
    <IonGrid fixed={true}>
      <IonImg src="/assets/images/home_banner.png" />
      <div className="ion-padding">

      <IonRow>
        <Carousel
          showThumbs={false}
          autoPlay={true}
          interval={1500}
          infiniteLoop
        >
          <div>
              <img src="/assets/images/ramcharit/balkand/balkand_s1.jpg" />
              {/* <p className="legend">Legend 1</p> */}
          </div>
          <div>
              <img src="/assets/images/ramcharit/balkand/balkand_s2.jpg" />
              {/* <p className="legend">Legend 2</p> */}
          </div>
          <div>
              <img src="/assets/images/ramcharit/balkand/balkand_s3.jpg" />
              {/* <p className="legend">Legend 3</p> */}
          </div>
          <div>
              <img src="/assets/images/ramcharit/balkand/balkand_s4.jpg" />
              {/* <p className="legend">Legend 3</p> */}
          </div>
          <div>
              <img src="/assets/images/ramcharit/balkand/balkand_s5.jpg" />
              {/* <p className="legend">Legend 3</p> */}
          </div>
          <div>
              <img src="/assets/images/ramcharit/balkand/balkand_s6.jpg" />
              {/* <p className="legend">Legend 3</p> */}
          </div>
          <div>
              <img src="/assets/images/ramcharit/balkand/balkand_s7.jpg" />
              {/* <p className="legend">Legend 3</p> */}
          </div>
        </Carousel>
      </IonRow>

      <IonRow>
        <IonCol>
          <Adsense
            client="ca-pub-4029214824221330"
            slot="2310889447"
            style={{ display: "block" }}
            format="auto"
            responsive="true"
          />
        </IonCol>
      </IonRow>
      <div className="ion-text-justify">   
<IonText color="danger"><h1>Welcome to Sanatan Puja - Your Ultimate Destination for Spiritual Connections!</h1></IonText>
At Sanatan Puja, we believe in the power of spirituality and its impact on our lives. Our aim is to provide a platform that connects individuals to their spiritual self and helps them deepen their connection with their faith.
<p>
From Hindu puja rituals to Sanskrit slokas, we offer a comprehensive collection of resources that cater to your spiritual needs. Our website is designed to help you perform your daily puja in the comfort of your own home, and also provide a deeper understanding of the religious practices and their significance.
</p>
<IonText color="primary"><h2>Explore Our Wide Range of Puja Services</h2></IonText>
Whether you're looking to perform a simple puja or conduct a grand religious ceremony, our team of experts is here to help. We offer a variety of puja services to meet your spiritual needs, including:
<IonText color="primary"><h3>
<p>Daily Puja</p>
<p>Griha Pravesh Puja</p>
<p>Vastu Puja</p>
<p>Navgraha Puja</p>
<p>Marriage Puja</p>
<p>Havan Puja</p></h3></IonText>
We understand the importance of performing pujas in the correct manner, and our experts will guide you through every step of the way.

<IonText color="primary"><h2>Connect with Our Spiritual Community</h2></IonText>

At Sanatan Puja, we are committed to building a strong spiritual community. We provide a forum for individuals to connect, share their experiences and seek guidance from experienced spiritual leaders. Our community is a safe and inclusive space for individuals of all ages, genders and religious backgrounds to come together and deepen their spiritual connection.

<IonText color="primary"><h2>Experience the Power of Sanskrit Slokas</h2></IonText>

Sanskrit slokas are an integral part of Hindu spirituality and hold immense power. We offer a collection of slokas and their meanings, along with audio recordings to help you learn and recite them with ease. Our slokas cover a range of topics, including blessings, protection, and peace.

<IonText color="primary"><h2>Get Access to Our Premium Resources</h2></IonText>

At Sanatan Puja, we offer a range of premium resources to help you deepen your spiritual connection. From personalized puja videos to guided meditations, our premium resources provide an immersive experience that will take your spiritual journey to the next level.
<h2>
Conclusion
</h2>
We hope that Sanatan Puja becomes your go-to destination for all things spiritual. Our aim is to provide you with a comprehensive collection of resources that will help you deepen your connection with your faith and spiritual self. Whether you're looking for information on Hindu puja rituals or guidance on Sanskrit slokas, we've got you covered.

<h2>FAQs</h2>
<p><b><i>
What is Sanatan Puja?</i></b></p>
Sanatan Puja is a platform that provides resources and services related to Hindu puja and spirituality.

<p><b><i>What services do you offer?</i></b></p>
We offer a variety of puja services, including daily puja, griha pravesh puja, vastu puja, navgraha puja, marriage puja, and havan puja.

<p><b><i>Who can use your services?</i></b></p>
Our services are open to individuals of all ages, genders and religious backgrounds.

<p><b><i>What is the significance of Sanskrit slokas?</i></b></p>
Sanskrit slokas are an integral part of Hindu spirituality and hold immense power. Reciting them regularly can bring blessings, protection and peace into your life.

<p><b><i>What makes Sanatan Puja unique?</i></b></p>
Sanatan Puja offers a comprehensive collection of resources and services related to Hindu puja and spirituality, along with a strong spiritual community to connect and seek guidance from.

          
       {/* <div className="ion-text-justify">
         <h2>'सनातन' का अर्थ है – शाश्वत या 'हमेशा बना रहने वाला', अर्थात् जिसका न आदि है न अन्त। सनातन धर्म मूलतः भारतीय धर्म है, जो किसी जमाने में पूरे संसार में व्याप्त था।</h2>
         <h2>"सनातन धर्म" एक धर्म या, जीवन पद्धति है जिसके अनुयायी अधिकांशतः भारत, नेपाल और मॉरिशस में बहुमत में हैं। इसे विश्व का प्राचीनतम धर्म माना जाता है। इसे 'वैदिक सनातन वर्णाश्रम धर्म' भी कहते हैं जिसका अर्थ है कि इसकी उत्पत्ति मानव की उत्पत्ति से भी पहले से है।सनातन धर्म के मूल तत्व सत्य, अहिंसा, दया, क्षमा, दान, जप, तप आदि है, जिसका शाश्वत महत्व है।</h2>
         <h2>प्राचीन काल में भारतीय सनातन धर्म में गाणपत्य, शैवदेव, वैष्णव, शाक्त और सौर नाम के पाँच सम्प्रदाय होते थे। गाणपत्य- गणेशकी, वैष्णव-विष्णु की, शैवदेव- शिव की, शाक्त-शक्ति की और सौर-सूर्य की पूजा आराधना किया करते थे।</h2>
         <h2>सनातन धर्म में विष्णु, शिव और शक्ति को समान माना गया है और तीनों ही सम्प्रदाय के समर्थक इस धर्म को मानते हैं। सनातन धर्म का सारा साहित्य - वेद, पुराण, श्रुति, स्मृतियाँ, उपनिषद्, रामायण, महाभारत, गीता आदि संस्कृत भाषा में रचा गया है।</h2>
         <h2>कालान्तर में भारतवर्ष में मुसलमान शासन हो जाने के कारण देवभाषा संस्कृत का ह्रास हो गया तथा सनातन धर्म की अवनति होने लगी। इस स्थिति को सुधारने के लिये तत्कालीन विद्वान संत तुलसीदास एवं अन्य क्षेत्रीय विद्वानों ने प्रचलित भाषा में धार्मिक साहित्य की रचना करके सनातन धर्म की रक्षा की।</h2>
         <h2>सनातन परंपरा को मानने वाले के घर में कम से कम पांच देवी-देवता यानी भगवान गणेश, भगवान शिव, देवी दुर्गा, भगवान सूर्य एवं भगवान विष्णु की पूजा अवश्य होनी चाहिए।</h2>
         <IonImg src="assets/images/about-left-image.png" alt="" />
         <IonRow><IonCol>
          <Adsense
            client="ca-pub-4029214824221330"
            slot="2310889447"
            style={{ display: "block" }}
            format="auto"
            responsive="true"
          />
          </IonCol></IonRow>
     
<h3>The meaning of 'Sanatan' is - eternal or 'everlasting', that is, which has neither beginning nor end. Sanatan Dharma is basically an Indian religion, which was once prevalent all over the world.</h3>
<h3>"Sanatana Dharma" is a religion, or way of life, whose adherents are mostly in majority in India, Nepal and Mauritius. It is considered the oldest religion of the world. It is also called 'Vedic Sanatan Varnashram Dharma' which means that its origin is even before the origin of human. The basic elements of Sanatan Dharma are truth, non-violence, kindness, forgiveness, charity, chanting, penance etc., which have eternal importance .</h3>         
<h3>In ancient times, there were five sects in Indian Sanatan Dharma namely Ganapati, Shaiva, Vaishnava, Shakta and Solar. Ganapati- Ganesha, Vaishnava-Vishnu, Shaiva-Shiva, Shakta-Shakti and Solar-Sun worshipped.</h3>         
<h3>In Sanatan Dharma, Vishnu, Shiva and Shakti are considered equal and all three sects follow this religion. All the literature of Sanatan Dharma - Vedas, Puranas, Shrutis, Smritis, Upanishads, Ramayana, Mahabharata, Gita etc. is composed in Sanskrit language.</h3>
<h3>Later on, due to Muslim rule in India, the language of God Sanskrit declined and Sanatan Dharma began to decline. To improve this situation, the then scholar Saint Tulsidas and other regional scholars protected Sanatan Dharma by composing religious literature in popular language.</h3>
<h3>At least five deities i.e. Lord Ganesha, Lord Shiva, Goddess Durga, Lord Surya and Lord Vishnu must be worshiped in the house of a follower of Sanatan tradition.</h3>
<div className="ion-text-center">
<iframe src="https://www.youtube.com/embed/H8B4dmvAV5I" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
</div>
<IonRow><IonCol>
          <Adsense
            client="ca-pub-4029214824221330"
            slot="2310889447"
            style={{ display: "block" }}
            format="auto"
            responsive="true"
          />
          </IonCol></IonRow>

<p>Sanatan Dharma, also known as Hinduism, is one of the oldest and most diverse spiritual traditions in the world. It has no founder and no single holy text, but rather is a collection of beliefs, practices, and teachings that have evolved over thousands of years.</p>
<p>At the heart of Sanatan Dharma is the belief in the unity of all things and the ultimate goal of achieving oneness with the divine. This goal is known as moksha, and it is achieved through spiritual practices such as yoga, meditation, and rituals.</p>
<p>Sanatan Dharma is also a deeply philosophical tradition, with a rich tradition of scriptures and writings on topics such as ethics, metaphysics, and the nature of reality.</p>
<p>One of the key teachings of Sanatan Dharma is the concept of karma, the belief that one's actions in this life determine their future in the next. This belief encourages individuals to act with compassion and kindness towards others, as they will reap the benefits in the long run.</p>
<p>Sanatan Dharma is a diverse and multifaceted tradition, and there is much more to learn about it. We hope this brief introduction has given you a taste of what it has to offer. Thank you for watching.</p>
   
   
     <IonItem><h1>Sanatan Puja: A Comprehensive Guide</h1></IonItem>

         <p>Puja is an important ritual in Hinduism that honoring the deities. The puja is usually performed in the presence of a priest, who will guide you through the proper steps and procedures.</p>
         <p>There are a few things you'll need to do in order to prepare for Sanatan Puja. First, you'll need to gather all of the puja items, which include flowers, incense, a candle, and a small statue or picture of the deity you're honoring. You'll also need to wear traditional Indian clothing, such as a sari or dhoti.</p>
         <p>Once you have all of the puja items, you'll need to find a clean, quiet place to perform the puja. The most important thing is to make sure that the area is clean and free of distractions. Once you're ready, you can begin the puja......</p>
         
         <IonItem><h1>WE ARE LEADING QUALIFIED PANDIT NETWORK</h1></IonItem>
         <p>Sanatan Puja: Providing Qualified Professionals For All Your Needs.</p>
         <p>Sanatan Puja is the largest network of qualified pandits in the world. With over 10,000 members in 10+ countries, we are sure to have a pandit available for any and all of your needs.</p>
         <p>Our members are highly qualified professionals that have undergone extensive training in their field. They are experts in the field of Hindu puja and can provide services for all occasions, from weddings to funerals.</p>
         <p>Our pandits are available for online booking 24/7, so you can be sure to find a pandit that fits your schedule. We also have a convenient mobile app that makes booking a pandit easy and convenient.</p>
         <p>Finding a reputable and qualified pandit can be a difficult task. There are many things to consider, such as experience, qualifications, and pricing. Luckily, the Sanatan Puja Pandit Network makes it easy to find the perfect pandit for your needs. Sanatan Puja Pandit Network is the largest online pandit directory, with over 500 pandits in our network. We only work with qualified and experienced pandits who are experts in their fields. Whether you're looking for a pandit for a puja, a wedding, or any other special occasion, we can help you find the perfect one.</p>
         <p>Best of all, our service is completely free. Simply browse our pandit profiles and request a quote. You can then compare quotes and reviews to find the best pandit for your needs. </p>
         <p>So why wait? Get started today and find the perfect pandit. If you're looking for a qualified and professional pandit for your next event, look no further than the Sanatan Puja Pandit Network. </p>
         <p>Looking For A Qualified And Experienced Pandit? </p>
         <p>Book: India's Leading Qualified Pandit Network</p>
  */}
         </div>
         
         <IonGrid>
         <IonRow><IonCol><IonImg src="/assets/images/aadishiv.jpeg" /></IonCol></IonRow>
         </IonGrid>
         </div>
      </IonGrid>
      
  );
};

export default Home;
