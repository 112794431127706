import {IonRow,IonCol, IonGrid, IonButton, IonText, IonImg, IonIcon, IonListHeader  } from '@ionic/react';
import {Adsense} from '@ctrl/react-adsense';


import './Laxmipuja.css';


const Laxmipuja: React.FC = () => {
  return (
    <div className="ion-padding">
      <IonGrid fixed={true}>
      <div className="ion-text-justify">
      <h1><b> महा लक्ष्मी पूजा </b></h1>
         <p> महा लक्ष्मी पूजा एक हिंदू अनुष्ठान है जो धन, समृद्धि और अच्छे भाग्य की हिंदू देवी महा लक्ष्मी के आशीर्वाद का आह्वान करने के लिए किया जाता है। पूजा दीवाली के त्योहार के दौरान की जाती है, जिसे महा लक्ष्मी के आशीर्वाद का आह्वान करने का सबसे शुभ समय माना जाता है। यह अन्य शुभ दिनों जैसे धनतेरस, दीवाली से एक दिन पहले और शुक्रवार को भी किया जाता है, जिसे देवी का दिन माना जाता है।</p>
         <p>महा लक्ष्मी को भगवान विष्णु की पत्नी माना जाता है और उन्हें धन और समृद्धि की देवी के रूप में जाना जाता है। ऐसा माना जाता है कि जो लोग उनकी पूजा करते हैं उनके लिए सौभाग्य, धन और सफलता लाते हैं। पूजा उनका आशीर्वाद लेने और उनकी सुरक्षा और मार्गदर्शन प्राप्त करने के लिए की जाती है।</p>
         <p>पूजा की शुरुआत पूजा कक्ष की सफाई और सजावट से होती है। एक ऊंचे चबूतरे पर महा लक्ष्मी की एक छोटी मूर्ति या तस्वीर रखी जाती है, जिसे फूल, फल और अनाज से सजाया जाता है। प्रतिमा के सामने एक छोटा सा दीया या दीया जलाया जाता है और पवित्र वातावरण बनाने के लिए अगरबत्ती भी जलाई जाती है।</p>
         <p>पूजा के दौरान महालक्ष्मी की कृपा पाने के लिए कई तरह के मंत्रों और प्रार्थनाओं का पाठ किया जाता है. देवी को फल, फूल और अनाज का प्रसाद चढ़ाया जाता है और उनका आशीर्वाद लिया जाता है। पूजा में लक्ष्मी पुराण जैसे पवित्र ग्रंथों का पाठ भी शामिल है, जो महा लक्ष्मी और उनके आशीर्वाद की कहानी बताता है।</p>
         <p>पूजा पूरी होने के बाद, आरती की जाती है, और महा लक्ष्मी की मूर्ति या तस्वीर को फूलों की माला से सजाया जाता है। ऐसा माना जाता है कि महालक्ष्मी की कृपा से घर में धन, समृद्धि और सौभाग्य आएगा।</p>
         <p>महा लक्ष्मी पूजा एक शक्तिशाली अनुष्ठान है जो हिंदुओं द्वारा धन और समृद्धि की देवी का आशीर्वाद प्राप्त करने के लिए किया जाता है। यह परमात्मा से जुड़ने और समृद्ध और सफल जीवन के लिए आशीर्वाद लेने का एक तरीका है। यह परंपरा, संस्कृति और आध्यात्मिकता से जुड़ने का भी एक शानदार तरीका है।</p>
         <IonListHeader lines="full"></IonListHeader>
         <IonRow>
        <Adsense
                client="ca-pub-4029214824221330"
                slot="2310889447"
                style={{ display: "block" }}
                format="auto"
                responsive="true"
              />
        </IonRow>
        <h1><b> Maha Laxmi Puja </b></h1>
        <p>Maha Laxmi Puja is a Hindu ritual performed to invoke the blessings of Maha Laxmi, the Hindu goddess of wealth, prosperity, and good fortune. The puja is performed during the festival of Diwali, which is considered to be the most auspicious time to invoke the blessings of Maha Laxmi. It is also performed on other auspicious days such as Dhanteras, the day before Diwali, and on Fridays, which are considered to be the day of the goddess.</p>
        <p>Maha Laxmi is considered to be the consort of Lord Vishnu and is known as the goddess of wealth and prosperity. She is believed to bring good luck, wealth, and success to those who worship her. The puja is performed to invoke her blessings and to seek her protection and guidance.</p>
        <p>The puja begins with the cleaning and decoration of the puja room. A small statue or picture of Maha Laxmi is placed on a raised platform, which is decorated with flowers, fruits, and grains. A small lamp or diya is lit in front of the statue and incense sticks are also lit to create a sacred atmosphere.</p>
        <p>During the puja, various mantras and prayers are recited to invoke the blessings of Maha Laxmi. Offerings of fruits, flowers, and grains are made to the goddess and her blessings are invoked. The puja also includes the reading of sacred texts such as the Laxmi Purana, which tells the story of Maha Laxmi and her blessings.</p>
        <p>After the puja is completed, aarti is performed, and the statue or picture of Maha Laxmi is adorned with a garland of flowers. It is believed that the blessings of Maha Laxmi will bring wealth, prosperity, and good fortune to the household.</p>
        <p>Maha Laxmi Puja is a powerful ritual that is performed by Hindus to invoke the blessings of the goddess of wealth and prosperity. It is a way to connect with the divine and to seek blessings for a prosperous and successful life. It is also a great way to connect with the tradition, culture and spirituality.</p>
        <IonRow>
        <Adsense
                client="ca-pub-4029214824221330"
                slot="2310889447"
                style={{ display: "block" }}
                format="auto"
                responsive="true"
              />
        </IonRow>
        </div>
        </IonGrid>
        
    </div>
  );
};

export default Laxmipuja;

