import {IonRow,IonCol, IonGrid, IonButton, IonText, IonImg, IonIcon, IonListHeader  } from '@ionic/react';
import {Adsense} from '@ctrl/react-adsense';


import './Navgrihprawesh.css';


const Navgrihprawesh: React.FC = () => {
  return (
    <div className="ion-padding">
        <h1>Nav Grih Prawesh Puja</h1>
        <p>Nav Grih Prawesh Puja is a traditional Hindu ceremony performed to invoke blessings and good luck for a new home. This puja is performed before moving into a new home and is considered to be a sacred ritual that brings prosperity, happiness, and good fortune to the residents of the home.</p>
        <p>The puja begins with a purification ceremony where the new house is cleaned and decorated with traditional items such as flowers and rangolis. The main puja room is then sanctified by lighting diyas (lamps) and incense sticks, and by placing a small statue of Lord Ganesha, the remover of obstacles, in the room.</p>
        <p>During the puja, various Hindu deities are invoked and offerings are made to them. The main deity invoked during the Nav Grih Prawesh Puja is Lord Vastu, the Hindu god of architecture and construction. Lord Vastu is believed to bring prosperity and good luck to the residents of the home. Offerings of fruits, flowers, and grains are made to Lord Vastu and prayers are recited to invoke his blessings.</p>
        <p>The puja also includes the recitation of mantras and the reading of sacred texts such as the Vastu Shastra, which is a Hindu text that deals with the science of architecture and construction. The Vastu Shastra provides guidelines on how to construct a home in harmony with the natural elements and the principles of the universe.</p>
        <p>After the puja is completed, the residents of the home move into their new home and begin a new chapter of their lives. It is believed that the blessings and good luck invoked during the Nav Grih Prawesh Puja will bring prosperity and happiness to the residents of the home.</p>
        <p>Nav Grih Prawesh Puja is a traditional ritual that is performed by many Hindus before moving into a new home. It is a way of giving thanks to the divine and asking for blessings to the new space. It is also an opportunity to connect with the tradition, culture and spirituality.</p>
        <IonRow>
        <Adsense
                client="ca-pub-4029214824221330"
                slot="2310889447"
                style={{ display: "block" }}
                format="auto"
                responsive="true"
              />
        </IonRow>
    </div>
  );
};

export default Navgrihprawesh;

