import React from 'react';
import { IonImg, IonGrid, IonRow, IonCol, IonButton } from '@ionic/react';
import {Adsense} from '@ctrl/react-adsense';

import './Bhagwatgeeta.css';
    
const Bhagwatgeeta: React.FC = () => {
  return (
      <div className="ion-padding">
        <div className="ion-text-center">
        <h1>संपूर्ण श्रीमद्भगवद्गीता</h1>
        <IonGrid fixed={true}>
        <IonRow><IonCol><h1>प्रथम अध्याय - अर्जुनविषादयोग  </h1></IonCol></IonRow>
        <IonRow><IonCol><IonButton fill="clear" routerLink="/bhagwat">Back To Index Page</IonButton></IonCol><IonButton fill="clear" routerLink="/bhagwatgeeta2"><IonCol>दूसरा अध्याय </IonCol></IonButton></IonRow>
        <IonRow><IonCol><IonImg src="/assets/images/gita/a1/A1.jpg" /></IonCol></IonRow>
        <IonRow><IonCol></IonCol></IonRow>
        <IonRow>
            <IonCol>
                <IonImg src="/assets/images/gita/a1/A1S1.jpg" />
                <h1>श्रीमद्भगवद्गीता</h1>
                <p>प्रथम अध्याय - अर्जुनविषादयोग</p>
                <h4> धृतराष्ट्र उवाच </h4>
                <h1>धर्मक्षेत्रे कुरुक्षेत्रे समवेता युयुत्सवः ।<p>मामकाः पाण्डवाश्चैव किमकुर्वत संजय ॥ (१)</p></h1>
                <p>भावार्थ : </p>
                <p>धृतराष्ट्र ने कहा – हे संजय! धर्म-भूमि और कर्म-भूमि में युद्ध की इच्छा से एकत्र हुए मेरे पुत्रों और पाण्डु के पुत्रों ने क्या किया(१)</p>
                <p>Dhrtarastra said: Sanjaya, gathered on the holy land of Kuruksetra, eager to fight, what did my sons and the sons of Pandu do?</p>
            </IonCol>
        </IonRow>
        <IonRow><IonCol>
          <Adsense
            client="ca-pub-4029214824221330"
            slot="2310889447"
            style={{ display: "block" }}
            format="auto"
            responsive="true"
          />
          </IonCol></IonRow>

        <IonRow>
            <IonCol><IonImg src="/assets/images/gita/a1/A1S2.jpg" />
                <h1>श्रीमद्भगवद्गीता</h1>
                <p>प्रथम अध्याय - अर्जुनविषादयोग</p>
                <p>संजय उवाच</p>
                <h1>दृष्टा तु पाण्डवानीकं व्यूढं दुर्योधनस्तदा ।
                <p>आचार्यमुपसंगम्य राजा वचनमब्रवीत्‌ ॥ (२)</p></h1>
                <p>भावार्थ : </p>
                <p>संजय ने कहा – हे राजन्! इस समय राजा दुर्योधन पाण्डु पुत्रों की सेना की व्यूह-रचना को देखकर आचार्य द्रोणाचार्य के पास जाकर कह रहे हैं। (२)</p>
                <p>Sanjaya said: At that time, seeing the army of the Påndavas drawn up for battle and approaching Dronachary, King Duryodhana spoke the following words.</p>

            </IonCol>
        </IonRow>
        <IonRow><IonCol>
          <Adsense
            client="ca-pub-4029214824221330"
            slot="2310889447"
            style={{ display: "block" }}
            format="auto"
            responsive="true"
          />
          </IonCol></IonRow>

        <IonRow>
            <IonCol><IonImg src="/assets/images/gita/a1/A1S3.jpg" />
                <h1>श्रीमद्भगवद्गीता</h1>
                <p>प्रथम अध्याय - अर्जुनविषादयोग</p>
                <h1> पश्यैतां पाण्डुपुत्राणामाचार्य महतीं चमूम्‌ ।</h1>
                <h1>व्यूढां द्रुपदपुत्रेण तव शिष्येण धीमता ॥ (३)</h1>
                <p>भावार्थ : </p>
                <p>हे आचार्य! पाण्डु पुत्रों की इस विशाल सेना को देखिए, जिसे आपके बुद्धिमान्‌ शिष्य द्रुपद पुत्र धृष्टद्युम्न ने इतने कौशल से व्यूह के आकार में सजाया है। (३)</p>
                <p> Behold, O Revered Master, the mighty army of the sons of Påndu arrayed for battle by your talented pupil, Dhrstadyumna, son of Drupada. </p>
            </IonCol>
        </IonRow>
        <IonRow>
            <IonCol><IonImg src="/assets/images/gita/a1/A1S4.jpg" />
                <h1>श्रीमद्भगवद्गीता</h1>
                <p>प्रथम अध्याय - अर्जुनविषादयोग</p>
                <h1> अत्र शूरा महेष्वासा भीमार्जुनसमा युधि ।</h1>
                <h1>युयुधानो विराटश्च द्रुपदश्च महारथः ॥ (४)</h1>
                <p>भावार्थ : </p>
                <p>इस युद्ध में भीम तथा अर्जुन के समान अनेकों महान शूरवीर और धनुर्धर है, युयुधान, विराट और द्रुपद जैसे भी महान योद्धा है। (४)</p>
                <p>There are in this army, heroes wielding mighty bows and equal in military prowess to Bhisma and Arjuna-Satyaki and Viråta and the Mahårathi Drupada.</p>
            </IonCol>
        </IonRow>
        <IonRow><IonCol>
          <Adsense
            client="ca-pub-4029214824221330"
            slot="2310889447"
            style={{ display: "block" }}
            format="auto"
            responsive="true"
          />
          </IonCol></IonRow>

        <IonRow>
            <IonCol><IonImg src="/assets/images/gita/a1/A1S5.jpg" />
            <h1>श्रीमद्भगवद्गीता</h1>
            <p>प्रथम अध्याय - अर्जुनविषादयोग</p>
            <h1>धृष्टकेतुश्चेकितानः काशिराजश्च वीर्यवान्‌ ।</h1>
            <h1>पुरुजित्कुन्तिभोजश्च शैब्यश्च नरपुङवः ॥ (५)</h1>
            <p>भावार्थ :</p> 
            <p>धृष्टकेतु, चेकितान तथा काशीराज जैसे महान शक्तिशाली और पुरुजित्, कुन्तीभोज तथा शैब्य जैसे मनुष्यों मे श्रेष्ठ योद्धा भी है। (५)</p>
            <p>Dhrstaketu, Cekitåna and the valiant King of Kåsi, and Purujit, Kuntibhoja, and saibya, the best of men, and mighty.</p>
            </IonCol>
        </IonRow>
        
        <IonRow>
            <IonCol><IonImg src="/assets/images/gita/a1/A1S6.jpg" />
            <h1>श्रीमद्भगवद्गीता</h1>
            <p>प्रथम अध्याय - अर्जुनविषादयोग</p>
            </IonCol>
        </IonRow>
        <IonRow>
            <IonCol><IonImg src="/assets/images/gita/a1/A1S7.jpg" />
            <h1>श्रीमद्भगवद्गीता</h1>
            <p>प्रथम अध्याय - अर्जुनविषादयोग</p>
            </IonCol>
        </IonRow>
        <IonRow>
            <IonCol><IonImg src="/assets/images/gita/a1/A1S8.jpg" />
            <h1>श्रीमद्भगवद्गीता</h1>
            <p>प्रथम अध्याय - अर्जुनविषादयोग</p>
            </IonCol>
        </IonRow>
        <IonRow>
            <IonCol><IonImg src="/assets/images/gita/a1/A1S9.jpg" />
            <h1>श्रीमद्भगवद्गीता</h1>
            <p>प्रथम अध्याय - अर्जुनविषादयोग</p>
            </IonCol>
        </IonRow>
        <IonRow>
            <IonCol><IonImg src="/assets/images/gita/a1/A1S10.jpg" />
            <h1>श्रीमद्भगवद्गीता</h1>
            <p>प्रथम अध्याय - अर्जुनविषादयोग</p>
            </IonCol>
        </IonRow>
        <IonRow>
            <IonCol><IonImg src="/assets/images/gita/a1/A1S11.jpg" />
            <h1>श्रीमद्भगवद्गीता</h1>
            <p>प्रथम अध्याय - अर्जुनविषादयोग</p>
            </IonCol></IonRow>
        
              
        <IonRow><IonCol><IonButton fill="clear" routerLink="/bhagwat">Back To Index Page</IonButton></IonCol><IonCol>दूसरा अध्याय </IonCol></IonRow>

            
        </IonGrid>
        </div>
                </div>
  );
};

export default Bhagwatgeeta;