import {IonRow,IonCol, IonGrid, IonButton, IonText, IonImg, IonIcon, IonListHeader  } from '@ionic/react';
import {Adsense} from '@ctrl/react-adsense';


import './Ramnavami.css';


const Ramnavami: React.FC = () => {
  return (
    <div className="ion-padding">
        <h1>Ram Navami</h1>
        <p>Ramnavami is a Hindu festival that celebrates the birth of Lord Rama, the seventh avatar of Lord Vishnu. It is celebrated on the ninth day of the Hindu month of Chaitra, which usually falls in the months of March or April. The festival is celebrated with great devotion and enthusiasm, and is considered to be one of the most important festivals in Hindu culture.</p>
        <p>The festival of Ramnavami is celebrated to honor the birth of Lord Rama, who is considered to be an embodiment of righteousness, courage, and wisdom. Lord Rama is also known as Maryada Purushottama, which means the perfect man or the best of the civilized. He is revered for his adherence to dharma, even in difficult circumstances and his compassion for all living beings.</p>
        <p>During the festival, people observe fasts, perform puja, and other rituals. Temples dedicated to Lord Rama are decorated with flowers and lights, and special puja is performed to invoke his blessings. Devotees also read the Ramayana, the Hindu epic which tells the story of Lord Rama.</p>
        <p>In many parts of India, the festival of Ramnavami is celebrated with grand processions, where devotees carry the idols of Lord Rama and Sita in a chariot through the streets. The processions are accompanied by music and devotional songs, and people join in to sing and dance in the streets.</p>
        <p>Ramnavami is not only a religious but also a cultural festival, it is celebrated with great devotion and enthusiasm by people from all walks of life. It is a way to connect with tradition, culture, and spirituality and to seek blessings from Lord Rama for peace, prosperity, and happiness.</p>
        <IonRow>
        <Adsense
                client="ca-pub-4029214824221330"
                slot="2310889447"
                style={{ display: "block" }}
                format="auto"
                responsive="true"
              />
        </IonRow>

    </div>
  );
};

export default Ramnavami;

