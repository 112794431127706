import React from 'react';
import { IonImg, IonThumbnail, IonGrid, IonRow, IonCol, IonButton} from '@ionic/react';
import {Adsense} from '@ctrl/react-adsense';
import './Ramcharit.css';
    
const Ramcharit: React.FC = () => {
  return (
      <div className="ion-padding">
        
        <div className="ion-text-center">
        <h1>श्री रामचरितमानस अर्थ सहित सम्पूर्ण</h1>
        <IonGrid fixed={true}>
        <IonRow><IonCol><IonThumbnail><img alt="Logo" src="/assets/images/gita/a1/A1.jpg" /></IonThumbnail></IonCol><IonCol>प्रथम सोपान - बालकाण्ड</IonCol><IonCol><IonButton color="dark" fill="clear" routerLink="/ramcharitbalkand">Read Now</IonButton></IonCol></IonRow>
        <IonRow><IonCol><IonThumbnail><img alt="Logo" src="/assets/images/gita/a2/A2.jpg" /></IonThumbnail></IonCol><IonCol>दूसरा सोपान -  अयोध्याकाण्ड</IonCol><IonCol><IonButton color="dark" fill="clear" routerLink="/bhagwatgeeta2">Read Now</IonButton></IonCol></IonRow>
        <IonRow><IonCol><IonThumbnail><img alt="Logo" src="/assets/images/gita/a3/A3.jpg" /></IonThumbnail></IonCol><IonCol>तीसरा सोपान - अरण्यकाण्ड</IonCol><IonCol><IonButton color="dark" fill="clear" routerLink="/bhagwatgeeta2">Read Now</IonButton></IonCol></IonRow>
        <IonRow><IonCol><IonThumbnail><img alt="Logo" src="/assets/images/gita/a4/A4.jpg" /></IonThumbnail></IonCol><IonCol>चौधा सोपान - किष्किन्धाकाण्ड</IonCol><IonCol>Read Now</IonCol></IonRow>
        <IonRow><IonCol><IonThumbnail><img alt="Logo" src="/assets/images/gita/a5/A5.jpg" /></IonThumbnail></IonCol><IonCol>पाँचवा सोपान - सुन्दरकाण्ड</IonCol><IonCol>Read Now</IonCol></IonRow>
        <IonRow><IonCol><IonThumbnail><img alt="Logo" src="/assets/images/gita/a6/A6.jpg" /></IonThumbnail></IonCol><IonCol>छठा सोपान - आत्मसंयम योग</IonCol><IonCol>Read Now</IonCol></IonRow>
        <IonRow><IonCol><IonThumbnail><img alt="Logo" src="/assets/images/gita/a7/A7.jpg" /></IonThumbnail></IonCol><IonCol>सातवाँ सोपान - उत्तरकाण्ड</IonCol><IonCol>Read Now</IonCol></IonRow>
        <IonRow> 

            <IonCol>
              <IonImg src="https://www.hinduamerican.org/wp-content/uploads/2020/06/20120510012-2048x1365.jpg" alt="Shubhasitani"></IonImg>
            </IonCol>
          </IonRow>
          <IonRow>
        <Adsense
                client="ca-pub-4029214824221330"
                slot="2310889447"
                style={{ display: "block" }}
                format="auto"
                responsive="true"
              />
        </IonRow>

        </IonGrid>
        </div>
      </div>
  );
};

export default Ramcharit;