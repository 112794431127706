const config = {
    firebase: {
        apiKey: "AIzaSyCsvhjtR7QEe8AL4SpyD1vkHBYQ9w4ZfpY",
        authDomain: "sanatan-puja.firebaseapp.com",
        databaseURL: "https://sanatan-puja-default-rtdb.asia-southeast1.firebasedatabase.app",
        projectId: "sanatan-puja",
        storageBucket: "sanatan-puja.appspot.com",
        messagingSenderId: "564930247586",
        appId: "1:564930247586:web:c75e4bb3d16eba371c10fb",
        measurementId: "G-S6WEXWSL9N"
    }
}

export default config;