import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { useEffect } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import {IonRow,IonCol, IonGrid} from '@ionic/react';


import './Career.css';


const Career: React.FC = () => {
 // const user = auth.currentUser;
  
 useEffect(() => {

  let tutorialsRef = firebase.firestore().collection("/contactUs");
  tutorialsRef.get().then(function(snapshot: any) {
    let tutorials: any = [];
  
    snapshot.forEach(function(childSnapshot: any) {
      let id = childSnapshot.id;
      let data = childSnapshot.data();
      // ...
  
      tutorials.push({ id: id, name: data.name, email: data.email});
    });
    console.log('contact us', tutorials);
  });
}, [])


  return (
      <div className="ion-padding">
        <IonGrid fixed={true}>
<IonRow><IonCol>
        <Carousel
          showThumbs={false}
          autoPlay={true}
          interval={1500}
          infiniteLoop
        >
          <div>
              <img src="/assets/images/ramcharit/balkand/balkand_s1.jpg" />
              {/* <p className="legend">Legend 1</p> */}
          </div>
          <div>
              <img src="/assets/images/ramcharit/balkand/balkand_s2.jpg" />
              {/* <p className="legend">Legend 2</p> */}
          </div>
          <div>
              <img src="/assets/images/ramcharit/balkand/balkand_s3.jpg" />
              {/* <p className="legend">Legend 3</p> */}
          </div>
          <div>
              <img src="/assets/images/ramcharit/balkand/balkand_s4.jpg" />
              {/* <p className="legend">Legend 3</p> */}
          </div>
          <div>
              <img src="/assets/images/ramcharit/balkand/balkand_s5.jpg" />
              {/* <p className="legend">Legend 3</p> */}
          </div>
          <div>
              <img src="/assets/images/ramcharit/balkand/balkand_s6.jpg" />
              {/* <p className="legend">Legend 3</p> */}
          </div>
          <div>
              <img src="/assets/images/ramcharit/balkand/balkand_s7.jpg" />
              {/* <p className="legend">Legend 3</p> */}
          </div>
      </Carousel>
      </IonCol></IonRow>
      
      <div className="ion-text-justify">     
<h4>Sanatanpuja.in is a platform dedicated to preserving and promoting the rich heritage of Hinduism. Our mission is to bring people closer to their roots and provide them with a spiritual experience like never before. We are a fast-growing company, and we're always on the lookout for talented and passionate individuals who can help us achieve our goals.</h4>
<p><b> Here are some of the career opportunities currently available at Sanatanpuja.in:</b></p>
<p><b>1. Content Developer:</b> We're looking for writers who have a deep understanding of Hinduism and its various aspects, including mythology, rituals, and customs. As a content developer, you will be responsible for creating and curating high-quality content that appeals to our audience and adds value to their spiritual journey.</p>
<p><b>2. Marketing Manager:</b> We're looking for a marketing professional who can help us reach a wider audience and promote our brand. As a marketing manager, you will be responsible for creating and executing marketing campaigns that drive traffic, engagement, and conversions.</p>
<p><b>3. Web Developer:</b> We're looking for a web developer who can help us improve the user experience and functionality of our website. As a web developer, you will be responsible for maintaining and updating our website, as well as implementing new features and fixing any bugs.</p>
<p><b>4. Customer Support Representative:</b> We're looking for customer support representatives who can provide our customers with a high-quality experience and help resolve any issues they may encounter. As a customer support representative, you will be responsible for answering customer inquiries, resolving any technical issues, and ensuring that our customers are satisfied with their experience on Sanatanpuja.in.</p>
If you are interested in joining the team at Sanatanpuja.in, please send your resume and a cover letter to careers@sanatanpuja.in. We look forward to hearing from you!
        <h1>Let’s build the nation religious together</h1>
        <h2> Come, join us and together we can transform the way India worships.?</h2>
        </div>
        </IonGrid>
                </div>
  );
};

export default Career;

