import React from 'react';
import { IonImg, IonGrid, IonRow, IonCol, IonButton } from '@ionic/react';
import {Adsense} from '@ctrl/react-adsense';
import './Bhagwatgeeta3.css';
    
const Bhagwatgeeta3: React.FC = () => {
  return (
      <div className="ion-padding">
        <div className="ion-text-center">
        <h1>संपूर्ण श्रीमद्भगवद्गीता</h1>
        <IonGrid fixed={true}>
        <IonRow><IonCol><h1>तीसरा अध्याय - कर्म योग  </h1></IonCol></IonRow>
        
        <IonRow>
            <IonCol><IonButton fill="clear" routerLink="/bhagwatgeeta">दूसरा अध्याय</IonButton></IonCol>
            <IonCol><IonButton fill="clear" routerLink="/bhagwat">Index Page</IonButton></IonCol>
            <IonCol> <IonButton fill="clear" routerLink="/bhagwatgeeta3">चौथा अध्याय</IonButton></IonCol>
        </IonRow>

        <IonRow>
            <IonCol>
                <IonImg src="/assets/images/gita/a3/A3.jpg" />
            </IonCol>
        </IonRow>
        <IonRow>
            <IonCol>
                <IonImg src="/assets/images/gita/a3/A3S1.jpg" />
                <h1>श्रीमद्भगवद्गीता</h1>
                <p>तीसरा अध्याय - कर्म योग</p>
            </IonCol>
        </IonRow>
        <IonRow>
            <IonCol><IonImg src="/assets/images/gita/a3/A3S2.jpg" />
                <h1>श्रीमद्भगवद्गीता</h1>
                <p>तीसरा अध्याय - कर्म योग</p>
            </IonCol>
        </IonRow>
        <IonRow>
            <IonCol><IonImg src="/assets/images/gita/a3/A3S3.jpg" />
                <h1>श्रीमद्भगवद्गीता</h1>
                <p>तीसरा अध्याय - कर्म योग</p>
            </IonCol>
        </IonRow>
        <IonRow>
            <IonCol><IonImg src="/assets/images/gita/a3/A3S4.jpg" />
                <h1>श्रीमद्भगवद्गीता</h1>
                <p>तीसरा अध्याय - कर्म योग</p>
            </IonCol>
        </IonRow>
        <IonRow><IonCol>
          <Adsense
            client="ca-pub-4029214824221330"
            slot="2310889447"
            style={{ display: "block" }}
            format="auto"
            responsive="true"
          />
          </IonCol></IonRow>

        <IonRow>
            <IonCol><IonImg src="/assets/images/gita/a3/A3S5.jpg" />
            <h1>श्रीमद्भगवद्गीता</h1>
            <p>तीसरा अध्याय - कर्म योग</p>
            </IonCol>
        </IonRow>
        
        <IonRow>
            <IonCol><IonImg src="/assets/images/gita/a3/A3S6.jpg" />
            <h1>श्रीमद्भगवद्गीता</h1>
            <p>तीसरा अध्याय - कर्म योग</p>
            </IonCol>
        </IonRow>
        <IonRow>
            <IonCol><IonImg src="/assets/images/gita/a3/A3S7.jpg" />
            <h1>श्रीमद्भगवद्गीता</h1>
            <p>तीसरा अध्याय - कर्म योग</p>
            </IonCol>
        </IonRow>
        <IonRow><IonCol>
          <Adsense
            client="ca-pub-4029214824221330"
            slot="2310889447"
            style={{ display: "block" }}
            format="auto"
            responsive="true"
          />
          </IonCol></IonRow>

        <IonRow>
            <IonCol><IonImg src="/assets/images/gita/a3/A3S8.jpg" />
            <h1>श्रीमद्भगवद्गीता</h1>
            <p>तीसरा अध्याय - कर्म योग</p>
            </IonCol>
        </IonRow>
                
              
        <IonRow><IonCol><IonButton fill="clear" routerLink="/bhagwat">Back To Index Page</IonButton></IonCol><IonCol>चौथा अध्याय </IonCol></IonRow>

            
        </IonGrid>
        </div>
                </div>
  );
};

export default Bhagwatgeeta3;