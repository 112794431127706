import {IonRow,IonCol, IonGrid, IonButton, IonText, IonImg, IonListHeader  } from '@ionic/react';
import {Adsense} from '@ctrl/react-adsense';

import './Vastu.css';


const Vastu: React.FC = () => {
  return (
    <div className="ion-padding">
        <div className="ion-text-center">
        <IonGrid fixed={true}>
        <IonRow><IonCol><IonImg src="/assets/images/vastu.png" /></IonCol></IonRow>
<h3>घर बनाते समय जाने-अनजाने ऐसी गलती हो जाती है जिससे घर में वास्तु दोष उत्पन्न हो जाते हैं। अगर किसी वास्तु शास्त्री की सलाह से घर का निर्माण किया जाए तो घर वास्तु दोषों से मुक्त हो सकता है, लेकिन कई बार घर की साज-सज्जा और घर में रखी चीजों के कारण भी वास्तु दोष उत्पन्न हो जाते हैं।
वास्तु दोष का प्रभाव हमेशा बना रहता है, लेकिन जब वास्तु दोष जिस दिशा में हो उस दिशा से संबंधित ग्रह की दशा हो तो उस घर में रहने वाले व्यक्ति को उसका बुरा प्रभाव भुगतना पड़ता है।
वास्तुदोष निवारण के लिए कई उपाय हैं जिनमें कई उपकरण, तंत्र और मंत्र शामिल हैं। यहां हम बता रहे हैं कि कैसे आप मंत्रों द्वारा वास्तु दोष से मुक्ति पा सकते हैं।</h3>
<h4>Consciously or unconsciously such a mistake is made when building a house, because of which the Vastu defects arise in the house. If the house is constructed with the advice of a Vastu Shastri, then the house can be free from Vastu defects, but sometimes Vastu defects arise due to the decoration of the house and the things kept in the house.
The effect of Vaastu Dosh always remains, but when the dasha of the planet related to the direction in which Vaastu Dosh is there, then the person living in that house has to suffer its bad effects.
There are many remedies for the elimination of Vastudosh which include many tools, tantras and mantras. Here we are telling how you can get rid of Vastu Dosh by mantras.</h4>
<IonListHeader lines="full"></IonListHeader>

<h2>ईशान दिशा मंत्र</h2>
<IonText color="tertiary"><h3>"ओम बृं बृहस्पतये नमः"</h3></IonText>
<h4>र्ईसान दिशा के स्वामी बृहस्पति हैं। और देवता हैं भगवान शिव। इस दिशा के अशुभ प्रभाव को दूर करने के लिए नियमित गुरू मंत्र 'ओम बृं बृहस्पतये नमः' मंत्र का जप करें।</h4>
<h3>शिव पंचाक्षरी मंत्र ओम नमः शिवाय का 108 बार जप करना भी लाभप्रद होता है।</h3>
<h4>The lord of Christian direction is Jupiter. And the deity is Lord Shiva. To remove the inauspicious effect of this direction, regularly chant the Guru Mantra 'Om Brihaspataye Namah'.</h4>
<IonListHeader lines="full"></IonListHeader>
<h2>पूर्व दिशा मंत्र</h2>
<IonText color="tertiary"><h3>ओम ह्रां ह्रीं ह्रौं सः सूर्याय नमः</h3></IonText>
<h3>घर का पूर्व दिशा वास्तु दोष से पीड़ित है तो इसे दोष मुक्त करने के लिए प्रतिदिन सूर्य मंत्र 'ओम ह्रां ह्रीं ह्रौं सः सूर्याय नमः' का जप करें। सूर्य इस दिशा के स्वामी हैं।</h3>
<h3>इस मंत्र के जप से सूर्य के शुभ प्रभावों में वृद्घि होती है। व्यक्ति मान-सम्मान एवं यश प्राप्त करता है। इन्द्र पूर्व दिशा के देवता हैं। प्रतिदिन 108 बार इंद्र मंत्र 'ओम इन्द्राय नमः' का जप करना भी इस दिशा के दोष को दूर कर देता है।</h3>
<IonListHeader lines="full"></IonListHeader>
<IonText color="tertiary"><h2>आग्नेय दिशा मंत्र</h2></IonText>
<IonText color="tertiary"><h3>ओम शुं शुक्राय नमः</h3></IonText>
<h3>आग्नेय दिशा के स्वामी ग्रह शुक्र और देवता अग्नि हैं। इस दिशा में वास्तु दोष होने पर शुक्र अथवा अग्नि के मंत्र का जप लाभप्रद होता है। शुक्र का मंत्र है 'ओम शुं शुक्राय नमः'।</h3>
<h3>अग्नि का मंत्र है 'ओम अग्नेय नमः'। इस दिशा को दोष से मुक्त रखने के लिए इस दिशा में पानी का टैंक, नल, शौचालय अथवा अध्ययन कक्ष नहीं होना चाहिए।</h3>
<IonListHeader lines="full"></IonListHeader>
<h2>दक्षिण दिशा मंत्र</h2>
<IonText color="tertiary"><h2>ओम अं अंगारकाय नमः</h2></IonText>
<h3>दक्षिण दिशा के स्वामी ग्रह मंगल और देवता यम हैं। दक्षिण दिशा से वास्तु दोष दूर करने के लिए नियमित 'ओम अं अंगारकाय नमः' मंत्र का 108 बार जप करना चाहिए।</h3>
<h3>यह मंत्र मंगल के कुप्रभाव को भी दूर कर देता है। 'ओम यमाय नमः' मंत्र से भी इस दिशा का दोष समाप्त हो जाता है।</h3>
<IonListHeader lines="full"></IonListHeader>
<IonRow>
        <Adsense
                client="ca-pub-4029214824221330"
                slot="2310889447"
                style={{ display: "block" }}
                format="auto"
                responsive="true"
              />
        </IonRow>
<h2>नैऋत्य दिशा मंत्र</h2>
<IonText color="tertiary"><h2>ओम रां राहवे नमः</h2></IonText>
<h3>नैऋत्य दिशा के स्वामी राहु ग्रह हैं। घर में यह दिशा दोषपूर्ण हो और कुण्डली में राहु अशुभ बैठा हो तो राहु की दशा व्यक्ति के लिए काफी कष्टकारी हो जाती है।</h3>
<h3>इस दोष को दूर करने के लिए राहु मंत्र 'ओम रां राहवे नमः' मंत्र का जप करें। इससे वास्तु दोष एवं राहु का उपचार भी उपचार हो जाता है।</h3>
<IonListHeader lines="full"></IonListHeader>
<h2>पश्चिम दिशा मंत्र</h2>
<IonText color="tertiary"><h2>ओम शं शनैश्चराय नमः</h2></IonText>
<h3>पश्चिम दिशा के स्वामी ग्रह शनि हैं। जबकि इस दिशा के देवता वरूण देव हैं। इस दिशा में किचन कभी भी नहीं बनाना चाहिए।</h3>
<h3>इस दिशा में वास्तु दोष होने पर शनि मंत्र 'ओम शं शनैश्चराय नमः' का नियमित जप करें। यह मंत्र शनि के कुप्रभाव को भी दूर कर देता है।</h3>
<IonListHeader lines="full"></IonListHeader>
<h2>वायव्य दिशा मंत्र</h2>
<IonText color="tertiary"><h2>ओम चन्द्रमसे नमः</h2></IonText>
<h3>वायव्य दिशा के ग्रह स्वामी चन्द्रमा हैं। यह दिशा दोषपूर्ण होने पर मन चंचल रहता है।</h3>
<h3>घर में रहने वाले लोग सर्दी जुकाम एवं छाती से संबंधित रोग से परेशान होते हैं। इस दिशा के दोष को दूर करने के लिए चन्द्र मंत्र 'ओम चन्द्रमसे नमः' का जप लाभकारी होता है।</h3>
<IonListHeader lines="full"></IonListHeader>
<h2>उत्तर दिशा मंत्र</h2>
<IonText color="tertiary"><h2>ओम बुधाय नमः या 'ओम कुबेराय नमः</h2></IonText>
<h3>उत्तर दिशा के देवता धन के स्वामी कुबेर हैं। यह दिशा बुध ग्रह के प्रभाव में आता है। इस दिशा के दूषित होने पर माता एवं घर में रहने वाले स्त्रियों को कष्ट होता है।</h3>
<h3>आर्थिक कठिनाईयों का भी सामना करना होता है। इस दिशा को वास्तु दोष से मुक्त करने के लिए 'ओम बुधाय नमः या 'ओम कुबेराय नमः' मंत्र का जप करें। आर्थिक समस्याओं में कुबेर मंत्र का जप अधिक लाभकारी होता है।</h3>
<IonListHeader lines="full"></IonListHeader>
<h3>वास्तु दोष से मुक्ति और धन लाभ भी</h3>
<h3>धन समृद्घि प्रदान करने वाले वास्तु के 8 मंत्र</h3>
<IonListHeader lines="full"></IonListHeader>

<h3>दशा आरंभ होने पर शुक्ल पक्ष में ग्रह संबधी जो भी पहला वार आये, उस वार से मंत्र जाप आरंभ करना चाहिए. राहु/केतु का कोई दिन नहीं होता है तो राहु के मंत्र जाप शनिवार से आरंभ करने चाहिए और केतु के जाप मंगलवार से आरंभ करने चाहिए.
मंत्रानुष्ठान के लिए शास्त्र के विधान का पालन करना परम आवश्यक है, अन्यथा लाभ के बदले हानि की संभावना अधिक रहती है।इसलिए यह आवश्यक है कि प्रत्येक कार्य शास्त्रसम्मत ही किया जाए। इस हेतु किसी योग्य और विद्वान व्यक्ति का मार्गदर्शन लेना चाहिए।
यदि साधक पूर्ण श्रद्धा और विश्वास के साथ यह साधना करें, तो वांछित फल की प्राप्ति की प्रबल संभावना रहती है।
</h3>
<h4>At the beginning of Dasha, the first attack related to a planet in Shukla Paksha should start chanting mantra from that attack. If there is no day for Rahu/Ketu, then chanting of Rahu's mantra should be started from Saturday and Ketu's chanting should be started from Tuesday. It is absolutely necessary to follow the law of the scriptures for mantra ritual, otherwise there is more possibility of loss instead of profit. That's why it is necessary that every work should be done according to the scriptures. For this, the guidance of a qualified and learned person should be taken. If the seeker does this practice with full devotion and faith, then there is a strong possibility of getting the desired result.</h4>        
        <IonText color="success">
        <h3><i><b>We are providing this service at very nominal Dakshina.</b></i></h3>
        </IonText>
        
        <IonRow><IonCol><IonButton routerLink="/contact">Contact Us for More Details</IonButton></IonCol></IonRow>
        </IonGrid>

        </div>
        
    </div>
  );
};

export default Vastu;

