import {IonRow,IonCol, IonGrid, IonButton, IonText, IonImg, IonIcon, IonListHeader  } from '@ionic/react';
import {Adsense} from '@ctrl/react-adsense';


import './Shadi.css';


const Shadi: React.FC = () => {
  return (
    <div className="ion-padding">
        <h1>Shadi</h1>
        <p>In Sanatan Dharma (Hinduism), the institution of marriage is considered to be one of the most sacred and important aspects of life. The wedding ceremony, known as Shadi, is a traditional ritual that marks the union of two individuals and is considered to be a sacrament rather than a contract.</p>
        <p>One of the main reasons for the importance of Shadi in Sanatan Dharma is that it is considered to be a sacrament that unites two individuals not only physically but also spiritually. It is believed that the marriage creates a bond between the couple that is not only for this lifetime but for all lifetimes to come. The couple is believed to be united not only in this life but in all future lives, as well.</p>
        <p>Shadi is also considered to be an important aspect of social and familial responsibility. In Hindu culture, the family is considered to be the most important unit of society and marriage is seen as a way to continue the family line. The couple is expected to have children and to raise them in the traditions of their faith and culture.</p>
        <p>Additionally, the traditional Hindu wedding ceremony is filled with symbolic rituals that are believed to bring blessings and good luck to the couple. The rituals performed during the ceremony are meant to invoke the blessings of the gods and goddesses and to ensure that the couple has a happy and prosperous life together.</p>
        <p>In Sanatan Dharma, marriage is also considered as a way for an individual to fulfill their duty towards their family, society, and the divine. It's a way to fulfill one's dharma, which means fulfilling the duty, and to attain moksha, which means liberation from the cycle of rebirths.</p>
        <p>In conclusion, Shadi is considered to be an important and sacred aspect of Sanatan Dharma. It is a sacrament that unites two individuals not only physically but also spiritually and it is considered to be a way to fulfill one's duty towards their family, society, and the divine. It is a traditional ritual that is filled with symbolic rituals and is believed to bring blessings and good luck to the couple.</p>
        <IonRow>
        <Adsense
                client="ca-pub-4029214824221330"
                slot="2310889447"
                style={{ display: "block" }}
                format="auto"
                responsive="true"
              />
        </IonRow>

        <h2>Shadi is a traditional wedding ceremony</h2>
        <p>Shadi, also spelled as Sadi or Shaadi, is a Hindu, Muslim, and Sikh traditional wedding ceremony that marks the union of two people in marriage. It is a sacred and joyous occasion that is celebrated with great enthusiasm and devotion by the couple and their families.</p>
        <p>The ceremony begins with pre-wedding rituals, such as Haldi and Mehendi, where the bride and groom are given turmeric and henna paste respectively, to be applied on their body. The rituals are considered auspicious, and it is believed that they bring good luck and prosperity to the couple.</p>
        <p>The main wedding ceremony usually takes place in a mandap, a traditional Hindu wedding canopy, or in a mosque or gurudwara for Muslim and Sikh wedding respectively. The ceremony is conducted by a pandit, a priest, or a qazi, an Islamic judge, or a granthi, a Sikh priest, who performs the rituals and chants prayers to invoke the blessings of the gods and goddesses.</p>
        <p>During the ceremony, the bride and groom exchange vows and rings, and they take seven vows, known as saat phere, in front of the fire, which is considered as a witness to their union. The bride and groom then take blessings from their parents and the elder members of the family.</p>
        <p>The wedding ceremony concludes with a grand feast, known as the reception, where the couple is presented with gifts and blessings from their guests. The wedding is celebrated with music, dance, and traditional rituals, and it is considered to be one of the most important events in a person's life.</p>
        <p>Shadi is a traditional wedding ceremony that is celebrated with great enthusiasm and devotion by people from Hindu, Muslim, and Sikh communities. It is a sacred and joyous occasion that marks the union of two people in marriage and it is a way to connect with tradition, culture, and spirituality. The wedding ceremony is not only a celebration of love but also an opportunity to come together with family and friends to celebrate the beginning of a new chapter in the couple's life.</p>
    </div>
  );
};

export default Shadi;

