import {IonRow,IonCol, IonGrid, IonButton, IonText, IonImg, IonIcon, IonListHeader  } from '@ionic/react';
import './Mahasarasawatijaap.css';
import {Adsense} from '@ctrl/react-adsense';


const Mahasarasawatijaap: React.FC = () => {
  return (
    <div className="ion-padding">
        <div className="ion-text-center">
        <IonGrid fixed={true}>
        <IonRow><IonCol><IonImg src="/assets/images/saraswati.png" /></IonCol></IonRow>
        <h1><b>|| मां सरस्वती का संपूर्ण मंत्र||</b></h1>
        <IonText color="tertiary">
        <h1 ><b>"ॐ ऐं ह्रीं क्लीं महासरस्वती देव्यै नमः। "</b></h1>
        </IonText>
        <h1><b>||सरस्वती देवी का मूल मंत्र||</b></h1>
        <IonText color="tertiary">
        <h1 ><b>"ॐ ऐं सरस्वत्यै ऐं नमः। "</b></h1>
        </IonText>
        <h1><b>||देवी सरस्वती के मंत्र:||</b></h1>
        <IonText color="danger">
        <h1 ><b>ॐ श्री सरस्वती शुक्लवर्णां सस्मितां सुमनोहराम्।।
            <p>कोटिचंद्रप्रभामुष्टपुष्टश्रीयुक्तविग्रहाम्।</p>
            <p>वह्निशुद्धां शुकाधानां वीणापुस्तकमधारिणीम्।।</p>
            <p>रत्नसारेन्द्रनिर्माणनवभूषणभूषिताम्।</p>
            <p>सुपूजितां सुरगणैब्रह्मविष्णुशिवादिभि:।।</p>
            <p>वन्दे भक्तया वन्दिता च।</p></b></h1>
        </IonText>
        <div className="ion-text-justify">
        <IonListHeader lines="full"></IonListHeader>
        <h4><b>मां सरस्वती को ज्ञान की देवी कहा जाता है। इनकी पूजा करने से व्यक्ति के मन मस्तिष्क में बुद्धि का संचार होता है। उनका कहना है कि यहीं पर मां सरस्वती का वास है। मां लक्ष्मी अवश्य ही यहां की वासी हैं। दिव्य काल में सबसे पहले भगवान श्रीकृष्ण ने मां शारदे की पूजा की थी। इन्हें संगीत की देवी भी कहा जाता है। हिंदी कैलेंडर के अनुसार, माघ महीने में वसंत पंचमी के दिन सरस्वती पूजा मनाई जाती है। इस दिन मां शारदे का आह्वान किया जाता है। खासकर विद्यार्थी सरस्वती पूजा को एक उत्सव की तरह मनाते हैं। बंगाल और बिहार समेत देश के कई राज्यों में सरस्वती पूजा धूमधाम से मनाई जाती है। विद्यार्थियों को विद्या प्राप्ति के लिए प्रतिदिन इन मंत्रों का जाप करना चाहिए। आइए जानते हैं- </b></h4>
        <h4><b>Maa Saraswati is referred to as the goddess of apprenticeship. Worshipping them leads to transmitting intelligence into a person's mind and brain. They say this is where Mother Saraswati is staying. Mother Lakshmi is certainly a resident of this place. In the divine period, first and foremost, Lord Krishna worshipped Maa Sharde. She is also referred to as the goddess of music. In accordance with the Hindi calendar, Saraswati Puja is celebrated on the day of Vasant Panchami in the month of Magha. Mother Sharde is invoked on this day. Especially students celebrate Saraswati Puja like a festival. Saraswati Puja is celebrated with splendour in several states of the country, including Bengal and Bihar. Students should sing these mantras every day to get an education. Let's know-</b></h4>
        </div>
        <IonRow>
        <Adsense
                client="ca-pub-4029214824221330"
                slot="2310889447"
                style={{ display: "block" }}
                format="auto"
                responsive="true"
              />
        </IonRow>
        
        <IonText color="danger">
        <h1><b> "नमस्ते सर्वगेवानां वरदासि हरे: प्रिया।"</b></h1>
        <h1><b>या गतिस्त्वत्प्रपन्नानां या सा मे भूयात्वदर्चनात्।।</b></h1>
        </IonText>
        <IonListHeader lines="full"></IonListHeader>
        
        <h3><b> माता सरस्वती के नाम मंत्र</b></h3>
        <h4><IonText color="danger">
        <p><b>1. सरस्वती : ॐ सरस्वत्यै नमः।</b></p>
<p><b>2. महाभद्रा : ॐ महाभद्रायै नमः।</b></p>
<p><b>3. महामाया : ॐ महमायायै नमः।</b></p>
<p><b>4. वरप्रदा : ॐ वरप्रदायै नमः।</b></p>
<p><b>5. श्रीप्रदा : ॐ श्रीप्रदायै नमः।</b></p>
<p><b>6. पद्मनिलया : ॐ पद्मनिलयायै नमः।</b></p>
<p><b>7. पद्माक्षी : ॐ पद्मा क्ष्रैय नमः।</b></p>
<p><b>8. पद्मवक्त्रगा : ॐ पद्मवक्त्रायै नमः।</b></p>
<p><b>9. शिवानुजा : ॐ शिवानुजायै नमः।</b></p>
<p><b>10. पुस्तकधृत : ॐ पुस्त कध्रते नमः।</b></p>
<p><b>11. ज्ञानमुद्रा : ॐ ज्ञानमुद्रायै नमः।</b></p>
<p><b>12. रमा : ॐ रमायै नमः।</b></p>
<p><b>13. परा : ॐ परायै नमः।</b></p>
<p><b>14. कामरूपा : ॐ कामरूपायै नमः।</b></p>
<p><b>15. महाविद्या : ॐ महाविद्यायै नमः।</b></p>
<p><b>16. महापातक : नाशिनी ॐ महापातक नाशिन्यै नमः।</b></p>
<p><b>17. महाश्रया : ॐ महाश्रयायै नमः।</b></p>
<p><b>18. मालिनी : ॐ मालिन्यै नमः।</b></p>
<p><b>19. महाभोगा : ॐ महाभोगायै नमः।</b></p>
<p><b>20. महाभुजा : ॐ महाभुजायै नमः।</b></p>
<p><b>21. महाभागा : ॐ महाभागायै नमः।</b></p>
<p><b>22. महोत्साहा : ॐ महोत्साहायै नमः।</b></p>
<p><b>23. दिव्याङ्गा : ॐ दिव्याङ्गायै नमः।</b></p>
<p><b>24. सुरवन्दिता : ॐ सुरवन्दितायै नमः।</b></p>
<p><b>25. महाकाली : ॐ महाकाल्यै नमः।</b></p>
<p><b>26. महापाशा : ॐ महापाशायै नमः।</b></p>
<p><b>27. महाकारा : ॐ महाकारायै नमः।</b></p>
<p><b>28. महाङ्कुशा : ॐ महाङ्कुशायै नमः।</b></p>
<p><b>29. सीता : ॐ सीतायै नमः।</b></p>
<p><b>30. विमला : ॐ विमलायै नमः।</b></p>
<p><b>31. विश्वा : ॐ विश्वायै नमः।</b></p>
<p><b>32. विद्युन्माला : ॐ विद्युन्मालायै नमः।</b></p>
<p><b>33. वैष्णवी : ॐ वैष्णव्यै नमः।</b></p>
<p><b>34. चन्द्रिका : ॐ चन्द्रिकायै नमः।</b></p>
<p><b>35. चन्द्रवदना : ॐ चन्द्रवदनायै नमः।</b></p>
<p><b>36. चन्द्रलेखाविभूषिता : ॐ चन्द्रलेखाविभूषितायै नमः। </b></p>
<p><b>37. सावित्री : ॐ सावित्र्यै नमः।</b></p>
<p><b>38. सुरसा : ॐ सुरसायै नमः।</b></p>
<p><b>39. देवी : ॐ देव्यै नमः।</b></p>
<p><b>40. दिव्यालङ्कारभूषिता : ॐ दिव्यालङ्कारभूषितायै नमः। </b></p>
<p><b>41. वाग्देवी : ॐ वाग्देव्यै नमः।</b></p>
<p><b>42. वसुधा : ॐ वसुधायै नमः।</b></p>
<p><b>43. तीव्रा : ॐ तीव्रायै नमः।</b></p>
<p><b>44. महाभद्रा : ॐ महाभद्रायै नमः।</b></p>
<p><b>45. महाबला : ॐ महाबलायै नमः।</b></p>
<p><b>46. भोगदा : ॐ भोगदायै नमः।</b></p>
<p><b>47. भारती : ॐ भारत्यै नमः।</b></p>
<p><b>48. भामा : ॐ भामायै नमः।</b></p>
<p><b>49. गोविन्दा : ॐ गोविन्दायै नमः।</b></p>
<p><b>50. गोमती : ॐ गोमत्यै नमः।</b></p>
<p><b>51. शिवा : ॐ शिवायै नमः।</b></p>
<p><b>52. जटिला : ॐ जटिलायै नमः।</b></p>
<p><b>53. विन्ध्यवासा : ॐ विन्ध्यावासायै नमः।</b></p>
<p><b>54. विन्ध्याचलविराजिता : ॐ विन्ध्याचलविराजितायै नमः।</b></p>
<p><b>55. चण्डिका : ॐ चण्डिकायै नमः।</b></p>
<p><b>56. वैष्णवी : ॐ वैष्णव्यै नमः।</b></p>
<p><b>57. ब्राह्मी : ॐ ब्राह्मयै नमः।</b></p>
<p><b>58. ब्रह्मज्ञानैकसाधना : ॐ ब्रह्मज्ञानैकसाधनायै नमः।</b></p>
<p><b>59. सौदामिनी : ॐ सौदामिन्यै नमः।</b></p>
<p><b>60. सुधामूर्ति : ॐ सुधामूर्त्यै नमः।</b></p>
<p><b>61. सुभद्रा : ॐ सुभद्रायै नमः।</b></p>
<p><b>62. सुरपूजिता : ॐ सुरपूजितायै नमः।</b></p>
<p><b>63. सुवासिनी : ॐ सुवासिन्यै नमः।</b></p>
<p><b>64. सुनासा : ॐ सुनासायै नमः।</b></p>
<p><b>65. विनिद्रा : ॐ विनिद्रायै नमः।</b></p>
<p><b>66. पद्मलोचना : ॐ पद्मलोचनायै नमः।</b></p>
<p><b>67. विद्यारूपा : ॐ विद्यारूपायै नमः।</b></p>
<p><b>68. विशालाक्षी : ॐ विशालाक्ष्यै नमः।</b></p>
<p><b>69. ब्रह्मजाया : ॐ ब्रह्मजायायै नमः।</b></p>
<p><b>70. महाफला : ॐ महाफलायै नमः।</b></p>
<p><b>71. त्रयीमूर्ती : ॐ त्रयीमूर्त्यै नमः।</b></p>
<p><b>72. त्रिकालज्ञा : ॐ त्रिकालज्ञायै नमः।</b></p>
<p><b>73. त्रिगुणा : ॐ त्रिगुणायै नमः।</b></p>
<p><b>74. शास्त्ररूपिणी : ॐ शास्त्ररूपिण्यै नमः।</b></p>
<p><b>75. शुम्भासुरप्रमथिनी : ॐ शुम्भासुरप्रमथिन्यै नमः।</b></p>
<p><b>76. शुभदा : ॐ शुभदायै नमः।</b></p>
<p><b>77. सर्वात्मिका : ॐ स्वरात्मिकायै नमः।</b></p>
<p><b>78. रक्तबीजनिहन्त्री : ॐ रक्तबीजनिहन्त्र्यै नमः।</b></p>
<p><b>79. चामुण्डा : ॐ चामुण्डायै नमः।</b></p>
<p><b>80. अम्बिका : ॐ अम्बिकायै नमः।</b></p>
<p><b>81. मुण्डकायप्रहरणा : ॐ मुण्डकायप्रहरणायै नमः।</b></p>
<p><b>82. धूम्रलोचनमर्दना : ॐ धूम्रलोचनमर्दनायै नमः।</b></p>
<p><b>83. सर्वदेवस्तुता : ॐ सर्वदेवस्तुतायै नमः।</b></p>
<p><b>84. सौम्या : ॐ सौम्यायै नमः।</b></p>
<p><b>85. सुरासुर नमस्कृता : ॐ सुरासुर नमस्कृतायै नमः।</b></p>
<p><b>86. कालरात्री : ॐ कालरात्र्यै नमः।</b></p>
<p><b>87. कलाधारा : ॐ कलाधारायै नमः।</b></p>
<p><b>88. रूपसौभाग्यदायिनी : ॐ रूपसौभाग्यदायिन्यै नमः।</b></p>
<p><b>89. वाग्देवी : ॐ वाग्देव्यै नमः।</b></p>
<p><b>90. वरारोहा : ॐ वरारोहायै नमः।</b></p>
<p><b>91. वाराही : ॐ वाराह्यै नमः।</b></p>
<p><b>92. वारिजासना : ॐ वारिजासनायै नमः।</b></p>
<p><b>93. चित्राम्बरा : ॐ चित्राम्बरायै नमः।</b></p>
<p><b>94. चित्रगन्धा : ॐ चित्रगन्धायै नमः।</b></p>
<p><b>95. चित्रमाल्यविभूषिता : ॐ चित्रमाल्यविभूषितायै नमः।</b></p>
<p><b>96. कान्ता : ॐ कान्तायै नमः।</b></p>
<p><b>97. कामप्रदा : ॐ कामप्रदायै नमः।</b></p>
<p><b>98. वन्द्या : ॐ वन्द्यायै नमः।</b></p>
<p><b>99. विद्याधरसुपूजिता : ॐ विद्याधरसुपूजितायै नमः।</b></p>
<p><b>100. श्वेतासना : ॐ श्वेतासनायै नमः।</b></p>
<p><b>101. नीलभुजा : ॐ नीलभुजायै नमः।</b></p>
<p><b>102. चतुर्वर्गफलप्रदा : ॐ चतुर्वर्गफलप्रदायै नमः।</b></p>
<p><b>103. चतुरानन साम्राज्या : ॐ चतुरानन साम्राज्यायै नमः।</b></p>
<p><b>104. रक्तमध्या : ॐ रक्तमध्यायै नमः।</b></p>
<p><b>105. निरञ्जना : ॐ निरञ्जनायै नमः।</b></p>
<p><b>106. हंसासना : ॐ हंसासनायै नमः।</b></p>
<p><b>107. नीलजङ्घा : ॐ नीलजङ्घायै नमः।</b></p>
<p><b>108. ब्रह्मविष्णुशिवात्मिका : ॐ ब्रह्मविष्णुशिवान्मिकायै नमः।</b></p>
</IonText>

</h4>
        <div className="ion-text-justify">
        <h5>उपर्युक्त मंत्र से मां सरस्वती की कृपा हम पर बरसेगी। मंत्र जाप से जीवन में खुशहाली, धन, वैभव और समृद्धि का आगमन होता है। इन मंत्रों के जाप से जीवन में धन की कमी पूरी होती है। इन मंत्रों को जपने से धन की कमी दूर होती है।</h5>
        <h5>The blessings of Maa Saraswati will be poured out upon us in the current mantra. Chanting mantra brings good luck, wealth, glory and prosperity in life. Chanting of these mantras fulfills the lack of money in life. Chanting these mantras removes the lack of money.</h5>
        <IonListHeader lines="full"></IonListHeader>
        <h4>हिंदी कैलेंडर के अनुसार, माघ महीने में वसंत पंचमी के दिन सरस्वती पूजा मनाई जाती है। इस दिन माँ शारदे का आह्वान किया जाता है। विशेषकर विद्यार्थी सरस्वती पूजा को उत्सव के रूप में मनाते हैं। सरस्वती पूजा पूरे देश के कई राज्यों में धूमधाम से मनाई जाती है।</h4>
        <h4>In accordance with the Hindi calendar, Saraswati Puja is celebrated on the day of Vasant Panchami in the month of Magha. Mother Sharde is summoned as of this day. Especially students celebrate Saraswati Puja as a fest. Saraswati Puja is celebrated with splendour in many states throughout the country.</h4>
        <IonListHeader lines="full"></IonListHeader>
        <h3>मंत्रानुष्ठान के लिए शास्त्र के विधान का पालन करना परम आवश्यक है, अन्यथा लाभ के बदले हानि की संभावना अधिक रहती है। इसलिए यह आवश्यक है कि प्रत्येक कार्य शास्त्रसम्मत ही किया जाए। इस हेतु किसी योग्य और विद्वान व्यक्ति का मार्गदर्शन लेना चाहिए।</h3>
        <h3>यदि साधक पूर्ण श्रद्धा और विश्वास के साथ यह साधना करें, तो वांछित फल की प्राप्ति की प्रबल संभावना रहती है।</h3>
        <h3>शास्त्रों में अलग-अलग कार्यों के लिए अलग-अलग संख्याओं में श्री लक्ष्मी महामंत्र: के जप का विधान है। इसमें किस कार्य के लिए कितनी संख्या में जप करना चाहिए इसका उल्लेख किया गया है।</h3>
        </div>
        <IonListHeader lines="full"></IonListHeader>
        <h3>All of us at <b>[Sanatan Puja]</b> would love to do Jaap of <IonText color="danger"><b> "Maha Saraswati Mantra" </b></IonText> for everyone who need it, and for you to use this as a tool that will empower you to reach your goals in life. You can contact us by clicking the link given below.</h3>
        <IonText color="success">
        <h3><i><b>We are providing this service at very nominal Dakshina.</b></i></h3>
        </IonText>
        <IonRow><IonCol><IonButton routerLink="/contact">Contact Us for More Details</IonButton></IonCol></IonRow>
        </IonGrid>
        </div>
        
    </div>
  );
};

export default Mahasarasawatijaap;

