import { IonRow, IonCol, IonButton, IonIcon, IonListHeader} from '@ionic/react';
import { logoFacebook, logoTwitter, logoInstagram, logoYoutube, paperPlaneOutline} from 'ionicons/icons';

const Footmenu: React.FC = ({ }) => {
    return (
        <div className='ion-text-center'>
            <IonListHeader lines="full"></IonListHeader>
            <b>Follow Us</b>
          <IonRow>
            <IonCol>
              <IonButton fill="clear"href="https://youtube.com/@sanatanpujain">YouTube<IonIcon icon={logoYoutube}slot="start"></IonIcon></IonButton>
              <IonButton fill="clear" href="https://facebook.com/sanatanpuja.in">FaceBook<IonIcon icon={logoFacebook}slot="start"></IonIcon></IonButton>
              <IonButton fill="clear" href="https://twitter.com/sanatanpuja">Twitter<IonIcon icon={logoTwitter}slot="start"></IonIcon></IonButton>
              <IonButton fill="clear" href="https://instagram.com/sanatanpuja.in">InstaGram<IonIcon icon={logoInstagram}slot="start"></IonIcon></IonButton>
              <IonButton fill="clear" href="https://t.me/sanatanpuja">Telegram<IonIcon icon={paperPlaneOutline}slot="start"></IonIcon></IonButton>

            </IonCol>
          </IonRow>

          <IonListHeader lines="full"></IonListHeader>
         
         <div className="ion-text-left">
         
         <IonRow>
           <IonCol><h3>Jaap Anusthan</h3>
           <IonButton fill="clear" routerLink="/mirtunjay">Maha Mirtunjay Jaap</IonButton>
           <IonButton fill="clear" routerLink="/mahalakshmijaap">Maha Lakshmi Jaap</IonButton>
           <IonButton fill="clear" routerLink="/mahasarasawatijaap">Maha Saraswati Jaap</IonButton>
           <IonButton fill="clear" routerLink="/navgrah">NavGrah Shanti Jaap</IonButton>
           <IonButton fill="clear" routerLink="/vastu">Vastu Dosh Nivaran Jaap</IonButton>
           <IonButton fill="clear" routerLink="/rudravishek">Rudrabhishek</IonButton>
           <br></br>
           <IonButton fill="clear" routerLink="/mahasarasawatijaap">Sri Saraswati Puja</IonButton>
           <br></br>
           <IonButton fill="clear" routerLink="/ekadashi">Ekadashi Puja</IonButton>
           <br></br>
           <IonButton fill="clear" routerLink="/home">Chhat Puja</IonButton>
           </IonCol>
           <IonCol>
             <h3>Puja Katha</h3>
           <IonButton fill="clear" routerLink="/ganesh"> Sri Ganesh Chaturthi Puja</IonButton>
           <IonButton fill="clear" routerLink="/satyanarayan">Sri Satya Narayan Puja</IonButton>
           <IonButton fill="clear" routerLink="/teej">Hartalika Teej Puja</IonButton>
           <IonButton fill="clear" routerLink="/anantpuja">Shri Anant Puja</IonButton>
           <br></br>
           <IonButton fill="clear" routerLink="/navratri">Navratri Puja</IonButton>
           <br></br>
           <IonButton fill="clear" routerLink="/shivratri">Shivratri Puja</IonButton>
           <br></br>
           <IonButton fill="clear" routerLink="/ramnavami">Ram Navami Puja</IonButton>
           <IonButton fill="clear" routerLink="/vishwakarmapuja">Sri Vishwakarma Puja</IonButton>
           <IonButton fill="clear" routerLink="/laxmipuja">Shri Lakshmi Puja</IonButton>
           </IonCol>
           <IonCol>
             <h3>संस्कार / Puja</h3>
             <IonButton fill="clear" routerLink="/janmotasavapuja">Janmotasav Puja</IonButton>
             <br></br>
             <IonButton fill="clear" routerLink="/shadi">Shadi Vivah</IonButton>
             <br></br>
             <IonButton fill="clear" routerLink="/mundan">मुंडन संस्कार</IonButton>
             <br></br>
             <IonButton fill="clear" routerLink="/bhumipujan">Bhumi Pujan</IonButton>
             <br></br>
             <IonButton fill="clear" routerLink="/jirngrihprawesh">Jirn Grih Prawesh</IonButton>
             <br></br>
             <IonButton fill="clear" routerLink="/navgrihprawesh">Nav Grih Prawesh</IonButton>
             <IonButton fill="clear" routerLink="/vyaparshubharambhpuja">Vyapar Shubh Arambh Puja</IonButton>
             <IonButton fill="clear" routerLink="/vyaparunnatianusthan">Vyapar Unnati Anusthan</IonButton>
           </IonCol>
           <IonCol><h3>Yagya</h3>
           <IonButton fill="clear" routerLink="/yagyasurya">Surya Yagya</IonButton>
           <IonButton fill="clear" routerLink="/yagyarudra">Maha Rudra Yagya</IonButton>
           <IonButton fill="clear" routerLink="/yagyavishnu">Sri Vishnu Yagya</IonButton>
           <IonButton fill="clear" routerLink="/yagyagyatri">Gayatri Yagya</IonButton>
           <IonButton fill="clear" routerLink="/yagyakaryasiddhi">Karya-siddhi Yagya</IonButton>
           <IonButton fill="clear" routerLink="/yagyaaswamedh">Aswamedh Yagya</IonButton>
           </IonCol>
        </IonRow>
       </div>
       <IonListHeader lines="full"></IonListHeader>
            <div className="ion-text-center">
            <IonButton fill="clear" routerLink="/home">Home</IonButton>
            <IonButton fill="clear" routerLink="/services">Services</IonButton>
            <IonButton fill="clear" routerLink="/panchang">Panchang</IonButton>
            <IonButton fill="clear" routerLink="/granth">Hamare Granth</IonButton>
            <IonButton fill="clear" routerLink="/products">Products</IonButton>
            <IonButton fill="clear" routerLink="/contact">Contact Us</IonButton>
        </div>
        <IonListHeader lines="full"></IonListHeader>
        <IonRow><IonCol>© Copyright Reserved 2022-2023 |<IonButton fill="clear" routerLink='/firstp'> https://sanatanpuja.in </IonButton></IonCol></IonRow>
        </div>

    )
}
export default Footmenu;
