import { IonGrid, IonRow, IonCol, IonButton, IonListHeader} from '@ionic/react';
import {Adsense} from '@ctrl/react-adsense';

import './Granth.css';


const Home: React.FC = () => {
  return (
    <div className="ion-padding">
      <IonGrid fixed={true}>
        
          <div className="ion-text-center">
          <h1>हिन्दू धर्म ग्रंथ इतिहास के नाम</h1>
              <IonRow><IonCol size="5">श्रीमद्भागवत</IonCol><IonCol><a href="https://amzn.to/3V9PeH3"target="_blank">Buy Now</a></IonCol><IonCol><IonButton size="small" fill="clear" routerLink="/bhagwat">Read Online</IonButton></IonCol></IonRow>
              <IonRow><IonCol size="5">रामचरितमानस</IonCol><IonCol><a href="https://amzn.to/3YKBMfZ"target="_blank">Buy Now</a></IonCol><IonCol><IonButton size="small" fill="clear" routerLink="/ramcharit">Read Online</IonButton></IonCol></IonRow>
              <IonRow><IonCol size="5">रामायण</IonCol><IonCol><a href="https://amzn.to/3Wry0Gd"target="_blank">Buy Now</a></IonCol><IonCol>Read Online</IonCol></IonRow>
              <IonRow><IonCol size="5">महाभारत</IonCol><IonCol><a href="https://amzn.to/3uY9ssu"target="_blank">Buy Now</a></IonCol><IonCol>Read Online</IonCol></IonRow>
              <IonRow><IonCol size="5">देवीभागवत</IonCol><IonCol><a href="https://amzn.to/3PIQKPq"target="_blank">Buy Now</a></IonCol><IonCol>Read Online</IonCol></IonRow>
              <IonRow><IonCol size="5">श्रीमद्भगवद्गीता – ये महाभारत का एक भाग हैं।</IonCol><IonCol><a href="https://amzn.to/3FXwIMH"target="_blank">Buy Now</a></IonCol><IonCol>Read Online</IonCol></IonRow>
              <IonListHeader lines="full"></IonListHeader>
              <IonRow>
        <Adsense
                client="ca-pub-4029214824221330"
                slot="2310889447"
                style={{ display: "block" }}
                format="auto"
                responsive="true"
              />
        </IonRow>
        
            <h1>चार वेदों के नाम </h1>
              <IonRow><IonCol size="5">ऋग्वेद </IonCol><IonCol><a href="https://amzn.to/3j6mZvr" target="_blank">Buy Now</a></IonCol><IonCol>Read Online</IonCol></IonRow>
              <IonRow><IonCol size="5">यजुर्वेद</IonCol><IonCol><a href="https://amzn.to/3PBpkuA"target="_blank">Buy Now</a></IonCol><IonCol>Read Online</IonCol></IonRow>
              <IonRow><IonCol size="5">सामवेद</IonCol><IonCol><a href="https://amzn.to/3jhyRes"target="_blank">Buy Now</a></IonCol><IonCol>Read Online</IonCol></IonRow>
              <IonRow><IonCol size="5">अथर्ववेद </IonCol><IonCol><a href="https://amzn.to/3BMrxO9"target="_blank">Buy Now</a></IonCol><IonCol>Read Online</IonCol></IonRow>
              <IonListHeader lines="full"></IonListHeader>

            <h1>हिन्दू धर्म ग्रंथ पुराण के नाम </h1>
              <IonRow><IonCol size="5">ब्रह्मपुराण</IonCol><IonCol><a href="https://amzn.to/3uYhLVh"target="_blank">Buy Now</a></IonCol><IonCol>Read Online</IonCol></IonRow>
              <IonRow><IonCol size="5">पद्मपुराण</IonCol><IonCol><a href="https://amzn.to/3WtXDpK"target="_blank">Buy Now</a></IonCol><IonCol>Read Online</IonCol></IonRow>
              <IonRow><IonCol size="5">विष्णुपुराण</IonCol><IonCol><a href="https://amzn.to/3V5SfIw"target="_blank">Buy Now</a></IonCol><IonCol>Read Online</IonCol></IonRow>
              <IonRow><IonCol size="5">वायुपुराण</IonCol><IonCol><a href="https://amzn.to/3j2Fjpi"target="_blank">Buy Now</a></IonCol><IonCol>Read Online</IonCol></IonRow>
              <IonRow><IonCol size="5">शिवपुराण</IonCol><IonCol><a href="https://amzn.to/3HHqrHn"target="_blank">Buy Now</a></IonCol><IonCol>Read Online</IonCol></IonRow>
              <IonRow><IonCol size="5">नारदपुराण</IonCol><IonCol><a href="https://amzn.to/3HKR26c"target="_blank">Buy Now</a></IonCol><IonCol>Read Online</IonCol></IonRow>
              <IonRow><IonCol size="5">मार्कण्डेयपुराण</IonCol><IonCol><a href="https://amzn.to/3WpKY7s"target="_blank">Buy Now</a></IonCol><IonCol>Read Online</IonCol></IonRow>
              <IonRow><IonCol size="5">अग्निपुराण</IonCol><IonCol><a href="https://amzn.to/3Yv0nFb"target="_blank">Buy Now</a></IonCol><IonCol>Read Online</IonCol></IonRow>
              <IonRow><IonCol size="5">भविष्यपुराण</IonCol><IonCol><a href="https://amzn.to/3uY3w2H"target="_blank">Buy Now</a></IonCol><IonCol>Read Online</IonCol></IonRow>
              <IonRow><IonCol size="5">ब्रह्मवैवर्तपुराण</IonCol><IonCol><a href="https://amzn.to/3W91s3Q"target="_blank">Buy Now</a></IonCol><IonCol>Read Online</IonCol></IonRow>
              <IonRow><IonCol size="5">लिंगपुराण</IonCol><IonCol><a href="https://amzn.to/3hx4T5E"target="_blank">Buy Now</a></IonCol><IonCol>Read Online</IonCol></IonRow>
              <IonRow><IonCol size="5">वराहपुराण</IonCol><IonCol><a href="https://amzn.to/3HN6sqQ"target="_blank">Buy Now</a></IonCol><IonCol>Read Online</IonCol></IonRow>
              <IonRow><IonCol size="5">स्कन्दपुराण</IonCol><IonCol><a href="https://amzn.to/3jhAusA"target="_blank">Buy Now</a></IonCol><IonCol>Read Online</IonCol></IonRow>
              <IonRow><IonCol size="5">वामनपुराण</IonCol><IonCol><a href="https://amzn.to/3G4hOW9"target="_blank">Buy Now</a></IonCol><IonCol>Read Online</IonCol></IonRow>
              <IonRow><IonCol size="5">कूर्मपुराण</IonCol><IonCol><a href="https://amzn.to/3W813Pk"target="_blank">Buy Now</a></IonCol><IonCol>Read Online</IonCol></IonRow>
              <IonRow><IonCol size="5">मत्स्यपुराण</IonCol><IonCol><a href="https://amzn.to/3Yyr5wB"target="_blank">Buy Now</a></IonCol><IonCol>Read Online</IonCol></IonRow>
              <IonRow><IonCol size="5">गरुड़पुराण</IonCol><IonCol><a href="https://amzn.to/3jhD7KA"target="_blank">Buy Now</a></IonCol><IonCol>Read Online</IonCol></IonRow>
              <IonRow><IonCol size="5">ब्रह्माण्डपुराण</IonCol><IonCol><a href="https://amzn.to/3FYNXhI"target="_blank">Buy Now</a></IonCol><IonCol>Read Online</IonCol></IonRow>
              <IonListHeader lines="full"></IonListHeader>

            <h1>आयुर्वेद ग्रंथों के नाम </h1>
              <IonRow><IonCol size="5">चरक संहिता</IonCol><IonCol><a href="https://amzn.to/3BFoyXR" target="_blank">Buy Now</a></IonCol><IonCol>Read Online</IonCol></IonRow>
              <IonRow><IonCol size="5">सुश्रुत संहिता</IonCol><IonCol><a href="https://amzn.to/3W81SYq" target="_blank">Buy Now</a></IonCol><IonCol>Read Online</IonCol></IonRow>
              <IonRow><IonCol size="5">अष्टांग हृदयम</IonCol><IonCol><a href="https://amzn.to/3HL223v" target="_blank">Buy Now</a></IonCol><IonCol>Read Online</IonCol></IonRow>
              <IonListHeader lines="full"></IonListHeader>

            <h1>योगग्रंथ के नाम</h1>
            <IonRow><IonCol size="5">पतंजलि योग दर्शन</IonCol><IonCol><a href="https://amzn.to/3hvavgJ"target="_blank">Buy Now</a></IonCol><IonCol>Read Online</IonCol></IonRow>
          </div>
        
      </IonGrid>
    </div>  
    
    
  );
};
export default Home;

