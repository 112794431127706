import {IonRow, IonGrid } from '@ionic/react';
import {Adsense} from '@ctrl/react-adsense';
import aadishivImage from './aadishiv.jpeg';

import './Teej.css';


const Teej: React.FC = () => {
  return (
    <div className="ion-padding">
      <IonGrid fixed={true}>
      <div className='ion-text-center'>
      <h1>हरितालिका तीज - Hartalika Teej </h1>
      </div>
      <div className='ion-text-justify'>

        <h3>हरितालिका तीज त्योहार के पीछे की कहानी हिंदू पौराणिक कथाओं से ली गई है, कहा जाता है कि यह वह दिन है जब देवी पार्वती भगवान शिव  से विवाह के लिए तपस्या की थी। पौराणिक कथा के अनुसार, पार्वती, जो राजा हिमावत और रानी मैना की बेटी थीं, भगवान शिव से बहुत प्यार करती थीं और उनसे शादी करना चाहती थीं। हालाँकि, भगवान शिव गहरे ध्यान में थे और उन्हें भौतिक दुनिया में कोई दिलचस्पी नहीं थी।</h3>
        <h3>भगवान शिव के प्रेम को जीतने के लिए, पार्वती ने अपना घर छोड़ दिया और हरिती नदी के तट पर घोर तपस्या करने लगीं। उसने वर्षों तक भगवान शिव की उपवास और प्रार्थना की, और उसकी भक्ति और दृढ़ संकल्प ने आखिरकार भगवान शिव का दिल जीत लिया। उनका विवाह हुआ और पार्वती उनकी पत्नी बनीं।</h3>
        <h3>इस दिव्य मिलन के सम्मान में हर साल भाद्रपद मास की तृतीया को हरितालिका तीज का त्योहार मनाया जाता है। महिलाएं पारंपरिक तीज का व्रत रखती हैं, गीत गाती हैं, नृत्य करती हैं और देवी पार्वती का आशीर्वाद लेने के लिए पूजा करती हैं, जिन्होंने कड़ी तपस्या करके भगवान शिव का प्यार और आशीर्वाद प्राप्त किया। ऐसा माना जाता है कि इस दिन व्रत रखने और अनुष्ठान करने से वैवाहिक सुख और उनके पति की भलाई सुनिश्चित होती है।</h3>
        <h3>यह त्योहार विशेष रूप से विवाहित महिलाओं के लिए महत्वपूर्ण है, जो अपने पति की लंबी उम्र और समृद्धि की कामना करती हैं और अविवाहितों के लिए भी महत्वपूर्ण है उन्हें एक अच्छा साथी मिलता है। कुल मिलाकर, हरितालिका तीज का त्योहार किसी की इच्छाओं को प्राप्त करने में भक्ति और दृढ़ संकल्प की शक्ति और हिंदू धर्म में विवाहित जीवन के महत्व की याद दिलाता है।</h3>
      </div>
        <IonRow>
        <Adsense
                client="ca-pub-4029214824221330"
                slot="2310889447"
                style={{ display: "block" }}
                format="auto"
                responsive="true"
              />
        </IonRow>

        <h4>The story behind Haritalika Teej festival is derived from Hindu mythology, it is said to be the day when Goddess Parvati did penance to marry Lord Shiva. According to legend, Parvati, who was the daughter of King Himavat and Queen Maina, was very much in love with Lord Shiva and wanted to marry him. However, Lord Shiva was in deep meditation and was not interested in the material world.</h4>
        <h4>Determined to win Lord Shiva's love, Parvati left her royal home and started performing severe austerities on the banks of the river Hariti. She fasted and prayed to Lord Shiva for years, and her devotion and determination finally won Lord Shiva's heart. They got married and Parvati become his wife.</h4>
        <h4>In honor of this divine union, the Haritalika Teej festival is celebrated every year on the Tritiya of the Bhadrapada month. Women observe fast, sing and dance to the traditional Teej songs,and perform puja to seek blessings of goddess Parvati, who by performing severe austerities, got the love and blessings of Lord Shiva. It is believed that observing the fast and performing the rituals on this day will ensure marital happiness and the well-being of their husband.</h4>
        <h4>The festival is especially important for married women, who wish for the long life and prosperity of their husbands, and is also important for the unmarried to find a good partner. Overall, the festival of Haritalika Teej is a reminder of the power of devotion and determination in achieving one's desires and the importance of married life in Hinduism.</h4>
         <div className='bg_image' style={{
        backgroundImage: 'url('+aadishivImage+')',
        backgroundSize: "100%",
        backgroundRepeat: "no-repeat",
        height:"100vh",
        color:"#000000"
      }}>
       
      
      </div>
      </IonGrid>
    </div>
  );
};

export default Teej;

