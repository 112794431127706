import {IonRow,IonCol, IonGrid, IonButton, IonText, IonImg, IonIcon, IonListHeader  } from '@ionic/react';
import {Adsense} from '@ctrl/react-adsense';


import './Navratri.css';


const Navratri: React.FC = () => {
  return (
    <div className="ion-padding">
      <h1>Navratri </h1>
       <p> Navratri is a Hindu festival that is celebrated annually for nine nights and ten days. It is celebrated twice a year, once in the spring (Chaitra Navratri) and once in the fall (Sharad Navratri). Navratri literally means "nine nights" and during this festival, the goddess Durga is worshipped in her various forms.</p>
       <p>Navratri is a festival that celebrates the victory of good over evil. It is believed that during Navratri, the goddess Durga defeated the demon king Mahishasura and restored peace and prosperity to the earth. The festival is celebrated to honor the goddess and to seek her blessings for good health, wealth, and happiness.</p>
       <p>During Navratri, people observe fasts, perform puja and other rituals, and participate in cultural activities such as Dandiya and Garba, traditional dance forms. The festival is celebrated with great enthusiasm and devotion, and people dress up in traditional attire and participate in the celebrations with friends and family.</p>
       <p>On the ninth day of Navratri, also known as Mahanavami, the goddess Durga is worshipped in her full form and the fast is broken. The tenth day, known as Vijayadashami, is celebrated as the victory of good over evil and is considered to be the most auspicious day of the festival.</p>
       <p>Navratri is an important festival in Hindu culture, it is a time to connect with the divine, to seek blessings and to celebrate the victory of good over evil. It is also an opportunity to connect with tradition, culture, and spirituality. It is a time to come together with friends and family, to participate in cultural activities, and to have fun.</p>
       <IonRow>
        <Adsense
                client="ca-pub-4029214824221330"
                slot="2310889447"
                style={{ display: "block" }}
                format="auto"
                responsive="true"
              />
        </IonRow>
    </div>
  );
};

export default Navratri;
