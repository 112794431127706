import { IonContent, IonPage} from '@ionic/react';
import { RouteComponentProps } from 'react-router';
import { Route } from 'react-router-dom';
import Firstp from '../firstp/Firstp';
import Header from '../../components/Header/Header';
import Home from '../home/Home';
import Products from '../products/Products';
import Career from '../career/Career';
import Panchang from '../panchang/Panchang';
import Rashiphal from '../rashiphal/Rashiphal';
import Services from '../services/Services';
import Mahalakshmijaap from '../mahalakshmijaap/Mahalakshmijaap';
import Ganesh from '../ganesh/Ganesh';
import Mahasarasawatijaap from '../mahasarasawatijaap/Mahasarasawatijaap';
import Vastu from '../vastu/Vastu';
import Bhumipujan from '../bhumipujan/Bhumipujan';
import Granth from '../granth/Granth';
import Contact from '../contact/Contact';
import Rudravishek from '../rudravishek/Rudravishek';
import Satyanarayan from '../satyanarayan/Satyanarayan';
import Ramcharit from '../ramcharit/Ramcharit';
import Ramcharitbalkand from '../ramcharitbalkand/Ramcharitbalkand';
import Bhagwat from '../bhagwat/Bhagwat';
import Bhagwatgeeta from'../bhagwatgeeta/Bhagwatgeeta';
import Bhagwatgeeta2 from'../bhagwatgeeta2/Bhagwatgeeta2';
import Bhagwatgeeta3 from'../bhagwatgeeta3/Bhagwatgeeta3';
import Menu from '../../components/Menu/Menu';
import Mirtunjay from '../mirtunjay/Mirtunjay';
import Navgrah from '../navgrah/Navgrah';
import Anantpuja from '../anantpuja/Anantpuja';
import Ekadashi from '../ekadashi/Ekadashi';
import Janmotasavapuja from '../janmotasavapuja/Janmotasavapuja';
import Jirngrihprawesh from '../jirngrihprawesh/Jirngrihprawesh';
import Laxmipuja from '../laxmipuja/Laxmipuja';
import Mundan from '../mundan/Mundan';
import Navgrihprawesh from '../navgrihprawesh/Navgrihprawesh';
import Navratri from '../navratri/Navratri';
import Ramnavami from '../ramnavami/Ramnavami';
import Shadi from '../shadi/Shadi';
import Shivratri from '../shivratri/Shivratri';
import Teej from '../teej/Teej';
import Vishwakarmapuja from '../vishwakarmapuja/Vishwakarmapuja';
import Vyaparshubharambhpuja from '../vyaparshubharambhpuja/Vyaparshubharambhpuja';
import Vyaparunnatianusthan from '../vyaparunnatianusthan/Vyaparunnatianusthan';
import Yagyaaswamedh from '../yagyaaswamedh/Yagyaaswamedh';
import Yagyagyatri from '../yagyagyatri/Yagyagyatri';
import Yagyakaryasiddhi from '../yagyakaryasiddhi/Yagyakaryasiddhi';
import Yagyarudra from '../yagyarudra/Yagyarudra';
import Yagyasurya from '../yagyasurya/Yagyasurya';
import Yagyavishnu from '../yagyavishnu/Yagyavishnu';
import Triyadashi from '../triyadashi/Triyadashi'



import SignUpPage from '../auth/SignUpPage';

import './Master.css';
import Footmenu from '../../components/Footmenu/Footmenu';



const Master: React.FC<RouteComponentProps> = ({ match }) => {
    console.log('match', match.url)
  return (
    
    <>
      <Menu id="main-content"/>
      <IonPage id="main-content">
        <Header />     
        <IonContent fullscreen={true} className="ion-padding">
          <Route exact path="/firstp" component={Firstp} />
          <Route exact path="/home" component={Home} />
          <Route exact path="/products" component={Products} />
          <Route exact path="/career" component={Career} />
          <Route exact path="/panchang" component={Panchang} />
          <Route exact path="/rashiphal" component={Rashiphal} />
          <Route exact path="/services" component={Services} />
          <Route exact path="/granth" component={Granth} />
          <Route exact path="/contact" component={Contact}/>
          <Route exact path="/rudravishek" component={Rudravishek} />
          <Route exact path="/satyanarayan" component={Satyanarayan} />
          <Route exact path="/bhagwat" component={Bhagwat}/>
          <Route exact path="/bhagwatgeeta"component={Bhagwatgeeta} />
          <Route exact path="/bhagwatgeeta2"component={Bhagwatgeeta2} />
          <Route exact path="/bhagwatgeeta3"component={Bhagwatgeeta3} />
          <Route exact path="/ramcharit" component={Ramcharit} />
          <Route exact path="/ramcharitbalkand" component={Ramcharitbalkand} />
          <Route exact path="/mirtunjay" component={Mirtunjay}/>
          <Route exact path="/mahalakshmijaap" component={Mahalakshmijaap} />
          <Route exact path="/mahasarasawatijaap" component={Mahasarasawatijaap} />
          <Route exact path="/ganesh" component={Ganesh} />
          <Route exact path="/navgrah" component={Navgrah}/>
          <Route exact path="/vastu" component={Vastu} />
          <Route exact path="/bhumipujan" component={Bhumipujan} />
          
          <Route exact path="/anantpuja" component={Anantpuja} />
          <Route exact path="/ekadashi" component={Ekadashi} />
          <Route exact path="/janmotasavapuja" component={Janmotasavapuja} />
          <Route exact path="/jirngrihprawesh" component={Jirngrihprawesh} />
          <Route exact path="/laxmipuja" component={Laxmipuja} />
          <Route exact path="/mundan" component={Mundan} />
          <Route exact path="/navgrihprawesh" component={Navgrihprawesh} />
          <Route exact path="/navratri" component={Navratri} />
          <Route exact path="/ramnavami" component={Ramnavami} />
          <Route exact path="/shadi" component={Shadi} />
          <Route exact path="/shivratri" component={Shivratri} />
          <Route exact path="/teej" component={Teej} />
          <Route exact path="/vishwakarmapuja" component={Vishwakarmapuja} />
          <Route exact path="/vyaparshubharambhpuja" component={Vyaparshubharambhpuja} />
          <Route exact path="/vyaparunnatianusthan" component={Vyaparunnatianusthan} />
          <Route exact path="/yagyaaswamedh" component={Yagyaaswamedh} />
          <Route exact path="/yagyagyatri" component={Yagyagyatri} />
          <Route exact path="/yagyakaryasiddhi" component={Yagyakaryasiddhi} />
          <Route exact path="/yagyarudra" component={Yagyarudra} />
          <Route exact path="/yagyasurya" component={Yagyasurya} />
          <Route exact path="/yagyavishnu" component={Yagyavishnu} />
          <Route exact path="/triyadashi" component={Triyadashi} />


          <Route exact path="/auth/signup" component={SignUpPage}/>
          <Footmenu />
        </IonContent>
        
      </IonPage>
    </>
  );
};

export default Master;
