import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom';
import { IonToolbar, IonGrid, IonRow, IonCol, IonButton,IonIcon, IonListHeader} from '@ionic/react';
import firebase from 'firebase/compat';

import IPageProps from '../../interfaces/page.interface';
import { SignInWithSocialMedia } from '../../modules/auth';
import { Providers } from '../../config/firebase';

import { informationCircle, star,logoGoogle, home,logoFacebook, logoTwitter, logoInstagram, logoYoutube } from 'ionicons/icons';


const SignUpPage: React.FunctionComponent<IPageProps> = props => {
    const [authenticating, setAuthenticating] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const history = useHistory();

    const signInWithSocialMedia = (provider: firebase.auth.AuthProvider) => {
        if (error !== '') setError('');

        setAuthenticating(true);

        SignInWithSocialMedia(provider)
            .then(result => {
                history.push('/');
            })
            .catch(error => {
                setAuthenticating(false);
                setError(error.message);
            });
    }

    return (
        <div className="AuthLogin">
            <div className="auth-main-container">
                <IonToolbar>
                <IonGrid fixed={true}>
                    
                    <div className="ion-text-center">
                    <IonRow> <IonCol><h1 >Welcome to Sanatan Puja</h1></IonCol></IonRow>
                    <IonRow><IonCol>Please Signup to continue by choosing one of the options below.</IonCol></IonRow>
                    </div>
                    
                    
                        <div className="auth-btn-wrapper">
                        <IonRow>
                            <IonCol>
                            <IonButton disabled={authenticating} onClick={() => signInWithSocialMedia(Providers.google)}>
                            <IonIcon icon={logoGoogle} slot="start"></IonIcon>SignUp with Google<IonIcon icon={star} slot="end"></IonIcon></IonButton>
                            </IonCol>
                            {/* <button
                            disabled={authenticating}
                            onClick={() => signInWithSocialMedia(Providers.facebook)}
                            >
                            Sign with Facebook</button> */}
                            </IonRow>
                            <IonRow>
                                <IonCol>
                            <Link to={`/`}>
                            <IonButton><IonIcon icon={home} slot="start"></IonIcon>Back To Home Page<IonIcon icon={star} slot="end"></IonIcon></IonButton>
                            </Link>
                            </IonCol>
                            </IonRow>
                            
                        </div>
                        
                </IonGrid>
                </IonToolbar>
            </div>
            <IonListHeader lines='full'></IonListHeader>
            <IonRow>
            <IonCol>
              <IonButton fill="clear"href="https://youtube.com/@sanatanpujain">YouTube<IonIcon icon={logoYoutube}slot="start"></IonIcon></IonButton>
              <IonButton fill="clear" href="https://facebook.com/sanatanpuja.in">FaceBook<IonIcon icon={logoFacebook}slot="start"></IonIcon></IonButton>
              <IonButton fill="clear" href="https://twitter.com/sanatanpuja">Twitter<IonIcon icon={logoTwitter}slot="start"></IonIcon></IonButton>
              <IonButton fill="clear" href="https://instagram.com/sanatanpuja.in">InstaGram<IonIcon icon={logoInstagram}slot="start"></IonIcon></IonButton>
            </IonCol>
          </IonRow>
          <IonRow><IonCol>© Copyright Reserved "https://sanatanpuja.in"</IonCol></IonRow>

        </div>
    );
}

export default SignUpPage;
