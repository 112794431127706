import {IonRow,IonCol, IonGrid, IonButton, IonText, IonImg, IonIcon, IonListHeader  } from '@ionic/react';
import {Adsense} from '@ctrl/react-adsense';


import './Vishwakarmapuja.css';


const Vishwakarmapuja: React.FC = () => {
  return (
    <div className="ion-padding">
        <h1>Vishwakarma Puja</h1>
        <p>Vishwakarma Puja is a Hindu festival that is celebrated to honor Lord Vishwakarma, the divine architect and engineer of the universe. The festival is usually celebrated on the 17th day of the Hindu month of Bhadrapada, which falls in the month of September or October.</p>
        <p>Lord Vishwakarma is considered to be the patron deity of all artisans, craftsmen, engineers, and architects. He is believed to have created the world and all its workings, and is considered to be the creator of the universe and the master of all arts and crafts.</p>
        <p>During the festival, people perform puja and other rituals to invoke the blessings of Lord Vishwakarma. Temples and workplaces dedicated to Lord Vishwakarma are decorated with flowers and lights, and special puja is performed to honor him. Devotees also offer milk, honey, and other offerings to Lord Vishwakarma and chant mantras in his honor.</p>
        <p>The festival is also celebrated by artisans, craftsmen, engineers, and architects who perform puja and other rituals in their workplaces. They clean their tools and equipment and place them in front of Lord Vishwakarma's idol to seek his blessings.</p>
        <p>Vishwakarma Puja is also considered to be a day of thanksgiving, people express gratitude to Lord Vishwakarma for the skills and knowledge that he has bestowed upon them and for the prosperity of their businesses.</p>
        <p>In conclusion, Vishwakarma Puja is an important Hindu festival that is celebrated to honor Lord Vishwakarma, the divine architect and engineer of the universe. It's a day of devotion, thanksgiving and is celebrated by artisans, craftsmen, engineers, and architects to seek blessings from Lord Vishwakarma for the skills and knowledge that he has bestowed upon them and for the prosperity of their businesses. It's a day to connect with tradition and spirituality and to pay respect to the creator of the universe.</p>
        <IonRow>
        <Adsense
                client="ca-pub-4029214824221330"
                slot="2310889447"
                style={{ display: "block" }}
                format="auto"
                responsive="true"
              />
        </IonRow>
    </div>
  );
};

export default Vishwakarmapuja;

