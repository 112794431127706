import React from 'react';
import { IonImg,IonGrid, IonRow, IonCol, IonButton } from '@ionic/react';
import {Adsense} from '@ctrl/react-adsense';

import './Bhagwatgeeta2.css';
    
const Bhagwatgeeta2: React.FC = () => {
  return (
      <div className="ion-padding">
        <div className="ion-text-center">
        <h1>संपूर्ण श्रीमद्भगवद्गीता</h1>
        <IonGrid fixed={true}>

        <IonRow><IonCol><h1>दूसरा अध्याय -  संख्या योग  </h1></IonCol></IonRow>
        <IonRow>
            <IonCol><IonButton fill="clear" routerLink="/bhagwatgeeta">प्रथम अध्याय</IonButton></IonCol>
            <IonCol><IonButton fill="clear" routerLink="/bhagwat">Index Page</IonButton></IonCol>
            <IonCol> <IonButton fill="clear" routerLink="/bhagwatgeeta3">तीसरा अध्याय </IonButton></IonCol>
        </IonRow>
        
        <IonRow>
            <IonCol>
                <IonImg src="/assets/images/gita/a2/A2.jpg" />
            </IonCol>
        </IonRow>
        <IonRow>
            <IonCol>
                <IonImg src="/assets/images/gita/a2/A2S1.jpg" />
                <h1>श्रीमद्भगवद्गीता</h1>
                <p>दूसरा अध्याय -  संख्या योग</p>
            </IonCol>
        </IonRow>
        <IonRow>
            <IonCol><IonImg src="/assets/images/gita/a2/A2S2.jpg" />
                <h1>श्रीमद्भगवद्गीता</h1>
                <p>दूसरा अध्याय -  संख्या योग</p>
            </IonCol>
        </IonRow>
        <IonRow>
            <IonCol><IonImg src="/assets/images/gita/a2/A2S3.jpg" />
                <h1>श्रीमद्भगवद्गीता</h1>
                <p>दूसरा अध्याय -  संख्या योग</p>
            </IonCol>
        </IonRow>
        <IonRow><IonCol>
          <Adsense
            client="ca-pub-4029214824221330"
            slot="2310889447"
            style={{ display: "block" }}
            format="auto"
            responsive="true"
          />
          </IonCol></IonRow>

        <IonRow>
            <IonCol><IonImg src="/assets/images/gita/a2/A2S4.jpg" />
                <h1>श्रीमद्भगवद्गीता</h1>
                <p>दूसरा अध्याय -  संख्या योग</p>
            </IonCol>
        </IonRow>
        <IonRow>
            <IonCol><IonImg src="/assets/images/gita/a2/A2S5.jpg" />
            <h1>श्रीमद्भगवद्गीता</h1>
            <p>दूसरा अध्याय -  संख्या योग</p>
            </IonCol>
        </IonRow>
        
        <IonRow>
            <IonCol><IonImg src="/assets/images/gita/a2/A2S6.jpg" />
            <h1>श्रीमद्भगवद्गीता</h1>
            <p>दूसरा अध्याय -  संख्या योग</p>
            </IonCol>
        </IonRow>
        <IonRow>
            <IonCol><IonImg src="/assets/images/gita/a2/A2S7.jpg" />
            <h1>श्रीमद्भगवद्गीता</h1>
            <p>दूसरा अध्याय -  संख्या योग</p>
            </IonCol>
        </IonRow>
        <IonRow><IonCol>
          <Adsense
            client="ca-pub-4029214824221330"
            slot="2310889447"
            style={{ display: "block" }}
            format="auto"
            responsive="true"
          />
          </IonCol></IonRow>

        <IonRow>
            <IonCol><IonImg src="/assets/images/gita/a2/A2S8.jpg" />
            <h1>श्रीमद्भगवद्गीता</h1>
            <p>दूसरा अध्याय -  संख्या योग</p>
            </IonCol>
        </IonRow>
        <IonRow>
            <IonCol><IonImg src="/assets/images/gita/a2/A2S9.jpg" />
            <h1>श्रीमद्भगवद्गीता</h1>
            <p>दूसरा अध्याय -  संख्या योग</p>
            </IonCol>
        </IonRow>
        <IonRow>
            <IonCol><IonImg src="/assets/images/gita/a2/A2S10.jpg" />
            <h1>श्रीमद्भगवद्गीता</h1>
            <p>दूसरा अध्याय -  संख्या योग</p>
            </IonCol>
        </IonRow>
        
        <IonRow>
            <IonCol><IonButton fill="clear" routerLink="/bhagwatgeeta">प्रथम अध्याय</IonButton></IonCol>
            <IonCol><IonButton fill="clear" routerLink="/bhagwat">Index Page</IonButton></IonCol>
            <IonCol> <IonButton fill="clear" routerLink="/bhagwatgeeta3">तीसरा अध्याय </IonButton></IonCol>
        </IonRow>
        </IonGrid>
        </div>
                </div>
  );
};

export default Bhagwatgeeta2;