import {IonRow,IonCol, IonGrid, IonButton, IonText, IonImg, IonListHeader  } from '@ionic/react';
import './Navgrah.css';
import {Adsense} from '@ctrl/react-adsense';


const Navgrah: React.FC = () => {
  return (
    <div className="ion-padding">
        <div className="ion-text-center">
        <IonGrid fixed={true}>
        <IonRow><IonCol><IonImg src="/assets/images/planet.png" /></IonCol></IonRow>
<h1><b>ब्रह्मा मुरारिस्त्रिपुरान्तकारी, भानुः शशी भूमिसुतो बुधश्च ।</b></h1>
<h1><b>गुरुश्च शुक्रः शनिराहुकेतवः, कुर्वन्तु सर्वे मम सुप्रभातम् ॥१॥</b></h1>
<h2><b>देव ब्रह्मा, मुरारी और त्रिपुरांतकारी (जिसने त्रिपुरासुरों को समाप्त किया), ग्रह भानु (सूर्य), शशि (चंद्रमा), बुमिसुता (मंगल) और बुद्ध (बुध), गुरु (बृहस्पति), शुक्र, शनि, राहु और केतु, सभी मेरी सुबह को शुभ बनाएं।</b></h2>
<h3><b>The gods Brahma, Murari and Tripurantkari (who eliminated the Tripurasuras), the planets Bhanu (Sun), Shashi (Moon), Bumisuta (Mars) and Budha (Mercury), Guru (Jupiter), Shukra, Shani, Rahu and Ketu, all my Make the morning auspicious.</b></h3>
        <IonText color="tertiary">
        <h1><b>|| ग्रह शांति जाप||</b></h1>
        </IonText>
        <IonListHeader lines="full"></IonListHeader>
        <h2>सभी ग्रहों का अपना एक मंत्र है और मंत्रों का जाप कितनी संख्या में होना चाहिए यह भी हर ग्रह के लिए अलग है। जब किसी ग्रह की महादशा आरंभ होती है तब उस ग्रह से संबंधित मंत्र की जाप संख्या एक निर्धारित समय में पूरी कर लेनी चाहिए। जब जाप पूरे हो जाएं तब दशांश हवन कराना चाहिए, एवं यथोचित दान व दक्षिणा देकर जापकर्ता से श्रेय दान अवश्य करा लेना चाहिए, अन्यथा जाप का फल जापकर्ता को ही चला जाता है। </h2>
        <h3>Each planet has its own mantra and the number of mantras that should be chanted is also different for each planet. When Mahadasha of a planet starts, then the number of chants related to that planet should be completed in a fixed time. When the chanting is over, Dashansh Havan should be performed, and by giving proper donation and Dakshina, the chanter must get credit done, otherwise the result of chanting goes to the chanter only.</h3>
        <IonListHeader lines="full"></IonListHeader>
<b>
<IonRow><IonCol>ग्रह</IonCol><IonCol>मंत्र</IonCol><IonCol>जाप संख्या</IonCol></IonRow>
<IonRow><IonCol>सूर्य</IonCol><IonCol>ऊँ घृणि सूर्याय नम:</IonCol><IonCol>7,000</IonCol></IonRow>
<IonRow><IonCol>चंद्रमा</IonCol><IonCol>ऊँ सों सोमाय नम:</IonCol><IonCol>11,000</IonCol></IonRow>
<IonRow><IonCol>मंगल</IonCol><IonCol>ऊँ अं अंगारकाय नम:</IonCol><IonCol>10,000</IonCol></IonRow>
<IonRow><IonCol>बुध</IonCol><IonCol>ऊँ बुं बुधाय नम:</IonCol><IonCol>9,000</IonCol></IonRow>
<IonRow><IonCol>गुरु</IonCol><IonCol>ऊँ बृं बृहस्पतये नम:</IonCol><IonCol>19,000</IonCol></IonRow>
<IonRow><IonCol>शुक्र</IonCol><IonCol>ऊँ शुं शुक्राय नम:</IonCol><IonCol>16,000</IonCol></IonRow>
<IonRow><IonCol>शनि</IonCol><IonCol>ऊँ शं शनैश्चराय नम:</IonCol><IonCol>23,000</IonCol></IonRow>
<IonRow><IonCol>राहु</IonCol><IonCol>ऊँ रां राहवे नम:</IonCol><IonCol>18,000</IonCol></IonRow>
<IonRow><IonCol>केतु</IonCol><IonCol>ऊं कें केतवे नम:</IonCol><IonCol>17,000</IonCol></IonRow>
</b>
<IonRow>
        <Adsense
                client="ca-pub-4029214824221330"
                slot="2310889447"
                style={{ display: "block" }}
                format="auto"
                responsive="true"
              />
        </IonRow>
<IonListHeader lines="full"></IonListHeader>
<h3>दशा आरंभ होने पर शुक्ल पक्ष में ग्रह संबधी जो भी पहला वार आये, उस वार से मंत्र जाप आरंभ करना चाहिए. राहु/केतु का कोई दिन नहीं होता है तो राहु के मंत्र जाप शनिवार से आरंभ करने चाहिए और केतु के जाप मंगलवार से आरंभ करने चाहिए.
मंत्रानुष्ठान के लिए शास्त्र के विधान का पालन करना परम आवश्यक है, अन्यथा लाभ के बदले हानि की संभावना अधिक रहती है।इसलिए यह आवश्यक है कि प्रत्येक कार्य शास्त्रसम्मत ही किया जाए। इस हेतु किसी योग्य और विद्वान व्यक्ति का मार्गदर्शन लेना चाहिए।
यदि साधक पूर्ण श्रद्धा और विश्वास के साथ यह साधना करें, तो वांछित फल की प्राप्ति की प्रबल संभावना रहती है।
</h3>
<h4>At the beginning of Dasha, the first attack related to a planet in Shukla Paksha should start chanting mantra from that attack. If there is no day for Rahu/Ketu, then chanting of Rahu's mantra should be started from Saturday and Ketu's chanting should be started from Tuesday. It is absolutely necessary to follow the law of the scriptures for mantra ritual, otherwise there is more possibility of loss instead of profit. That's why it is necessary that every work should be done according to the scriptures. For this, the guidance of a qualified and learned person should be taken. If the seeker does this practice with full devotion and faith, then there is a strong possibility of getting the desired result.</h4>        
        <IonText color="success">
        <h3><i><b>We are providing this service at very nominal Dakshina.</b></i></h3>
        </IonText>
        
        <IonRow><IonCol><IonButton routerLink="/contact">Contact Us for More Details</IonButton></IonCol></IonRow>
        </IonGrid>

        </div>
    </div>
  );
};

export default Navgrah;

