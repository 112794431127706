import React from 'react';
import { IonImg,IonGrid, IonRow, IonCol, IonButton } from '@ionic/react';
import {Adsense} from '@ctrl/react-adsense';
import './Ramcharitbalkand.css';
    
const Ramcharitbalkand: React.FC = () => {
  return (
      <div className="ion-padding">
        <div className="ion-text-center">
        <h1> श्री रामचरितमानस अर्थ सहित सम्पूर्ण</h1>
        <IonGrid fixed={true}>
        <IonRow><IonCol><h1>प्रथम सोपान - बालकाण्ड</h1></IonCol></IonRow>
        <IonRow><IonCol><IonButton fill="clear" routerLink="/ramcharit">Back To Index Page</IonButton></IonCol><IonButton fill="clear" routerLink="/balkand"><IonCol>दूसरा अध्याय </IonCol></IonButton></IonRow>
        <IonRow><IonCol><IonImg src="/assets/images/ramcharit/balkand/balkand.jpg" /></IonCol></IonRow>
        <IonRow>
            <IonCol>
                <IonImg src="/assets/images/ramcharit/balkand/balkand_s1.jpg" />
                <h1>श्री रामचरितमानस अर्थ सहित सम्पूर्ण</h1>
                <p>प्रथम सोपान - बालकाण्ड</p>
                
<h1>बालकाण्ड (अर्थ सहित) </h1>
<h3>श्लोक :</h3>
<h2><b> वर्णानामर्थसंघानां रसानां छन्दसामपि।</b></h2>
<h2><b>मंगलानां च कर्त्तारौ वन्दे वाणीविनायकौ॥1॥</b></h2>
<p>भावार्थ:-अक्षरों, अर्थ समूहों, रसों, छन्दों और मंगलों को करने वाली सरस्वतीजी और गणेशजी की मैं वंदना करता हूँ॥1॥ </p>
<p>Meaning:- I worship Saraswatiji and Ganeshji who do letters, meaning groups, rasas, verses and auspiciousness ॥1॥ </p>
<IonImg src="/assets/images/ramcharit/balkand/balkand_s2.jpg" />
<h2><b> भवानीशंकरौ वन्दे श्रद्धाविश्वासरूपिणौ।</b></h2>
<h2><b>याभ्यां विना न पश्यन्ति सिद्धाः स्वान्तःस्थमीश्वरम्‌॥2॥</b></h2>
<p>भावार्थ:-श्रद्धा और विश्वास के स्वरूप श्री पार्वतीजी और श्री शंकरजी की मैं वंदना करता हूँ, जिनके बिना सिद्धजन अपने अन्तःकरण में स्थित ईश्वर को नहीं देख सकते॥2॥</p>
<p>Meaning :- I worship Shri Parvatiji and Shri Shankarji in the form of devotion and faith, without whom Siddhajans cannot see the God situated in their hearts.॥2॥</p>
<IonImg src="/assets/images/ramcharit/balkand/balkand_s3.jpg" />
<h2><b> वन्दे बोधमयं नित्यं गुरुं शंकररूपिणम्‌।</b></h2>
<h2><b>यमाश्रितो हि वक्रोऽपि चन्द्रः सर्वत्र वन्द्यते॥3॥</b></h2>
<p>भावार्थ:-ज्ञानमय, नित्य, शंकर रूपी गुरु की मैं वन्दना करता हूँ, जिनके आश्रित होने से ही टेढ़ा चन्द्रमा भी सर्वत्र वन्दित होता है॥3॥</p>
<p>Meaning: I worship the Guru in the form of Lord Shiva, full of knowledge, eternal, under whose dependence even the crooked moon is worshiped everywhere. ॥3॥ </p>
<IonImg src="/assets/images/ramcharit/balkand/balkand_s4.jpg" />
<h2><b> सीतारामगुणग्रामपुण्यारण्यविहारिणौ।</b></h2>
<h2><b>वन्दे विशुद्धविज्ञानौ कवीश्वरकपीश्वरौ॥4॥</b></h2>
<p>भावार्थ:-श्री सीतारामजी के गुणसमूह रूपी पवित्र वन में विहार करने वाले, विशुद्ध विज्ञान सम्पन्न कवीश्वर श्री वाल्मीकिजी और कपीश्वर श्री हनुमानजी की मैं वन्दना करता हूँ॥4॥ </p>
<p>Meaning:- I worship Shri Valmikiji and Kapishwar Shri Hanumanji, who roam in the holy forest in the form of the virtues of Shri Sitaramji, who are full of pure science ॥4॥ </p>
<IonImg src="/assets/images/ramcharit/balkand/balkand_s5.jpg" />
<h2><b> उद्भवस्थितिसंहारकारिणीं क्लेशहारिणीम्‌।</b></h2>
<h2><b>सर्वश्रेयस्करीं सीतां नतोऽहं रामवल्लभाम्‌॥5॥</b></h2>
<p>भावार्थ:-उत्पत्ति, स्थिति (पालन) और संहार करने वाली, क्लेशों को हरने वाली तथा सम्पूर्ण कल्याणों को करने वाली श्री रामचन्द्रजी की प्रियतमा श्री सीताजी को मैं नमस्कार करता हूँ॥5॥</p>
<p>Meaning:- I bow to Sri Sitaji, the beloved of Shri Ramchandraji, who is the origin, condition (maintenance) and annihilator, the destroyer of troubles and the doer of all welfare.॥5॥</p>
<IonRow>
        <Adsense
                client="ca-pub-4029214824221330"
                slot="2310889447"
                style={{ display: "block" }}
                format="auto"
                responsive="true"
              />
        </IonRow>

<IonImg src="/assets/images/ramcharit/balkand/balkand_s6.jpg" />
<h2><b> यन्मायावशवर्ति विश्वमखिलं ब्रह्मादिदेवासुरा </b></h2>
<h2><b>यत्सत्त्वादमृषैव भाति सकलं रज्जौ यथाहेर्भ्रमः।</b></h2>
<h2><b>यत्पादप्लवमेकमेव हि भवाम्भोधेस्तितीर्षावतां </b></h2>
<h2><b>वन्देऽहं तमशेषकारणपरं रामाख्यमीशं हरिम्‌॥6॥</b></h2>
<p>भावार्थ:-जिनकी माया के वशीभूत सम्पूर्ण विश्व, ब्रह्मादि देवता और असुर हैं, जिनकी सत्ता से रस्सी में सर्प के भ्रम की भाँति यह सारा दृश्य जगत्‌ सत्य ही प्रतीत होता है और जिनके केवल चरण ही भवसागर से तरने की इच्छा वालों के लिए एकमात्र नौका हैं, उन समस्त कारणों से पर (सब कारणों के कारण और सबसे श्रेष्ठ) राम कहलाने वाले भगवान हरि की मैं वंदना करता हूँ॥6॥ </p>
<p>Meaning:- Under whose illusion the whole world, Brahmadi gods and Asuras are, under whose power this whole visible world appears to be the truth like the illusion of a snake in a rope, and whose feet are only the feet of those who wish to cross the Bhavsagar. For all those reasons, I worship Lord Hari, who is called Ram (the best of all reasons). ॥6॥ </p>
<IonImg src="/assets/images/ramcharit/balkand/balkand_s7.jpg" />
<h2><b> नानापुराणनिगमागमसम्मतं यद् </b></h2>
<h2><b>रामायणे निगदितं क्वचिदन्यतोऽपि।</b></h2>
<h2><b>स्वान्तःसुखाय तुलसी रघुनाथगाथा </b></h2>
<h2><b>भाषानिबन्धमतिमंजुलमातनोति॥7॥ </b></h2>
<p>भावार्थ:-अनेक पुराण, वेद और (तंत्र) शास्त्र से सम्मत तथा जो रामायण में वर्णित है और कुछ अन्यत्र से भी उपलब्ध श्री रघुनाथजी की कथा को तुलसीदास अपने अन्तःकरण के सुख के लिए अत्यन्त मनोहर भाषा रचना में विस्तृत करता है॥7॥</p>
<p>Meaning:- According to many Puranas, Vedas and (Tantra) scriptures and which is described in Ramayana and also available from some other places, Tulsidas elaborates the story of Shri Raghunathji in a very beautiful language for the pleasure of his heart.॥7॥</p>
  
                
            </IonCol>
        </IonRow>
                     
        <IonRow><IonCol><IonButton fill="clear" routerLink="/bhagwat">Back To Index Page</IonButton></IonCol><IonCol>दूसरा अध्याय </IonCol></IonRow>

                <IonRow> 
            <IonCol>
              <IonImg src="https://www.hinduamerican.org/wp-content/uploads/2020/06/20120510012-2048x1365.jpg" alt="Shubhasitani"></IonImg>
            </IonCol>
          </IonRow>
        </IonGrid>
        </div>
                </div>
  );
};

export default Ramcharitbalkand;