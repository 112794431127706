import {IonRow,IonCol, IonGrid, IonButton, IonText, IonImg, IonIcon, IonListHeader  } from '@ionic/react';
import {Adsense} from '@ctrl/react-adsense';


import './Mundan.css';


const Mundan: React.FC = () => {
  return (
    <div className="ion-padding">
        <IonGrid fixed ={true}>
        <div className="ion-text-justify">
        <h1> मुंडन या चूड़ाकरण </h1>
        <p> मुंडन, जिसे चौला या चूड़ाकरण भी कहा जाता है, नवजात शिशुओं और छोटे बच्चों के लिए किया जाने वाला एक हिंदू अनुष्ठान है। यह मार्ग का एक संस्कार है जो एक बच्चे के पहले बाल कटवाने का प्रतीक है और इसे हिंदू संस्कृति में एक महत्वपूर्ण अनुष्ठान माना जाता है।</p>
        <p>मुंडन समारोह आमतौर पर तब किया जाता है जब बच्चा 6 महीने और 3 साल की उम्र के बीच होता है। ऐसा माना जाता है कि बच्चे का पहला बाल कटवाना एक महत्वपूर्ण घटना है क्योंकि यह बच्चे के जीवन में एक नए चरण की शुरुआत का प्रतीक है। यह भी माना जाता है कि यह अनुष्ठान बच्चे और परिवार के लिए सौभाग्य और समृद्धि लाता है।</p>
        <p>मुंडन समारोह की शुरुआत पूजा से होती है, जहां देवी-देवताओं को प्रसाद चढ़ाया जाता है और उनका आशीर्वाद लेने के लिए प्रार्थना की जाती है। इसके बाद बच्चे का सिर नाई या परिवार के किसी सदस्य द्वारा मुंडवाया जाता है, जिसे अनुष्ठान में विशेषज्ञ माना जाता है। बालों को फिर कृतज्ञता के प्रतीक के रूप में देवी-देवताओं को अर्पित किया जाता है।</p>
        <p>मुंडन समारोह का आध्यात्मिक महत्व भी माना जाता है। ऐसा माना जाता है कि बच्चे का पहला बाल कटवाना गर्भनाल के कटने और बच्चे के मां से अलग होने का प्रतीक है। यह भी माना जाता है कि काटे गए बाल बच्चे के अतीत का प्रतिनिधित्व करते हैं, और नए बाल जो बढ़ते हैं वह बच्चे के भविष्य का प्रतिनिधित्व करते हैं।</p>
        <p>ऐसा माना जाता है कि मुंडन से बच्चे को स्वास्थ्य लाभ होता है। ऐसा कहा जाता है कि पहला बाल कटवाने से लैनुगो नामक मुलायम बालों को हटाने में मदद मिलती है, जो एक नवजात शिशु के शरीर को ढंकता है, और यह सिर पर घमौरियों और संक्रमण को रोकने में भी मदद कर सकता है।</p>
      <IonListHeader lines="full"></IonListHeader> 
      <IonRow>
      <Adsense
                client="ca-pub-4029214824221330"
                slot="2310889447"
                style={{ display: "block" }}
                format="auto"
                responsive="true"
              />
        </IonRow>
          
      <h1> Mundan or Chudakarana </h1>
       <p> Mundan, also known as chaula or chudakarana, is a Hindu ritual performed for newborn babies and young children. It is a rite of passage that marks the first haircut of a child and is considered to be an important ritual in Hindu culture.</p>
       <p>The mundan ceremony is usually performed when a child is between the ages of 6 months and 3 years. It is believed that the first haircut of a child is an important event as it marks the beginning of a new phase in the child's life. The ritual is also believed to bring good luck and prosperity to the child and the family.</p>
       <p>The mundan ceremony begins with a puja, where offerings are made to the gods and goddesses and prayers are recited to invoke their blessings. The child's head is then shaved by a barber or a family member, who is considered to be an expert in the ritual. The hair is then offered to the gods and goddesses as a symbol of gratitude.</p>
       <p>The mundan ceremony is also believed to have spiritual significance. It is believed that the child's first haircut symbolizes the cutting of the umbilical cord and the child's separation from the mother. It is also believed that the hair that is cut represents the child's past, and the new hair that grows represents the child's future.</p>
       <p>Mundan is also believed to have health benefits for the child. It is said that the first haircut helps to remove the soft hair known as lanugo, which covers a newborn's body, and it can also help to prevent heat rashes and infections on the scalp.</p>
       <p>Mundan is an important ritual that is performed by many Hindu families for their children. It is a way to connect with tradition, culture, and spirituality. It is also an opportunity to give thanks to the divine and to seek blessings for the child's future.</p>
       <IonRow>
        <Adsense
                client="ca-pub-4029214824221330"
                slot="2310889447"
                style={{ display: "block" }}
                format="auto"
                responsive="true"
              />
        </IonRow>
        </div>
        </IonGrid>
        </div>

    
  );
};

export default Mundan;

