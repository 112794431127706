import { IonButtons, IonMenuButton, IonTitle, IonHeader, IonToolbar, IonImg, IonAvatar} from '@ionic/react';
import './Header.css';
import { auth } from '../../config/firebase';

const Header: React.FC = ({ }) => {
  const user = auth.currentUser;
  return (
    <IonHeader>
      <IonToolbar>
        <IonButtons slot="start">
          <IonMenuButton></IonMenuButton>
        </IonButtons>
        <IonTitle>Sanatan Puja</IonTitle>
        <IonButtons slot="end">
          {user?.photoURL && (
            <IonAvatar>
              <IonImg src={user?.photoURL} />
            </IonAvatar>
          )}
        </IonButtons>
      </IonToolbar>
    </IonHeader>
    // <IonHeader  translucent={true}>

    //   <IonToolbar>
    //     <div className="ion-text-center">
    //       <IonGrid><IonRow>
    //         <IonCol><IonThumbnail>
    //           <img alt="Logo" src="/assets/images/logo.jpg" />
    //   </IonThumbnail></IonCol>
    //         <IonCol><h1>Sanatan Puja </h1></IonCol>
    //         <IonCol>aa</IonCol>
    //       </IonRow></IonGrid>
    //     </div>   
    //   </IonToolbar>
    //   <IonToolbar>     
    //     <div className="ion-text-center">
    //         <IonButton fill="clear" routerLink="/home">Home</IonButton>
    //         <IonButton fill="clear" routerLink="/services">Services</IonButton>
    //         <IonButton fill="clear" routerLink="/panchang">Panchang</IonButton>
    //         <IonButton fill="clear" routerLink="/granth">Hamare Granth</IonButton>
    //         <IonButton fill="clear" routerLink="/products">Products</IonButton>
    //         <IonButton fill="clear" routerLink="/contact">Contact Us</IonButton>
            
    //       </div>
          
    //     </IonToolbar>

    // </IonHeader> 
         
   );
};

export default Header;
