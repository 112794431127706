import {IonRow,IonCol, IonGrid, IonButton, IonText, IonImg, IonIcon, IonListHeader  } from '@ionic/react';
import {Adsense} from '@ctrl/react-adsense';
import './Mahalakshmijaap.css';


const Mahalakshmijaap: React.FC = () => {
  return (
    <div className="ion-padding">
        <div className="ion-text-center">
        <IonGrid fixed={true}>
        <IonRow><IonCol><IonImg src="/assets/images/LaxmiMata.jpg" /></IonCol></IonRow>
        <h1><b>|| मां लक्ष्मी के प्रिय मंत्र / श्री लक्ष्मी बीज मन्त्र:||</b></h1>
        <IonText color="tertiary">
        <h1 ><b>"ॐ श्री ह्रीं श्रीं कमले कमलालये प्रसीद श्रीं ह्रीं श्रीं ॐ महालक्ष्मयै नमः।। "</b></h1>
        </IonText>
        <IonListHeader lines="full"></IonListHeader>
        <h1><b>|| लक्ष्मी प्रा​र्थना मंत्र: ||</b></h1>
        <IonText color="danger">
        <h1><b> "नमस्ते सर्वगेवानां वरदासि हरे: प्रिया।"</b></h1>
        <h1><b>या गतिस्त्वत्प्रपन्नानां या सा मे भूयात्वदर्चनात्।।</b></h1>
        </IonText>
        <IonListHeader lines="full"></IonListHeader>
        
        <h2><b>श्री लक्ष्मी महामंत्र:</b></h2>
        <p> <IonText color="danger"><b> ॐ श्रीं ल्कीं महालक्ष्मी महालक्ष्मी एह्येहि सर्व सौभाग्यं देहि मे स्वाहा।।</b></IonText></p>
        <IonListHeader lines="full"></IonListHeader>
        
        <h3><b> माता लक्ष्मी के मंत्र</b></h3>
        <p><IonText color="danger"><b> ॐ श्रींह्रीं श्रीं कमले कमलालये प्रसीद प्रसीद श्रीं ह्रीं श्रीं ॐ महालक्ष्मी नम:।।</b></IonText></p>
        <p><IonText color="danger"><b>ॐ श्रीं ल्कीं महालक्ष्मी महालक्ष्मी एह्येहि सर्व सौभाग्यं देहि मे स्वाहा।।</b></IonText></p>
        <p><IonText color="danger"><b> ॐ ह्रीं श्री क्रीं क्लीं श्री लक्ष्मी मम गृहे धन पूरये, धन पूरये, चिंताएं दूरये-दूरये स्वाहा:।।</b></IonText></p>
        <p><IonText color="danger"><b> ॐ श्रीं ह्रीं क्लीं श्री सिद्ध लक्ष्म्यै नम:।।</b></IonText></p>
        <p><IonText color="danger"><b> पद्मानने पद्म पद्माक्ष्मी पद्म संभवे तन्मे भजसि पद्माक्षि येन सौख्यं लभाम्यहम् ।।</b></IonText></p>
        <p> <IonText color="danger"><b> ऊं ह्रीं त्रिं हुं फट।।</b></IonText></p>
        <h5>उपर्युक्त मंत्र से मां लक्ष्मी की कृपा हम पर बरसेगी। मंत्र जाप से जीवन में खुशहाली, धन, वैभव और समृद्धि का आगमन होता है। इन मंत्रों के जाप से जीवन में धन की कमी पूरी होती है। इन मंत्रों को जपने से धन की कमी दूर होती है।</h5>
        <h5>The blessings of Maa Lakshmi will be poured out upon us in the current mantra. Chanting mantra brings good luck, wealth, glory and prosperity in life. Chanting of these mantras fulfills the lack of money in life. Chanting these mantras removes the lack of money.</h5>
        <IonRow>
        <Adsense
                client="ca-pub-4029214824221330"
                slot="2310889447"
                style={{ display: "block" }}
                format="auto"
                responsive="true"
              />
        </IonRow>
        
        <IonListHeader lines="full"></IonListHeader>
        <h4>सप्ताह के हर सात दिन किसी न किसी भगवान को समर्पित होते हैं। इसी तरह शुक्रवार का दिन मां लक्ष्मी को समर्पित होता है। उस दिन मां लक्ष्मी की पूजा से लोगों के जीवन में धन की कमी दूर हो जाती है। यह घर में सौभाग्य, शांति और समृद्धि लाता है। धार्मिक मान्यताओं के अनुसार मां लक्ष्मी को धन और ऐश्वर्य की देवी बताया गया है। मां लक्ष्मी को प्रसन्न करने के लिए कई मंत्रों का जाप किया जाता है। आज शुक्रवार हम आपके साथ मां लक्ष्मी को प्रसन्न करने के लिए उनके कुछ प्रभावी मंत्र साझा कर रहे हैं।</h4>
        <h4>Every seven days of the week are devoted to one god or another. In the same way, Friday is devoted to Maa Lakshmi. On that day, the worship of the goddess Lakshmi eliminated the lack of money in the lives of people. It brings good fortune, peace and prosperity to the home. In accordance with religious beliefs, Maa Lakshmi has been described as the goddess of wealth and prosperity. Lots of mantras are chanted in order to please Maa Lakshmi. Today, Friday, we share with you some of her effective mantras to please the goddess Lakshmi.</h4>
        <IonListHeader lines="full"></IonListHeader>
        <h3>मंत्रानुष्ठान के लिए शास्त्र के विधान का पालन करना परम आवश्यक है, अन्यथा लाभ के बदले हानि की संभावना अधिक रहती है। इसलिए यह आवश्यक है कि प्रत्येक कार्य शास्त्रसम्मत ही किया जाए। इस हेतु किसी योग्य और विद्वान व्यक्ति का मार्गदर्शन लेना चाहिए।</h3>
        <h3>यदि साधक पूर्ण श्रद्धा और विश्वास के साथ यह साधना करें, तो वांछित फल की प्राप्ति की प्रबल संभावना रहती है।</h3>
        <h3>शास्त्रों में अलग-अलग कार्यों के लिए अलग-अलग संख्याओं में श्री लक्ष्मी महामंत्र: के जप का विधान है। इसमें किस कार्य के लिए कितनी संख्या में जप करना चाहिए इसका उल्लेख किया गया है।</h3>
        <IonListHeader lines="full"></IonListHeader>
        <h4># भय से छुटकारा पाने के लिए 1100 मंत्र का जप किया जाता है।</h4>
        <h4># रोगों से मुक्ति के लिए 11000 मंत्रों का जप किया जाता है।</h4>
        <h4># पुत्र की प्राप्ति के लिए, मनोवांछित नौकरी के लिए, उन्नति के लिए, मृत्यु से बचने के लिए सवा लाख की संख्या में मंत्र जप करना अनिवार्य है।</h4>
        <IonListHeader lines="full"></IonListHeader>
        <h3>All of us at <b>[Sanatan Puja]</b> would love to do Jaap of <IonText color="danger"><b> "Maha Laxmi Mantra" </b></IonText> for everyone who need it, and for you to use this as a tool that will empower you to reach your goals in life. You can contact us by clicking the link given below.</h3>
        <IonText color="success">
        <h3><i><b>We are providing this service at very nominal Dakshina.</b></i></h3>
        </IonText>
        <IonRow><IonCol><IonButton routerLink="/contact">Contact Us for More Details</IonButton></IonCol></IonRow>
        </IonGrid>
        </div>
</div>
  );
};

export default Mahalakshmijaap;

