import { IonGrid, IonRow, IonListHeader, IonIcon, IonCol, IonButton } from '@ionic/react';
import {Adsense} from '@ctrl/react-adsense';

import './Panchang.css';


const Panchang: React.FC = () => {
    return (
        <div className="ion-padding">
      <IonGrid fixed={true}>
        <h1>Panchang, पंचांग</h1>
        <IonListHeader lines='full'></IonListHeader>
          <h2>हिन्दू कैलेंडर जनवरी, 2023 - व्रत एवं त्यौहार</h2>
          <h3>हिन्दू कैलेंडर के अनुसार, जनवरी 2023 में आने वाली पर्व एवं छुट्टियाँ की सूची। </h3>
          <h3> 2023 जनवरी हिन्दू त्योहार, व्रत एवं महत्वपूर्ण तिथियाँ| </h3>
          <IonRow>01 Sun	नव वर्ष </IonRow>
          <IonRow>02 Mon	प्रकृति दिन , वैकुंठ एकादशी , पौष पुत्रदा एकादशी</IonRow>
          <IonRow>03 Tue	कूर्म द्वादशी व्रत</IonRow>
          <IonRow>04 Wed	प्रदोष व्रत , रोहिणी व्रत </IonRow>
          <IonRow>06 Fri	पूर्णिमा , माघस्नान प्रारंभ , सत्य व्रत , पूर्णिमा व्रत , सत्य व्रत , पौष पूर्णिमा </IonRow>
          <IonRow>10 Tue	सकट चौथ , संकष्टी गणेश चतुर्थी </IonRow>
          <IonRow>12 Thu	राष्ट्रीय युवा दिवस </IonRow>
          <IonRow>14 Sat	पोंगल , स्वामी विवेकानंद जयंती , लोहड़ी (लोहरी) , मकर संक्रांति </IonRow>
          <IonRow>15 Sun	कालाष्टमी , गंगा सागर स्नान </IonRow>
          <IonRow>18 Wed	षटतिला एकादशी </IonRow>
          <IonRow>19 Thu	प्रदोष व्रत </IonRow>
          <IonRow>20 Fri	मास शिवरात्रि , गुरु गोबिंदसिंह जयंती </IonRow>
          <IonRow>21 Sat	मौनी अमावस्या , अमावस्या </IonRow>
          <IonRow>22 Sun	माघ गुप्त नवरात्रि , चंद्र दर्शन , शिशिर ऋतू </IonRow>
          <IonRow>23 Mon	सोमवार व्रत </IonRow>
          <IonRow>24 Tue	गणेश जयंती </IonRow>
          <IonRow>25 Wed	वरद चतुर्थी </IonRow>
          <IonRow>26 Thu	बसंत पंचमी , गणतंत्र दिवस , षष्टी </IonRow>
          <IonRow>28 Sat	भीष्माष्टमी , रथ सप्तमी </IonRow>
          <IonRow>29 Sun	दुर्गाष्टमी व्रत , महानन्दा नवमी </IonRow>
          <IonRow>30 Mon	गांधी पुण्यतिथि </IonRow>
          <IonRow>31 Tue	रोहिणी व्रत </IonRow>
          <IonListHeader lines='full'></IonListHeader>
          <IonRow>
        <Adsense
                client="ca-pub-4029214824221330"
                slot="2310889447"
                style={{ display: "block" }}
                format="auto"
                responsive="true"
              />
        </IonRow>
          <h2>हिन्दू कैलेंडर फरवरी, 2023 - व्रत एवं त्यौहार</h2>
          <h3>हिन्दू कैलेंडर के अनुसार, फरवरी 2023 में आने वाली पर्व एवं छुट्टियाँ की सूची। </h3> 
          <h3> 2023 फरवरी हिन्दू त्योहार, व्रत एवं महत्वपूर्ण तिथियाँ| </h3>
          <IonRow>01 Wed	जाया एकादशी </IonRow>
          <IonRow>02 Thu	प्रदोष व्रत </IonRow>
          <IonRow>05 Sun	माघ पूर्णिमा , रविदास जयंती , पूर्णिमा , सत्य व्रत , सत्य व्रत , पूर्णिमा व्रत , माघस्नान समाप्त </IonRow>
          <IonRow>09 Thu	संकष्टी गणेश चतुर्थी </IonRow>
          <IonRow>13 Mon	कालाष्टमी , कुंभ संक्रांति </IonRow>
          <IonRow>14 Tue	श्री रामदास नवमी , वैलेंटाइन्स डे </IonRow>
          <IonRow>18 Sat	महाशिवरात्रि , मास शिवरात्रि , प्रदोष व्रत </IonRow>
          <IonRow>19 Sun	शिवजी जयंती </IonRow>
          <IonRow>20 Mon	अमावस्या , सोमवार व्रत </IonRow>
          <IonRow>21 Tue	चंद्र दर्शन , फुलेरा दूज , रामकृष्ण जयंती </IonRow>
          <IonRow>23 Thu	वरद चतुर्थी </IonRow>
          <IonRow>25 Sat	षष्टी </IonRow>
          <IonRow>27 Mon	होलाष्टक , दुर्गाष्टमी व्रत </IonRow>
          <IonRow>28 Tue	रोहिणी व्रत , राष्ट्रीय विज्ञान दिवस </IonRow>
          <IonListHeader lines='full'></IonListHeader>
          <h2>हिन्दू कैलेंडर मार्च, 2023 - व्रत एवं त्यौहार</h2>
          <h3>हिन्दू कैलेंडर के अनुसार, मार्च 2023 में आने वाली पर्व एवं छुट्टियाँ की सूची। </h3> 
          <h3> 2023 मार्च हिन्दू त्योहार, व्रत एवं महत्वपूर्ण तिथियाँ| </h3>
          <IonRow>03 Fri	आमलकी एकादशी , गोविंद द्वादशी</IonRow>
          <IonRow>04 Sat	प्रदोष व्रत </IonRow>
          <IonRow>07 Tue	सत्य व्रत , सत्य व्रत , पूर्णिमा व्रत , पूर्णिमा , होलिका दहन , चैतन्य महाप्रभु जयंती , होलाष्टक समाप्त </IonRow>
          <IonRow>08 Wed	अन्तरराष्ट्रीय महिला दिवस , गणगौर व्रत प्रारंभ , होली </IonRow>
          <IonRow>10 Fri	शिवजी जयंती </IonRow>
          <IonRow>11 Sat	संकष्टी गणेश चतुर्थी </IonRow>
          <IonRow>12 Sun	रंग पंचमी </IonRow>
          <IonRow>14 Tue	शीतला सप्तमी </IonRow>
          <IonRow>15 Wed	बुधाष्टमी व्रत , मीन संक्रांति , अंतरराष्ट्रीय उपभोक्ता दिवस , कालाष्टमी , शीतला अष्टमी </IonRow>
          <IonRow>18 Sat	पापमोचनी एकादशी </IonRow>
          <IonRow>19 Sun	मधु कृष्ण त्रयोदशी , रंग तेरस , प्रदोष व्रत </IonRow>
          <IonRow>20 Mon	मास शिवरात्रि </IonRow>
          <IonRow>21 Tue	भौमवती अमावस्या , अमावस्या </IonRow>
          <IonRow>22 Wed	चंद्र दर्शन , गुड़ी पड़वा , वसंत ऋतू , हिन्दू नव वर्ष , चैत्र नवरात्री , चेटीचंड </IonRow>
          <IonRow>23 Thu	झूलेलाल जयंती , रमजान उपवास शुरु </IonRow>
          <IonRow>24 Fri	मत्स्य जयंती , गौरी पूजा (गणगौर पूजा) </IonRow>
          <IonRow>25 Sat	वरद चतुर्थी </IonRow>
          <IonRow>27 Mon	सोमवार व्रत , षष्टी , रोहिणी व्रत , यमुना छठ </IonRow>
          <IonRow>29 Wed	अशोक अष्टमी , दुर्गाष्टमी व्रत , बुधाष्टमी व्रत </IonRow>
          <IonRow>30 Thu	श्री महातारा जयंती , स्वामीनारायण जयंती , राम नवमी </IonRow>
          <IonListHeader lines='full'></IonListHeader>
          <h2>हिन्दू कैलेंडर अप्रैल, 2023 - व्रत एवं त्यौहार</h2>
          <h3>हिन्दू कैलेंडर के अनुसार, अप्रैल 2023 में आने वाली पर्व एवं छुट्टियाँ की सूची। </h3> 
          <h3> 2023 अप्रैल हिन्दू त्योहार, व्रत एवं महत्वपूर्ण तिथियाँ| </h3>
          <IonRow>01 Sat	वैष्णव कामदा एकादशी , कामदा एकादशी , अप्रैल फूल दिवस , वित्तीय वर्ष प्रारंभ</IonRow>
          <IonRow>02 Sun	खजूर रविवार (पाम संडे) </IonRow>
          <IonRow>03 Mon	सोम प्रदोष व्रत , प्रदोष व्रत </IonRow>
          <IonRow>04 Tue	महावीर जयंती </IonRow>
          <IonRow>05 Wed	सत्य व्रत , सत्य व्रत , पूर्णिमा व्रत , बाबु जगजीवन राम जयंती </IonRow>
          <IonRow>06 Thu	हनुमान जयंती , पुण्य बृहस्पतिवार , पूर्णिमा </IonRow>
          <IonRow>07 Fri	विश्व स्वास्थ्य दिवस , गुड फ़्राइडे </IonRow>
          <IonRow>09 Sun	संकष्टी गणेश चतुर्थी , ईस्टर </IonRow>
          <IonRow>13 Thu	कालाष्टमी </IonRow>
          <IonRow>14 Fri	आंबेडकर जयंती , बैसाखी , बंगाली नव वर्ष , मेष संक्रांति </IonRow>
          <IonRow>16 Sun	वरुथिनी एकादशी , वल्लभाचार्य जयंती </IonRow>
          <IonRow>17 Mon	सोम प्रदोष व्रत , प्रदोष व्रत </IonRow>
          <IonRow>18 Tue	मास शिवरात्रि </IonRow>
          <IonRow>20 Thu	अमावस्या </IonRow>
          <IonRow>21 Fri	चंद्र दर्शन , जमात-उल-विदा </IonRow>
          <IonRow>22 Sat	परशुराम जयंती , अक्षय तृतीय , रमज़ान , पृथ्वी दिवस </IonRow>
          <IonRow>23 Sun	वरद चतुर्थी , रोहिणी व्रत , मातंगी जयंती </IonRow>
          <IonRow>24 Mon	सोमवार व्रत </IonRow>
          <IonRow>25 Tue	सूरदास जयंती </IonRow>
          <IonRow>26 Wed	षष्टी </IonRow>
          <IonRow>27 Thu	गंगा सप्तमी </IonRow>
          <IonRow>28 Fri	दुर्गाष्टमी व्रत , बगलामुखी जयंती </IonRow>
          <IonRow>29 Sat	सीता नवमी </IonRow>
          <IonListHeader lines='full'></IonListHeader>
          <h2>हिन्दू कैलेंडर मई, 2023 - व्रत एवं त्यौहार</h2>
          <h3>हिन्दू कैलेंडर के अनुसार, मई 2023 में आने वाली पर्व एवं छुट्टियाँ की सूची। </h3> 
          <h3> 2023 मई हिन्दू त्योहार, व्रत एवं महत्वपूर्ण तिथियाँ| </h3>
          <IonRow>01 Mon	मोहिनी एकादशी , महाराष्ट्र दिवस , मई दिवस </IonRow>
          <IonRow>02 Tue	परशुराम द्वादशी </IonRow>
          <IonRow>03 Wed	प्रदोष व्रत </IonRow>
          <IonRow>04 Thu	नृसिंह जयंती </IonRow>
          <IonRow>05 Fri	पूर्णिमा , सत्य व्रत , कूर्म जयंती , बुद्ध पूर्णिमा (बुद्ध जयंती) , चैत्र पूर्णिमा , पूर्णिमा व्रत , सत्य व्रत </IonRow>
          <IonRow>06 Sat	नारद जयंती </IonRow>
          <IonRow>07 Sun	रबीन्द्रनाथ टैगोर जयंती </IonRow>
          <IonRow>08 Mon	संकष्टी गणेश चतुर्थी </IonRow>
          <IonRow>12 Fri	कालाष्टमी </IonRow>
          <IonRow>14 Sun	मातृ दिवस </IonRow>
          <IonRow>15 Mon	वृषभ संक्रांति , अपरा एकादशी , भद्रकाली जयंती </IonRow>
          <IonRow>17 Wed	मास शिवरात्रि , प्रदोष व्रत </IonRow>
          <IonRow>19 Fri	वट सावित्री व्रत , अमावस्या , शनि जयंती </IonRow>
          <IonRow>20 Sat	चंद्र दर्शन , ग्रीष्म ऋतू </IonRow>
          <IonRow>21 Sun	रोहिणी व्रत </IonRow>
          <IonRow>22 Mon	महाराणा प्रताप जयंती , सोमवार व्रत </IonRow>
          <IonRow>23 Tue	वरद चतुर्थी </IonRow>
          <IonRow>25 Thu	षष्टी </IonRow>
          <IonRow>26 Fri	शीतला षष्टी </IonRow>
          <IonRow>28 Sun	धूमावती जयंती , दुर्गाष्टमी व्रत , वृषभ व्रत </IonRow>
          <IonRow>29 Mon	महेश नवमी </IonRow>
          <IonRow>30 Tue	गंगा दशहरा </IonRow>
          <IonRow>31 Wed	निर्जला एकादशी </IonRow>
          <IonListHeader lines='full'></IonListHeader>
          <h2>हिन्दू कैलेंडर जून, 2023 - व्रत एवं त्यौहार</h2>
          <h3>हिन्दू कैलेंडर के अनुसार, जून 2023 में आने वाली पर्व एवं छुट्टियाँ की सूची। </h3> 
          <h3> 2023 जून हिन्दू त्योहार, व्रत एवं महत्वपूर्ण तिथियाँ| </h3>
          <IonRow>01 Thu	प्रदोष व्रत </IonRow>
          <IonRow>03 Sat	वैट सावित्री पूर्णिमा , सत्य व्रत , पूर्णिमा व्रत , सत्य व्रत </IonRow>
          <IonRow>04 Sun	कबीर जयंती , देव स्नान पूर्णिमा , पूर्णिमा </IonRow>
          <IonRow>05 Mon	विश्व पर्यावरण दिवस </IonRow>
          <IonRow>07 Wed	संकष्टी गणेश चतुर्थी </IonRow>
          <IonRow>10 Sat	कालाष्टमी </IonRow>
          <IonRow>14 Wed	योगिनी एकादशी </IonRow>
          <IonRow>15 Thu	प्रदोष व्रत , मिथुन संक्रांति </IonRow>
          <IonRow>16 Fri	मास शिवरात्रि </IonRow>
          <IonRow>17 Sat	रोहिणी व्रत </IonRow>
          <IonRow>18 Sun	अमावस्या , पितृ दिवस </IonRow>
          <IonRow>19 Mon	सोमवार व्रत , चंद्र दर्शन , गुप्त नवरात्र प्रारंभ </IonRow>
          <IonRow>20 Tue	पूरी जगन्नाथ रथ यात्रा </IonRow>
          <IonRow>22 Thu	वरद चतुर्थी </IonRow>
          <IonRow>24 Sat	कौमार षष्ठी , षष्टी </IonRow>
          <IonRow>26 Mon	दुर्गाष्टमी व्रत </IonRow>
          <IonRow>29 Thu	आषाढ़ी एकादशी , बकरीद (ईद-उल-अज़हा) </IonRow>
          <IonListHeader lines='full'></IonListHeader>
          <h2>हिन्दू कैलेंडर जुलाई, 2023 - व्रत एवं त्यौहार</h2>
          <h3>हिन्दू कैलेंडर के अनुसार, जुलाई 2023 में आने वाली पर्व एवं छुट्टियाँ की सूची। </h3> 
          <h3> 2023 जुलाई हिन्दू त्योहार, व्रत एवं महत्वपूर्ण तिथियाँ| </h3>
          <IonRow>01 Sat	प्रदोष व्रत , जाया पार्वती व्रत प्रारंभ </IonRow>
          <IonRow>03 Mon	पूर्णिमा व्रत , संत थॉमस डे , व्यास पूजा , पूर्णिमा , सत्य व्रत , गुरु पूर्णिमा , सत्य व्रत</IonRow>
          <IonRow>04 Tue	कांवड़ यात्रा </IonRow>
          <IonRow>05 Wed	जाया पारवती व्रत जागरण </IonRow>
          <IonRow>06 Thu	जाया पार्वती व्रत समाप्त , संकष्टी गणेश चतुर्थी </IonRow>
          <IonRow>09 Sun	कालाष्टमी </IonRow>
          <IonRow>11 Tue	जनसंख्या दिवस </IonRow>
          <IonRow>13 Thu	वैष्णव कामिक एकादशी , कामिका एकादशी </IonRow>
          <IonRow>14 Fri	रोहिणी व्रत </IonRow>
          <IonRow>15 Sat	प्रदोष व्रत , मास शिवरात्रि </IonRow>
          <IonRow>16 Sun	कर्क संक्रांति </IonRow>
          <IonRow>17 Mon	हरियाली अमावस्या, अमावस्या, सोमवार व्रत </IonRow>
          <IonRow>18 Tue	अधिक मास , वर्ष ऋतू </IonRow>
          <IonRow>19 Wed	चंद्र दर्शन , इस्लामी नव वर्ष </IonRow>
          <IonRow>21 Fri	वरद चतुर्थी </IonRow>
          <IonRow>24 Mon	षष्टी </IonRow>
          <IonRow>26 Wed	दुर्गाष्टमी व्रत </IonRow>
          <IonRow>29 Sat	आशुरा के दिन , पद्मिनी एकादशी </IonRow>
          <IonRow>30 Sun	प्रदोष व्रत </IonRow>
          <IonListHeader lines='full'></IonListHeader>
          <h2>हिन्दू कैलेंडर अगस्त, 2023 - व्रत एवं त्यौहार</h2>
          <h3>हिन्दू कैलेंडर के अनुसार, अगस्त 2023 में आने वाली पर्व एवं छुट्टियाँ की सूची। </h3> 
          <h3> 2023 अगस्त हिन्दू त्योहार, व्रत एवं महत्वपूर्ण तिथियाँ| </h3>
          <IonRow>01 Tue	पूर्णिमा व्रत , सत्य व्रत , पूर्णिमा , सत्य व्रत </IonRow>
          <IonRow>04 Fri	संकष्टी गणेश चतुर्थी </IonRow>
          <IonRow>06 Sun	हिरोशिमा दिवस , मित्रता दिवस </IonRow>
          <IonRow>08 Tue	कालाष्टमी </IonRow>
          <IonRow>10 Thu	रोहिणी व्रत </IonRow>
          <IonRow>12 Sat	परमा एकादशी </IonRow>
          <IonRow>13 Sun	प्रदोष व्रत </IonRow>
          <IonRow>14 Mon	मास शिवरात्रि </IonRow>
          <IonRow>15 Tue	स्वतंत्रता दिवस </IonRow>
          <IonRow>16 Wed	अमावस्या </IonRow>
          <IonRow>17 Thu	चंद्र दर्शन , सिंह संक्रांति , मुहर्रम समाप्त </IonRow>
          <IonRow>19 Sat	वर्ल्ड फोटोग्राफी दिवस , हरियाली तीज </IonRow>
          <IonRow>20 Sun	वरद चतुर्थी </IonRow>
          <IonRow>21 Mon	नाग पंचमी , सोमवार व्रत </IonRow>
          <IonRow>22 Tue	षष्टी </IonRow>
          <IonRow>23 Wed	तुलसीदास जयंती </IonRow>
          <IonRow>24 Thu	दुर्गाष्टमी व्रत </IonRow>
          <IonRow>25 Fri	वरलक्ष्मी व्रत </IonRow>
          <IonRow>27 Sun	श्रावण पुत्रदा एकादशी </IonRow>
          <IonRow>28 Mon	प्रदोष व्रत , सोम प्रदोष व्रत </IonRow>
          <IonRow>29 Tue	ओणम </IonRow>
          <IonRow>30 Wed	सत्य व्रत , पूर्णिमा व्रत , सत्य व्रत , रक्षा बंधन </IonRow>
          <IonRow>31 Thu	नराली पूर्णिमा , पूर्णिमा </IonRow>
          <IonListHeader lines='full'></IonListHeader>
          <h2>हिन्दू कैलेंडर सितम्बर, 2023 - व्रत एवं त्यौहार</h2>
          <h3>हिन्दू कैलेंडर के अनुसार, सितम्बर 2023 में आने वाली पर्व एवं छुट्टियाँ की सूची। </h3> 
          <h3> 2023 सितम्बर हिन्दू त्योहार, व्रत एवं महत्वपूर्ण तिथियाँ| </h3>
          <IonRow>02 Sat	हेरम्ब संकष्टी चतुर्थी , कजरी तीज , संकष्टी गणेश चतुर्थी </IonRow>
          <IonRow>04 Mon	रक्षा पंचमी </IonRow>
          <IonRow>05 Tue	हल षष्ठी , शिक्षक दिवस </IonRow>
          <IonRow>06 Wed	अरबईन , बुधाष्टमी व्रत , कालाष्टमी </IonRow>
          <IonRow>07 Thu	श्रीकृष्ण जन्माष्टमी , रोहिणी व्रत </IonRow>
          <IonRow>08 Fri	गोगा नवमी </IonRow>
          <IonRow>10 Sun	अजा एकादशी , वैष्णव आज एकादशी </IonRow>
          <IonRow>12 Tue	भौम प्रदोष व्रत , प्रदोष व्रत </IonRow>
          <IonRow>13 Wed	मास शिवरात्रि </IonRow>
          <IonRow>14 Thu	राष्ट्रीय भाषा दिवस , पिठौरी अमावस्या , अमावस्या </IonRow>
          <IonRow>16 Sat	चंद्र दर्शन </IonRow>
          <IonRow>17 Sun	वाराह जयंती , कन्या संक्रांति , विश्वकर्मा जयंती </IonRow>
          <IonRow>18 Mon	सोमवार व्रत , हरतालिका तीज </IonRow>
          <IonRow>19 Tue	वरद चतुर्थी , गणेशोत्सव </IonRow>
          <IonRow>20 Wed	ऋषि पंचमी </IonRow>
          <IonRow>21 Thu	षष्टी </IonRow>
          <IonRow>22 Fri	दूर्वा अष्टमी , महालक्ष्मी व्रत प्रारंभ </IonRow>
          <IonRow>23 Sat	दुर्गाष्टमी व्रत , राधाष्टमी </IonRow>
          <IonRow>25 Mon	पार्ष्व एकादशी </IonRow>
          <IonRow>26 Tue	वैष्णव पार्ष्व एकादशी </IonRow>
          <IonRow>27 Wed	विश्व पर्यटन दिवस , प्रदोष व्रत </IonRow>
          <IonRow>28 Thu	मीलाद उन-नबी , गणेश विसर्जन </IonRow>
          <IonRow>29 Fri	प्रतिपदा श्राद्ध , भाद्रपद पूर्णिमा , सत्य व्रत , महालय श्राद्घ पक्ष , पूर्णिमा व्रत , पूर्णिमा , सत्य व्रत </IonRow>
          <IonListHeader lines='full'></IonListHeader>
          <h2>हिन्दू कैलेंडर अक्टूबर, 2023 - व्रत एवं त्यौहार</h2>
          <h3>हिन्दू कैलेंडर के अनुसार, अक्टूबर 2023 में आने वाली पर्व एवं छुट्टियाँ की सूची। </h3> 
          <h3> 2023 अक्टूबर हिन्दू त्योहार, व्रत एवं महत्वपूर्ण तिथियाँ| </h3>
          <IonRow>02 Mon	भरणी श्रद्धा , संकष्टी गणेश चतुर्थी , गाँधी जयंती </IonRow>
          <IonRow>04 Wed	रोहिणी व्रत , विश्व पशु दिवस </IonRow>
          <IonRow>06 Fri	श्रीमहालक्ष्मी व्रत समाप्त , मध्य अष्टमी , कालाष्टमी </IonRow>
          <IonRow>07 Sat	अविधवा नवमी </IonRow>
          <IonRow>10 Tue	माघ श्रद्धा , इंदिरा एकादशी </IonRow>
          <IonRow>11 Wed	प्रदोष व्रत </IonRow>
          <IonRow>12 Thu	मास शिवरात्रि </IonRow>
          <IonRow>14 Sat	अमावस्या , महालय श्राद्घ पक्ष पूर्ण </IonRow>
          <IonRow>15 Sun	अग्रसेन जयंती , शरद ऋतू , नवरात्री </IonRow>
          <IonRow>16 Mon	चंद्र दर्शन , सोमवार व्रत , सिंधारा दूज </IonRow>
          <IonRow>18 Wed	वरद चतुर्थी , तुला संक्रांति </IonRow>
          <IonRow>19 Thu	ललित पंचमी </IonRow>
          <IonRow>20 Fri	षष्टी , सरस्वती आवाहन </IonRow>
          <IonRow>21 Sat	सरस्वती पूजा , दुर्गा पूजा </IonRow>
          <IonRow>22 Sun	सरस्वती विसर्जन , सरस्वती बलिदान , दुर्गाष्टमी , दुर्गाष्टमी व्रत </IonRow>
          <IonRow>23 Mon	महा नवमी </IonRow>
          <IonRow>24 Tue	विजय दशमी </IonRow>
          <IonRow>25 Wed	भारत मिलाप , पापांकुशा एकादशी </IonRow>
          <IonRow>26 Thu	प्रदोष व्रत </IonRow>
          <IonRow>28 Sat	पूर्णिमा , पूर्णिमा व्रत , शरद पूर्णिमा , वाल्मीकि जयंती , सत्य व्रत , कार्तिक स्नान , काजोगरा पूजा , सत्य व्रत </IonRow>
          <IonRow>31 Tue	रोहिणी व्रत </IonRow>
          <IonListHeader lines='full'></IonListHeader>
          <h2>हिन्दू कैलेंडर नवम्बर, 2023 - व्रत एवं त्यौहार</h2>
          <h3>हिन्दू कैलेंडर के अनुसार, नवम्बर 2023 में आने वाली पर्व एवं छुट्टियाँ की सूची। </h3> 
          <h3> 2023 नवम्बर हिन्दू त्योहार, व्रत एवं महत्वपूर्ण तिथियाँ| </h3>
          <IonRow>01 Wed	करवा चौथ , संकष्टी गणेश चतुर्थी </IonRow>
          <IonRow>05 Sun	अहोई अष्टमी , कालाष्टमी </IonRow>
          <IonRow>09 Thu	गोवत्स द्वादशी , वैष्णव रामा एकादशी , रामा एकादशी </IonRow>
          <IonRow>10 Fri	प्रदोष व्रत , धनतेरस </IonRow>
          <IonRow>11 Sat	मास शिवरात्रि , काली चौदस </IonRow>
          <IonRow>12 Sun	दिवाली , नरक चतुर्दशी </IonRow>
          <IonRow>13 Mon	अमावस्या , सोमवार व्रत </IonRow>
          <IonRow>14 Tue	चंद्र दर्शन , अन्नकूट , गोवर्धन पूजा </IonRow>
          <IonRow>15 Wed	भाई दूज </IonRow>
          <IonRow>16 Thu	वरद चतुर्थी </IonRow>
          <IonRow>17 Fri	वृश्चिक संक्रांति </IonRow>
          <IonRow>18 Sat	लाभ पंचमी , षष्टी </IonRow>
          <IonRow>19 Sun	छठ पूजा </IonRow>
          <IonRow>20 Mon	गोपाष्टमी , दुर्गाष्टमी व्रत </IonRow>
          <IonRow>21 Tue	अक्षय नवमी </IonRow>
          <IonRow>22 Wed	कंस वध </IonRow>
          <IonRow>23 Thu	प्रबोधिनी एकादशी </IonRow>
          <IonRow>24 Fri	तुलसी विवाह </IonRow>
          <IonRow>25 Sat	प्रदोष व्रत , विश्वेश्वर व्रत </IonRow>
          <IonRow>26 Sun	मणिकर्णिका स्नान , देव दिवाली </IonRow>
          <IonRow>27 Mon	कार्तिक पूर्णिमा , गुरु नानक जयंती , कार्तिक स्नान समाप्त , सत्य व्रत , सत्य व्रत , पूर्णिमा , पूर्णिमा व्रत </IonRow>
          <IonRow>28 Tue	रोहिणी व्रत </IonRow>
          <IonRow>30 Thu	झंडा दिवस , सौभाग्य सुंदरी तीज , संकष्टी गणेश चतुर्थी </IonRow>
          <IonListHeader lines='full'></IonListHeader>
          <h2>हिन्दू कैलेंडर दिसम्बर, 2023 - व्रत एवं त्यौहार</h2>
          <h3>हिन्दू कैलेंडर के अनुसार, दिसम्बर 2023 में आने वाली पर्व एवं छुट्टियाँ की सूची। </h3> 
          <h3> 2023 दिसम्बर हिन्दू त्योहार, व्रत एवं महत्वपूर्ण तिथियाँ| </h3>
          <IonRow>01 Fri	विश्व एड्स दिवस </IonRow>
          <IonRow>04 Mon	भारतीय नौसेना दिवस </IonRow>
          <IonRow>05 Tue	कालभैरव जयंती , कालाष्टमी </IonRow>
          <IonRow>08 Fri	उत्पन्न एकादशी </IonRow>
          <IonRow>10 Sun	प्रदोष व्रत </IonRow>
          <IonRow>11 Mon	मास शिवरात्रि </IonRow>
          <IonRow>12 Tue	भौमवती अमावस्या , गौरी तपो व्रत , अमावस्या </IonRow>
          <IonRow>13 Wed	हेमंत ऋतू </IonRow>
          <IonRow>14 Thu	चंद्र दर्शन </IonRow>
          <IonRow>16 Sat	वरद चतुर्थी , धनु संक्रांति </IonRow>
          <IonRow>17 Sun	विवाह पंचमी </IonRow>
          <IonRow>18 Mon	षष्टी , सोमवार व्रत </IonRow>
          <IonRow>20 Wed	दुर्गाष्टमी व्रत </IonRow>
          <IonRow>22 Fri	मोक्षदा एकादशी , गीता जयंती </IonRow>
          <IonRow>23 Sat	वैकुंठ एकादशी </IonRow>
          <IonRow>24 Sun	अनंग त्रयोदशी व्रत , प्रदोष व्रत , राष्ट्रीय उपभोक्ता दिवस </IonRow>
          <IonRow>25 Mon	मदन मोहन मालवीय जयंती , रोहिणी व्रत , क्रिसमस </IonRow>
          <IonRow>26 Tue	सत्य व्रत , सत्य व्रत , पूर्णिमा , अन्नपूर्णा जयंती , मार्गशीर्ष पूर्णिमा , पूर्णिमा व्रत </IonRow>
          <IonRow>30 Sat	संकष्टी गणेश चतुर्थी </IonRow>
</IonGrid>
</div>
);
};
export default Panchang;
