import { IonGrid, IonRow, IonCol} from '@ionic/react';
import './Rudravishek.css';


const Rudravishek: React.FC = () => {
  return (
      <div className="ion-padding">
      <h1>Rudrabhishek</h1>
      <h2>What is Rudrabhishek puja?</h2>
      <p>Rudrabhishek puja is a supreme ritual. It is considered to be one of the purest rituals in Hinduism. Rudrabhishek puja is performed by giving a sacred bath to Lord Shiva along with flowers and the holy puja materials.</p>
      <p>What ingredients are needed for Rudrabhishek puja</p>
      <IonGrid>
          <IonRow><IonCol>1</IonCol><IonCol>Flowers</IonCol><IonCol>Buy Link</IonCol></IonRow>
          <IonRow><IonCol>2</IonCol><IonCol>Incense sticks</IonCol><IonCol>Buy Link</IonCol></IonRow>
          <IonRow><IonCol>3</IonCol><IonCol>Ghee</IonCol><IonCol>Buy Link</IonCol></IonRow>
          <IonRow><IonCol>4</IonCol><IonCol>Curd</IonCol><IonCol>Buy Link</IonCol></IonRow>
          <IonRow><IonCol>5</IonCol><IonCol>Honey</IonCol><IonCol>Buy Link</IonCol></IonRow>
          <IonRow><IonCol>6</IonCol><IonCol>Fresh milk</IonCol><IonCol>Buy Link</IonCol></IonRow>
          <IonRow><IonCol>7</IonCol><IonCol>Panchamrit</IonCol><IonCol>Buy Link</IonCol></IonRow>
          <IonRow><IonCol>8</IonCol><IonCol>Rose water</IonCol><IonCol>Buy Link</IonCol></IonRow>
          <IonRow><IonCol>9</IonCol><IonCol>Sweets(Mithai)</IonCol><IonCol>Buy Link</IonCol></IonRow>
          <IonRow><IonCol>10</IonCol><IonCol>Ganga Jal</IonCol><IonCol>Buy Link</IonCol></IonRow>
          <IonRow><IonCol>11</IonCol><IonCol>Camphor(Kapoor)</IonCol><IonCol>Buy Link</IonCol></IonRow>
          <IonRow><IonCol>12</IonCol><IonCol>Supari, laung, elaichi</IonCol><IonCol>Buy Link</IonCol></IonRow>
          <IonRow><IonCol>13</IonCol><IonCol>Bel Patra</IonCol><IonCol>Buy Link</IonCol></IonRow>
          <IonRow><IonCol>14</IonCol><IonCol>holy ash etc</IonCol><IonCol>Buy Link</IonCol></IonRow>
      
      </IonGrid>
          </div>
               
  );
};

export default Rudravishek;

