import {IonRow,IonCol, IonGrid, IonButton, IonText, IonImg, IonIcon, IonListHeader  } from '@ionic/react';
import {Adsense} from '@ctrl/react-adsense';


import './Yagyasurya.css';


const Yagyasurya: React.FC = () => {
  return (
    <div className="ion-padding">
        <h1>Surya Yagya</h1>
        <p>Surya Yagya is a Hindu ritual that is performed to invoke the blessings of Lord Surya, the Hindu god of the sun. The yagya is considered to be a powerful tool for bringing blessings of health, wealth, and prosperity, and for removing negative influences and obstacles.</p>
        <p>The ritual is performed by a trained priest, who will chant mantras and make offerings to Lord Surya and other associated deities. These offerings can include fruits, flowers, and grains, as well as ghee, milk, and other items. The priest will also light a sacred fire, known as the "yagya fire," which is considered to be a conduit for the blessings and positive energy to flow to the individual for whom the yagya is being performed.</p>
        <p>One of the key aspects of Surya Yagya is that it is considered to be a powerful tool for removing negative influences and obstacles that may be preventing an individual from achieving success. The ritual is believed to purify and balance the energy of the individual, so that they may move forward with their goals with greater ease and confidence.</p>
        <p>It is also believed that the positive energy generated by the yagya can help to attract positive people and opportunities into an individual's life. This can include new business opportunities, promotions at work, or even finding a suitable partner.</p>
        <p>Surya Yagya is also said to be beneficial for people with problems related to the eyes, skin and other health issues related to the sun. It is also said to be beneficial for people in fields such as agriculture, business, and politics.</p>
        <p>It is important to note that Surya Yagya is not a quick fix or a magic solution, but rather a powerful tool for helping individuals achieve their goals. The individual for whom the yagya is being performed must also take responsibility for their actions and put in the necessary effort and hard work to achieve their goals.</p>
        <p>Overall, Surya Yagya is a powerful ritual that can be used to invoke the blessings of Lord Surya and other associated deities. It is believed to bring blessings and positive energy, remove obstacles and negative influences, and attract positive people and opportunities into one's life. Additionally, it's also said to be beneficial for health issues related to the sun. However, it's important to remember that the individual must also put in the necessary effort and hard work to achieve their goals.</p>
        <IonRow>
        <Adsense
                client="ca-pub-4029214824221330"
                slot="2310889447"
                style={{ display: "block" }}
                format="auto"
                responsive="true"
              />
        </IonRow>
    </div>
  );
};

export default Yagyasurya;

