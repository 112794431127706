import {IonRow,IonCol, IonGrid, IonButton, IonText, IonImg, IonIcon, IonListHeader  } from '@ionic/react';
import {Adsense} from '@ctrl/react-adsense';


import './Ekadashi.css';


const Ekadashi: React.FC = () => {
  return (
    <div className="ion-padding">
      <IonGrid fixed={true}>
      <IonImg src="/assets/images/god/vishnuhead.png" />
      <h5> सनातन धर्म में एकादशी एक महत्वपूर्ण दिन है।  यह चंद्र महीने के अनुसार महीने में दो बार मनाया जाता है। प्रथम शुक्ल पक्ष की एकादशी  द्वितीय कृष्ण पक्ष की एकादशी यह दिन भगवान विष्णु के भक्तों के लिए महत्वपूर्ण दिवस माना जाता है और इस दिन भगवान विष्णु की विशेष पूजा की जाती है।</h5>
      <h5>शब्द "एकादशी" का अर्थ संस्कृत में "ग्यारह" है, जो चंद्र चरण के 11 वें दिन का जिक्र है। यह दिन आध्यात्मिक साधना और भक्ति के लिए शुभ दिन माना जाता है। बहुत से लोग उपवास करते हैं, पूजा करते हैं और भगवान विष्णु को समर्पित प्रार्थना और भजन पढ़ते हैं।</h5>
      <h5>एकादशी को आध्यात्मिक शुद्धि के लिए एक विशेष रूप से शक्तिशाली दिन माना जाता है, और कहा जाता है कि यह जन्म और मृत्यु के चक्र से मुक्ति लाता है। हिंदू पौराणिक कथाओं के अनुसार, यह कहा जाता है कि एकादशी का पालन करने से व्यक्ति मोक्ष प्राप्त कर सकता है, सभी पापों को नष्ट कर सकता है और मुक्ति प्राप्त कर सकता है।</h5>
      <h5>एकादशी का व्रत महीने की विशिष्ट एकादशी के आधार पर अलग-अलग होता है। कुछ लोग पूरा व्रत रखते हैं तो कुछ सिर्फ फल और दूध ही खाते हैं। कुछ भक्त अगले दिन अपना उपवास गेहूँ से बने भोजन से तोड़ते हैं, जबकि अन्य केवल फल और दूध खाते हैं।</h5>
      <h5>एकादशी के दिन, भक्त जल्दी उठते हैं, स्नान करते हैं और नए कपड़े पहनते हैं। फिर वे पूजा करने के लिए अपने स्थानीय मंदिर जाते हैं और भगवान विष्णु को समर्पित प्रार्थना और भजन पढ़ते हैं। बहुत से लोग भगवद गीता, रामायण और अन्य पवित्र ग्रंथ भी पढ़ते हैं।</h5>
      <h5>एकादशी आध्यात्मिक साधनाओं के अलावा परोपकारी कार्यों और जरूरतमंदों की मदद करने का भी दिन है। बहुत से लोग गरीबों को दान देते हैं, भूखों को खाना खिलाते हैं, और दयालुता के अन्य कार्य करते हैं।</h5>
      <h5>अंत में, एकादशी हिंदू धर्म में आध्यात्मिक अभ्यास और भक्ति के लिए एक महत्वपूर्ण दिन है। मन, शरीर और आत्मा को शुद्ध करने और भगवान विष्णु से आशीर्वाद प्राप्त करने के लिए यह एक शुभ दिन है। एकादशी के अनुष्ठान और अनुष्ठान अलग-अलग हो सकते हैं, लेकिन इसका उद्देश्य परमात्मा के करीब जाना और जन्म और मृत्यु के चक्र से मुक्ति प्राप्त करना है।</h5>
<IonListHeader></IonListHeader>
<h1>Unlocking the Secrets of Ekadashi: The Significance and Story Behind the Hindu Festival"</h1>
<h4>Introduction:</h4>
Ekadashi is one of the most auspicious and significant Hindu festivals that is celebrated twice a month in the Hindu calendar. This festival is dedicated to Lord Vishnu and is considered highly sacred among the Hindu community. It is said that observing Ekadashi Vrat or fasting on this day can help a person attain Moksha or liberation from the cycle of birth and death.
<h2>What is Ekadashi?</h2>
Ekadashi is a Sanskrit word that means "Eleven" and refers to the 11th day of the lunar fortnight in the Hindu calendar. The day holds immense significance among Hindus and is considered to be a powerful day for spiritual growth and development. The festival of Ekadashi is celebrated twice a month and has a different significance each time it is observed.
<h2>The Significance of Ekadashi:</h2>
Ekadashi is considered to be a highly auspicious day for Hindus and is associated with numerous spiritual benefits. The festival is believed to bring blessings from Lord Vishnu and helps a person attain Moksha or liberation from the cycle of birth and death. Observing the Ekadashi Vrat or fasting on this day is said to help purify the mind and soul, improve one's health and well-being, and bring peace and happiness to a person's life.
<h2>The Story Behind Ekadashi:</h2>
The story behind Ekadashi is one of devotion, sacrifice, and love. According to Hindu mythology, Ekadashi was a beautiful and pious young woman who was deeply devoted to Lord Vishnu. She was so dedicated to her lord that she gave up all her material possessions and lived a life of pure devotion and meditation. Impressed by her devotion, Lord Vishnu granted her a boon and made her an eternal part of the Hindu calendar, to be celebrated and revered by all Hindus.
<h2>Observing Ekadashi Vrat:</h2>
Observing Ekadashi Vrat or fasting is one of the most important rituals associated with the festival of Ekadashi. The fast is observed from sunrise to sunset and involves abstaining from food and water. Some people also abstain from sleeping during this time and spend the night in meditation and prayer. Fasting on Ekadashi is said to bring spiritual benefits and help purify the mind and soul.
Conclusion:
In conclusion, Ekadashi is a significant Hindu festival that holds immense spiritual importance for Hindus. Observing Ekadashi Vrat or fasting on this day is said to bring blessings from Lord Vishnu, improve one's health and well-being, and help a person attain Moksha or liberation from the cycle of birth and death. The festival of Ekadashi is a reminder of the power of devotion and the importance of living a life dedicated to the divine.      <IonRow>
        <Adsense
                client="ca-pub-4029214824221330"
                slot="2310889447"
                style={{ display: "block" }}
                format="auto"
                responsive="true"
              />
        </IonRow>
        <h2><IonRow><IonCol>एकादशी का नाम</IonCol><IonCol>मास</IonCol><IonCol>पक्ष</IonCol></IonRow></h2>
        <IonRow><IonCol>पापमोचिनी एकादशी</IonCol><IonCol>चैत्र</IonCol><IonCol>कृष्ण</IonCol></IonRow>
        <IonRow><IonCol>कामदा एकादशी</IonCol><IonCol>चैत्र</IonCol><IonCol>शुक्ल</IonCol></IonRow>
        <IonRow><IonCol>वरूथिनी एकादशी</IonCol><IonCol>वैशाख</IonCol><IonCol>कृष्ण</IonCol></IonRow>
        <IonRow><IonCol>मोहिनी एकादशी</IonCol><IonCol>वैशाख</IonCol><IonCol>शुक्ल</IonCol></IonRow>
        <IonRow><IonCol>अपरा एकादशी</IonCol><IonCol> ज्येष्ठ</IonCol><IonCol>कृष्ण</IonCol></IonRow>
        <IonRow><IonCol>निर्जला एकादशी</IonCol><IonCol> ज्येष्ठ</IonCol><IonCol>शुक्ल</IonCol></IonRow>
        <IonRow><IonCol>योगिनी एकादशी</IonCol><IonCol>आषाढ़</IonCol><IonCol>कृष्ण</IonCol></IonRow>
        <IonRow><IonCol>देवशयनी एकादशी</IonCol><IonCol>आषाढ़</IonCol><IonCol>शुक्ल</IonCol></IonRow>
        <IonRow><IonCol>कामिका एकादशी</IonCol><IonCol>श्रावण</IonCol><IonCol>कृष्ण</IonCol></IonRow>
        <IonRow><IonCol>पुत्रदा एकादशी</IonCol><IonCol>श्रावण</IonCol><IonCol>शुक्ल</IonCol></IonRow>
        <IonRow><IonCol>अजा एकादशी</IonCol><IonCol>भाद्रपद</IonCol><IonCol>कृष्ण</IonCol></IonRow>
        <IonRow><IonCol>परिवर्तिनी एकादशी</IonCol><IonCol>भाद्रपद</IonCol><IonCol>शुक्ल</IonCol></IonRow>
        <IonRow><IonCol>इंदिरा एकादशी</IonCol><IonCol>आश्विन</IonCol><IonCol>कृष्ण</IonCol></IonRow>
        <IonRow><IonCol>पापांकुशा एकादशी</IonCol><IonCol>आश्विन</IonCol><IonCol>शुक्ल</IonCol></IonRow>
        <IonRow><IonCol>रमा एकादशी</IonCol><IonCol>कार्तिक</IonCol><IonCol>कृष्ण</IonCol></IonRow>
        <IonRow><IonCol>देव प्रबोधिनी एकादशी</IonCol><IonCol>कार्तिक</IonCol><IonCol>शुक्ल</IonCol></IonRow>
        <IonRow><IonCol>परमा एकादशी</IonCol><IonCol>अधिक मास</IonCol><IonCol>कृष्ण</IonCol></IonRow>
        <IonRow><IonCol>पद्‍मिनी एकादशी</IonCol><IonCol>अधिक मास</IonCol><IonCol>शुक्ल</IonCol></IonRow>
        <IonRow><IonCol>उत्पन्ना एकादशी</IonCol><IonCol>मार्गशीर्ष </IonCol><IonCol>कृष्ण</IonCol></IonRow>
        <IonRow><IonCol>मोक्षदा एकादशी</IonCol><IonCol>मार्गशीर्ष </IonCol><IonCol>शुक्ल</IonCol></IonRow>
        <IonRow><IonCol>सफला एकादशी</IonCol><IonCol>पौष</IonCol><IonCol>कृष्ण</IonCol></IonRow>
        <IonRow><IonCol>पुत्रदा एकादशी</IonCol><IonCol>पौष</IonCol><IonCol>शुक्ल</IonCol></IonRow>
        <IonRow><IonCol>षटतिला एकादशी</IonCol><IonCol>माघ</IonCol><IonCol>कृष्ण</IonCol></IonRow>
        <IonRow><IonCol>जया एकादशी</IonCol><IonCol>माघ</IonCol><IonCol>शुक्ल</IonCol></IonRow>
        <IonRow><IonCol>विजया एकादशी</IonCol><IonCol>फाल्गुन</IonCol><IonCol>कृष्ण</IonCol></IonRow>
        <IonRow><IonCol>आमलकी एकादशी</IonCol><IonCol>फाल्गुन</IonCol><IonCol>शुक्ल</IonCol></IonRow>
        </IonGrid>
        <IonButton routerLink="/contact">For More Info Click Me</IonButton>
</div>
  );
};

export default Ekadashi;

