import {IonRow,IonCol, IonGrid, IonButton, IonText, IonImg, IonIcon, IonListHeader  } from '@ionic/react';
import {Adsense} from '@ctrl/react-adsense';


import './Vyaparshubharambhpuja.css';


const Vyaparshubharambhpuja: React.FC = () => {
  return (
    <div className="ion-padding">
        <h1>Vyapar Shubharambh Puja</h1>
        <p>Vyapar Shubharambh Puja, also known as "Vyapar Vriddhi Puja" is a Hindu ritual that is performed to seek blessings from the Gods and Goddesses for the success and prosperity of a business. This puja is usually performed before starting a new business or before starting a new financial year.</p>
        <p>The puja is performed to invoke the blessings of Lord Ganesha, the remover of obstacles, and Lord Laxmi, the goddess of wealth and prosperity. The puja begins with a prayer to Lord Ganesha, seeking his blessings for a smooth start to the business and to remove any obstacles that may arise. The puja also includes the recitation of mantras and the offering of puja items such as flowers, fruits, and sweets to the gods and goddesses.</p>
        <p>During the puja, the business owner or the representative of the business, takes a sacred bath and wears new clothes. They then perform the puja, seeking blessings for the success and growth of the business. After the puja, the business owner or representative is supposed to light a lamp and offer it to Lord Ganesha and Lord Laxmi.</p>
        <p>It's believed that by performing the Vyapar Shubharambh Puja, the business will be blessed with success, growth, and prosperity. It is also believed that by performing this puja, the business will be protected from any negative energy and obstacles that may arise in the future.</p>
        <p>In conclusion, Vyapar Shubharambh Puja is a Hindu ritual that is performed to seek blessings from the Gods and Goddesses for the success and prosperity of a business. It is usually performed before starting a new business or before starting a new financial year. It's believed that by performing this puja, the business will be blessed with success, growth and prosperity and will be protected from any negative energy and obstacles that may arise in the future.</p>
        <IonRow>
        <Adsense
                client="ca-pub-4029214824221330"
                slot="2310889447"
                style={{ display: "block" }}
                format="auto"
                responsive="true"
              />
        </IonRow>
    </div>
  );
};

export default Vyaparshubharambhpuja;

