import React from 'react';
import { IonItem, IonLabel, IonContent, IonHeader, IonMenu, IonTitle, IonToolbar, IonIcon} from '@ionic/react';
import {home, hammerOutline, bookOutline, libraryOutline, shapesOutline, callOutline, personAddOutline, happyOutline } from 'ionicons/icons';
import { connect } from 'net';
interface ContainerProps {
  id: string,
}
const Menu: React.FC<ContainerProps> = (props) => {
  return (
    <>
      <IonMenu contentId="main-content">
        <IonHeader>
          <IonToolbar>
            <IonTitle>Sanatan Puja</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
        <IonItem routerLink="/firstp"><IonLabel><IonIcon icon={home} slot="start"></IonIcon> Home</IonLabel></IonItem>
        <IonItem routerLink="/services"><IonLabel><IonIcon icon={hammerOutline} slot="start"></IonIcon> Services</IonLabel></IonItem>
        <IonItem routerLink="/panchang"><IonLabel><IonIcon icon={bookOutline} slot="start"></IonIcon> Panchang</IonLabel></IonItem>
        <IonItem routerLink="/granth"><IonLabel><IonIcon icon={libraryOutline} slot="start"></IonIcon> Hamare Granth</IonLabel></IonItem>
        <IonItem routerLink="/products"><IonLabel><IonIcon icon={shapesOutline} slot="start"></IonIcon> Products</IonLabel></IonItem>
        <IonItem routerLink="/bhagwat"><IonLabel><IonIcon icon={callOutline} slot="start"></IonIcon> Bhagwat Gita</IonLabel></IonItem>
        <IonItem routerLink="/contact"><IonLabel><IonIcon icon={callOutline} slot="start"></IonIcon> Contact</IonLabel></IonItem>
        <IonItem routerLink="/home"><IonLabel><IonIcon icon={happyOutline} slot="start"></IonIcon> About Sanatan Dharma</IonLabel></IonItem>
        <IonItem routerLink="/auth/signup"><IonLabel><IonIcon icon={personAddOutline} slot="start"></IonIcon> Sign up</IonLabel></IonItem>
        </IonContent>
      </IonMenu>
      {/* <IonPage id="main-content">
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonMenuButton></IonMenuButton>
            </IonButtons>
            <IonTitle>Sanatan Puja</IonTitle>
          </IonToolbar>
        </IonHeader>
      </IonPage> */}
    </>
  );
}

export default Menu;